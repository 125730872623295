import * as THREE from 'three';
import { LineGenerator } from './LineGenerator';
import { Globals } from '../../utils/Globals';
import { degreesToRadians } from '../../../lib/com/hellomonday/utils/MathUtils';

export class CustomLineGenerator extends LineGenerator {
	private _scene: THREE.Scene;

	private _params = {
		radius: 40,
		sphereOpacity: 0,
		rotationX: 0,
		rotationY: 0,
		rotationZ: 0
	};

	constructor(scene: THREE.Scene) {
		super(0.02, {
			transformLineMethod: p => p
		});

		let geometry = new THREE.SphereBufferGeometry(this._params.radius, 32, 32, 0); //, 3.2, 4, 2.1);
		let material = new THREE.MeshBasicMaterial({ wireframe: false, visible: true, transparent: true, opacity: this._params.sphereOpacity, fog: false, side: THREE.BackSide });
		let sphere = new THREE.Mesh(geometry, material);

		this.sphere = sphere;
		this._scene = scene;

		this._scene.add(sphere);

		if (Globals.DEBUG) {
			this.addGui2();
		}
	}

	private addGui2 = () => {
		let top = Globals.GUI.addFolder('Shooting Stars');
		top.add(this._params, 'radius', 2, 100)
			.step(0.01)
			.name('Radius')
			.onChange(this.guiUpdate);
		top.add(this._params, 'sphereOpacity', 0, 1)
			.step(0.01)
			.name('Sphere Opacity')
			.onChange(this.guiUpdate);
		top.add(this._params, 'rotationX', 0, 360)
			.step(1)
			.name('Rotation X')
			.onChange(this.guiUpdate);
		top.add(this._params, 'rotationY', 0, 360)
			.step(1)
			.name('Rotation Y')
			.onChange(this.guiUpdate);
		top.add(this._params, 'rotationZ', 0, 360)
			.step(1)
			.name('Rotation Z')
			.onChange(this.guiUpdate);
		// top.open();
	};

	private guiUpdate = () => {
		let scale = this._params.radius / 10;
		this.sphere.scale.x = scale;
		this.sphere.scale.y = scale;
		this.sphere.scale.z = scale;

		this.rotation.x = degreesToRadians(this._params.rotationX);
		this.rotation.y = degreesToRadians(this._params.rotationY);
		this.rotation.z = degreesToRadians(this._params.rotationZ);

		this.sphere.material.opacity = this._params.sphereOpacity;
		this.radius = this._params.radius;
	};
}
