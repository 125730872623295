import * as THREE from 'three';
import { ParticleMaterial } from '../../materials/ParticleMaterial';
import { Globals } from '../../utils/Globals';
import { clamp, degreesToRadians, randomFloat, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';
import { Back, Linear, Power1, Power2, TweenMax } from 'gsap/TweenMax';

export class ParticleEmitter {
	public element: THREE.Points;
	private _geometry: THREE.BufferGeometry;
	private _material: ParticleMaterial;

	private _initialPositions = [];
	private _initialSpherical = [];

	private _particleData = [];

	private _levelColors = [[0.5, 0.5, 1.0], [1, 0.5, 0.5], [0.5, 1.0, 0.5]];

	private _params = {
		radius: 7.5
	};

	private _particleCount: number;

	constructor(color: THREE.Color, particleCount: number = 100, pointRadius: number = 0.05, rotationOffset: number = 0) {
		this._particleCount = particleCount;
		this._params.radius = pointRadius;

		let radius = pointRadius; //Globals.SPHERE_RADIUS; //6.5;
		this._geometry = new THREE.BufferGeometry();
		let vertices = [];
		let colors = [];
		let sizes = [];

		let spherical = new THREE.Spherical(radius, 0, 0);
		let vec3 = new THREE.Vector3(0, 0, 0);

		let random;

		for (let i = 0; i < this._particleCount; i++) {
			random = randomFloat(0, 0.1);
			radius = clamp(0.04 + random, 0.001, 5); //this._params.radius + random; // 0.001;//

			// Backward / Forward
			spherical.phi = Math.random() * Math.PI; //randomFloat(degreesToRadians(70), degreesToRadians(90)) + rotationOffset;//Math.random() * Math.PI;//randomFloat(degreesToRadians(-10), degreesToRadians(-50));//randomFloat(degreesToRadians(90), degreesToRadians(-90));//randomFloat(degreesToRadians(-110), degreesToRadians(-130));//randomFloat(degreesToRadians(-10), degreesToRadians(90));    //randomFloat(degreesToRadians(30), degreesToRadians(50)); //Math.PI * 0.5;//Math.random() * Math.PI;

			// Left / Right
			spherical.theta = Math.random() * TAU; //randomFloat(degreesToRadians(-60), degreesToRadians(60));//Math.random() * TAU;
			spherical.radius = radius;
			// spherical.makeSafe();

			this._initialSpherical.push(spherical.clone());

			vec3.setFromSpherical(spherical);

			vertices.push(vec3.x);
			vertices.push(vec3.y);
			vertices.push(vec3.z);

			colors.push(color.r, color.g, color.b);
			// colors.push(0, 1, 0);

			let size = 0; //0.02;
			sizes.push(size);
			// sizes.push(0.015);

			this._particleData.push({ radius: radius, position: vec3, size: size, zOffset: 0 });
		}

		this._initialPositions = [...vertices];

		this._geometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		this._geometry.addAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
		this._geometry.addAttribute('size', new THREE.Float32BufferAttribute(sizes, 1).setDynamic(true));
		// this._geometry.addAttribute('size', new THREE.Float32BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));

		this._material = new ParticleMaterial(Globals.getNamedTexture('particle2'));

		// this._material.alpha = 0;

		this.element = new THREE.Points(this._geometry, this._material);
		this.element.rotation.z = Math.PI * 0.5;
	}

	public explode = (callback: () => void) => {
		let time = 1; //0.25;
		let l = this._particleData.length;

		for (let i = 0; i < l; i++) {
			TweenMax.to(this._particleData[i], time, { zOffset: -3, radius: this._initialSpherical[i].radius + 3, onUpdate: this.render, ease: Power1.easeOut }); //, onUpdate: this.render, onComplete: this.reset});
			TweenMax.to(this._particleData[i], time * 0.5, { size: 0.25, onUpdate: this.render, yoyo: true, repeat: 1, ease: Power1.easeOut }); //, onUpdate: this.render, onComplete: this.reset});
			// TweenMax.to(this._particleData[i], 4, {onComplete: callback}); // , onComplete: this.reset
		}

		TweenMax.delayedCall(time, callback);
	};

	private reset = () => {
		let l = this._particleData.length;

		for (let i = 0; i < l; i++) {
			TweenMax.killTweensOf(this._particleData[i]);
			this._particleData[i].size = 0; //0.015;
			this._particleData[i].radius = this._initialSpherical[i].radius;
			this._particleData[i].zOffset = 0;
		}
	};

	public render = () => {
		// Size
		let sizes = this._geometry.attributes.size.array as Array<number>;

		for (let i = 0; i < this._particleCount; i++) {
			sizes[i] = this._particleData[i].size;
		}

		// @ts-ignore
		this._geometry.attributes.size.needsUpdate = true;

		// 	Position
		let positions = this._geometry.attributes.position.array as Array<number>;
		let l = positions.length;

		let spherical = new THREE.Spherical(0, 0, 0);
		let vec3 = new THREE.Vector3(0, 0, 0);
		let sphericalId = 0;

		for (let i = 0; i < l; i += 3) {
			spherical.phi = this._initialSpherical[sphericalId].phi;
			spherical.theta = this._initialSpherical[sphericalId].theta;
			spherical.radius = this._particleData[sphericalId].radius;

			vec3.setFromSpherical(spherical);

			positions[i] = vec3.x;
			positions[i + 1] = vec3.y; // + this._particleData[sphericalId].zOffset;
			positions[i + 2] = vec3.z; // + this._particleData[sphericalId].zOffset;

			sphericalId++;
		}

		// @ts-ignore
		this._geometry.attributes.position.needsUpdate = true;
	};
}
