import * as THREE from 'three';
import { ParticleMaterial } from '../../materials/ParticleMaterial';
import { Globals } from '../../utils/Globals';
import { degreesToRadians, randomInt, spherePosition, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';

//const PARTICLE_COUNT: number = 3000; //5000;

export class WorldParticles {
	public element: THREE.Points;
	private _geometry: THREE.BufferGeometry;
	private _material: ParticleMaterial;

	private _initialPositions = [];

	private _levelColors = [[0.5, 0.5, 1.0], [1, 0.5, 0.5], [0.5, 1.0, 0.5]];

	private _params = {
		radius: 7.5
	};

	private _particleCount: number;

	public animateSize: boolean = true;
	public speedMultiplier: number = 5;
	private _size: number;

	constructor(particleCount: number = 2000, pointRadius: number = Globals.SPHERE_RADIUS, maxParticleSize: number = 0.05, texture: string = 'particle1', depthTest: boolean = true) {
		this._particleCount = particleCount;

		this._size = maxParticleSize;

		let radius = pointRadius; //Globals.SPHERE_RADIUS; //6.5;
		this._geometry = new THREE.BufferGeometry();
		let vertices = [];
		let colors = [];
		let sizes = [];

		let spherical = new THREE.Spherical(radius, 0, 0);
		let vec3 = new THREE.Vector3(0, 0, 0);

		for (let i = 0; i < this._particleCount; i++) {
			radius = this._params.radius + (-0.5 + Math.random());

			spherical.phi = Math.random() * Math.PI;
			spherical.theta = Math.random() * TAU;
			spherical.radius = radius;
			// spherical.makeSafe();

			vec3.setFromSpherical(spherical);

			vertices.push(vec3.x);
			vertices.push(vec3.y);
			vertices.push(vec3.z);

			colors.push(0.5, 0.5, 1.0);
			sizes.push(this._size);
		}

		this._initialPositions = [...vertices];

		this._geometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		this._geometry.addAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
		this._geometry.addAttribute('size', new THREE.Float32BufferAttribute(sizes, 1).setDynamic(true));
		// this._geometry.addAttribute('size', new THREE.Float32BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));

		this._material = new ParticleMaterial(Globals.getNamedTexture(texture), depthTest);

		this._material.alpha = 0;

		this.element = new THREE.Points(this._geometry, this._material);
		this.element.rotation.z = Math.PI * 0.5;

		// if (Globals.DEBUG) {
		// 	this.addGui();
		// }
	}

	private addGui = () => {
		let top = Globals.GUI.addFolder('Planet Particles');

		let radius = top
			.add(this._params, 'radius')
			.min(0.0)
			.max(100)
			.step(0.01)
			.name('Radius')
			.listen();
		radius.onChange(this.updateRadius);

		// top.open();
	};

	private updateRadius = () => {
		let radius = this._params.radius;
		let spherical = new THREE.Spherical(0, 0, 0);
		let vec3 = new THREE.Vector3(0, 0, 0);
		let index = 0;

		for (let i = 0; i < this._particleCount; i++) {
			radius = this._params.radius + (-0.5 + Math.random());

			spherical.phi = Math.random() * Math.PI;
			spherical.theta = Math.random() * TAU;
			spherical.radius = radius;
			// spherical.makeSafe();

			vec3.setFromSpherical(spherical);

			// @ts-ignore
			this._geometry.attributes.position.array[index] = vec3.x;
			// @ts-ignore
			this._geometry.attributes.position.array[index + 1] = vec3.y;
			// @ts-ignore
			this._geometry.attributes.position.array[index + 2] = vec3.z;

			// vertices.push(vec3.x);
			// vertices.push(vec3.y);
			// vertices.push(vec3.z);

			index += 3;
		}

		// @ts-ignore
		this._geometry.attributes.position.needsUpdate = true;

		// this._geometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
	};

	// noinspection DuplicatedCode
	public changeLevel = (id: number) => {
		let colors = [];

		for (let i = 0; i < this._particleCount; i++) {
			colors.push(this._levelColors[id][0], this._levelColors[id][1], this._levelColors[id][2]);
		}

		this._geometry.addAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
	};

	get alpha() {
		return this._material.alpha;
	}

	set alpha(value: number) {
		this._material.alpha = value;
	}

	public render() {
		if (!this.animateSize) {
			return;
		}

		let time = Date.now() * 0.005;
		// this.element.rotation.z = 0.01 * time;

		// let positions = this._geometry.attributes.position.array as Array<number>;
		let sizes = this._geometry.attributes.size.array as Array<number>;

		for (let i = 0; i < this._particleCount; i++) {
			// console.log(Math.sin(this._size * (i + time)));
			sizes[i] = this._size * Math.abs(Math.sin((i + time) / this.speedMultiplier)); // / 18;

			// sizes[i] = 0.05 + (Math.sin(0.01 * i + time) / 40);

			// positions[i] = this._initialPositions[i] + Math.sin(5 * time);
		}

		// @ts-ignore
		this._geometry.attributes.size.needsUpdate = true;
	}
}
