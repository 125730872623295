import {Power2, TweenMax} from "gsap/TweenMax";


export class WebcamNotAllowed {
	private _element;
	private _isShown: boolean = false;

	constructor(element) {
		this._element = element;
		this.init();
	}

	private init() {

	}

	public animateIn = () => {
		if (this._isShown === false) {
			this._isShown = true;
			this._element.style.display = 'block';
			TweenMax.to(this._element, 0.3, {y: 0, opacity: 1, ease: Power2.easeOut});
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			this._isShown = false;
			TweenMax.to(this._element, 0.3, {y: 0, opacity: 0, ease: Power2.easeOut, onComplete: this.hide});
		}
	};

	public toggle = () => {
		if (this._isShown === true) {
			this.animateOut();
		} else {
			this.animateIn();
		}
		return this._isShown;
	};

	private hide = () => {
		this._element.style.display = 'none';
	};
}
