import { Globals } from './utils/Globals';
import { Footer } from './utils/Footer';
import { About } from './utils/About';
import { LevelsCompleted } from './utils/LevelsCompleted';

import { MainScene } from './utils/MainScene';
import { Preloader } from './utils/Preloader';
import { TopContent } from './utils/TopContent';
import { LevelScreen } from './utils/LevelScreen';
import { InstructionText } from './utils/InstructionText';
import { WebcamNotAllowed } from './utils/WebcamNotAllowed';
import { BodyPixController } from './utils/BodyPixController';
import { AudioManager } from './game/audio/AudioManager';

import * as dat from 'dat.gui';
import { GameHUD } from './utils/GameHUD';
import { TweenMax } from 'gsap/TweenMax';
import { IntroScreen } from './utils/IntroScreen';
import { TryAgainScreen } from './utils/TryAgainScreen';
import Global = WebAssembly.Global;
import { AllowWebcamScreen } from './utils/AllowWebcamScreen';

//@ts-ignore: Using Require to import ES5
let Stats = require('./stats.js');

//@ts-ignore: Using Require to import ES5
require('./OBJLoader.js');

//@ts-ignore: Using Require to import ES5
let blip = require('./blip.js');

declare global {
	interface Window {
		blip: any;
	}
}

window.blip = window.blip || {};

class Main {
	private preloader: Preloader;
	private _bodyPixReady: boolean = false;
	private _assetsReady: boolean = false;

	constructor() {
		Globals.main = this;

		Globals.GUI = new dat.GUI();

		let url = new URL(window.location.href);
		if (url.origin.indexOf('localhost') === -1) {
			Globals.GUI.domElement.parentNode.style.display = 'none';
		}

		// Globals.GUI.close();

		var isMobile = false; //initiate as false
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}

		Globals.isMobile = isMobile;

		if (isMobile != true) {
			this.init();
		} else {
			//@ts-ignore
			Globals.noWebcam = true;
			Globals.keyboardOnly = true;
			Globals.useKeyboard = true;

			//	document.querySelector('#MobileNotice').style.display = 'block';
			this.init();
		}
	}

	private init() {
		// Remove all console logs
		//window.console.log = function() {};

		let url = new URL(window.location.href);
		let useLevel = url.searchParams.get('useLevel');
		let paddleMoveDistance = url.searchParams.get('paddleMoveDistance');
		let skipIntro = url.searchParams.get('skipIntro');
		let showStats = url.searchParams.get('showStats');
		let useKeyboard = url.searchParams.get('useKeyboard');
		let noWebcam = url.searchParams.get('noWebcam');
		let skipTraining = url.searchParams.get('skipTraining');
		let controls = url.searchParams.get('controls');

		// Disable all url params
		if (url.origin.indexOf('localhost') === -1) {
			skipIntro = 'false';
			showStats = 'false';
			useKeyboard = 'false';
			noWebcam = 'false';
			skipTraining = 'false';
			controls = 'false';

			Globals.DEBUG = false;
		}

		if (skipTraining && skipTraining === 'true') {
			Globals.SKIP_TRAINING = true;
			Globals.trainingLevelDone = true;
		}

		if (useLevel) {
			Globals.gameVariables.currentLevel = Number(useLevel);
		}
		if (paddleMoveDistance) {
			Globals.gameVariables.paddleMoveDistance = Number(paddleMoveDistance);
		}
		if (skipIntro === 'true') {
			Globals.speedUpFloatingIslandWith = 0.01;
			Globals.speedUpIntroWithFactor = 0.01; //1;// 0.01;
			Globals.debugSkipIntro = true;
			Globals.speedUpTextIntroWithFactor = 0.01; // 1;//0.01;
			Globals.skipMoveLeftAndRight = true;
			Globals.skipSkeletonInIntro = true;
		}
		if (useKeyboard === 'true') {
			Globals.keyboardOnly = true;
		}
		if (noWebcam === 'true') {
			Globals.noWebcam = true;
			Globals.keyboardOnly = true;
			Globals.useKeyboard = true;
		}

		if (controls === 'true') {
			Globals.GUI.domElement.parentNode.style.zIndex = '1000';
		}

		Globals.stats = new Stats();
		Globals.stats.showPanel(0);
		if (showStats === 'true') {
			document.body.appendChild(Globals.stats.dom);
		}

		Globals.AUDIO_MANAGER = new AudioManager();

		this.preloader = new Preloader(document.querySelector('#Preloader'), this.assetsLoaded, this.startBodyPixController);

		Globals.topContent = new TopContent(document.querySelector('#TopContent'));
		Globals.gameHUD = new GameHUD(document.querySelector('#GameHUD'));
		Globals.levelScreen = new LevelScreen(document.querySelector('#LevelScreen'));
		Globals.instructionText = new InstructionText(document.querySelector('#InstructionText'));
		Globals.footer = new Footer(document.querySelector('#Footer'));
		Globals.about = new About(document.querySelector('#About'));
		Globals.levelsCompleted = new LevelsCompleted(document.querySelector('#LevelsCompleted'));
		Globals.webcamNotAllowed = new WebcamNotAllowed(document.querySelector('#WebcamNotAllowed'));
		Globals.tryAgainScreen = new TryAgainScreen(document.querySelector('#TryAgainScreen'));
		Globals.allowWebcamScreen = new AllowWebcamScreen(document.querySelector('#AllowWebcamScreen'));
	}

	private assetsLoaded = () => {
		this._assetsReady = true;
		this.resize();

		this.startIntro();
	};

	private startBodyPixController = data => {
		Globals.bodyPixController = new BodyPixController(data, this.bodyPixReady);
	};

	private bodyPixReady = () => {
		this._bodyPixReady = true;

		this.startIntro();
	};

	private startIntro = () => {
		if (this._assetsReady === true && this._bodyPixReady === true) {
		}
	};

	public startSite = () => {
		Globals.mainScene = new MainScene();
		new IntroScreen(document.querySelector('#IntroScreen'), this.introDone);
	};

	private introDone = () => {
		if (Globals.isMobile || Globals.noWebcam) {
			if (Globals.SKIP_TRAINING !== true) {
				Globals.mainScene.cameraController.introComplete();
			}
		} else {
			Globals.allowWebcamScreen.animateIn();
		}
	};

	public resize = () => {
		//	Globals.VIEW_MANAGER.resize();
		//	var videoWidth = window.innerWidth;
		//	this._webcam.width = videoWidth;
	};
}

window.onload = () => {
	const main = new Main();
	(window as any).Main = main;
};
