import * as THREE from 'three';
import { Globals } from './Globals';
import { Linear, Power1, Power2, TimelineMax, TweenMax } from 'gsap/TweenMax';
import { GameObjectSpawner } from '../game/objects/GameObjectSpawner';
import { CameraShake } from '../game/utils/CameraShake';
import { clamp, degreesToRadians, normalizeAngle, radiansToDegrees, shortestAngle, TAU } from '../../lib/com/hellomonday/utils/MathUtils';
import { PostProcessing } from '../game/utils/PostProcessing';
import { Reindeers } from '../game/objects/Reindeers';
import { WorldParticles } from '../game/objects/WorldParticles';
import { Sleigh } from '../game/objects/Sleigh';
import { CustomLineGenerator } from '../game/utils/CustomLineGenerator';
import { Lights } from '../game/world/Lights';
import { TrackRibbons } from '../game/world/TrackRibbons';
import { LightspeedTunnel } from '../game/world/LightspeedTunnel';
import { AsteroidField } from '../game/objects/AsteroidField';
import { TrainingLevel } from '../game/levels/TrainingLevel';
import { FixedTimeStep } from './FixedTimeStep';
import { CameraController } from '../game/controllers/CameraController';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib';
import init = RectAreaLightUniformsLib.init;
import { GameHUD } from './GameHUD';

(window as any).THREE = THREE;

//@ts-ignore: Using Require to import ES5
require('./../OBJLoader.js');

//@ts-ignore: Using Require to import ES5
require('./../FBXLoader.js');

export class MainScene {
	private _gameStarted: boolean = false;
	private _inHyperspeed: boolean = false;
	private _allGiftsPickedUp: boolean = true;

	private _inTrainingLevel: boolean = !Globals.SKIP_TRAINING;

	private _intro: boolean = this._inTrainingLevel;
	private _trainingLockCamera: boolean = false;

	private _scene: THREE.Scene;
	private _renderer: THREE.WebGLRenderer;

	private _mainGroup: THREE.Group = new THREE.Group();
	private _planetGroup: THREE.Group = new THREE.Group();
	private _planetGroupOuter: THREE.Group = new THREE.Group();
	private _planet2Group: THREE.Group = new THREE.Group();

	private _mainPlanet;
	private _planet2;

	private _spawner: GameObjectSpawner;

	private _fixedTimestep: FixedTimeStep;

	private _initialData = {
		cameraLookX: 0,
		cameraLookY: -30,
		cameraLookZ: -100,
		cameraX: 0,
		cameraY: 10,
		cameraZ: 310,
		timescale: 0,
		deersY: 9.52, //-0.9,
		deersZ: 309.07, //-0.52,
		firstPerson: false,
		hyperspeed: null,
		fov: 25
	};

	private _controlsData = {
		cameraLookX: this._initialData.cameraLookX,
		cameraLookY: this._inTrainingLevel ? 10 : this._initialData.cameraLookY,
		cameraLookZ: this._inTrainingLevel ? 394 : this._initialData.cameraLookZ,
		cameraX: this._inTrainingLevel ? -25 : this._initialData.cameraX,
		cameraY: this._inTrainingLevel ? 10 : this._initialData.cameraY,
		cameraZ: this._inTrainingLevel ? 500 : this._initialData.cameraZ,
		timescale: this._initialData.timescale,
		sleightRotationY: 0,
		sleightRotationX: 0,
		deersX: 0,
		deersY: this._initialData.deersY,
		deersZ: this._inTrainingLevel ? 300 : this._initialData.deersZ, // -1.15
		firstPerson: this._initialData.firstPerson,
		hyperspeed: this._initialData.hyperspeed,
		restart: null,
		fov: this._inTrainingLevel ? 40 : this._initialData.fov
	};

	private _animateInData = {
		cameraLookY: 0,
		cameraY: 7,
		cameraZ: 4,
		timescale: 1,
		fov: 40
	};

	private _hyperspeedAnimationData = {
		cameraLookY: 7,
		cameraLookZ: -200,
		cameraZ: -15,
		fov: 55
	};

	private _cameraShake: CameraShake = new CameraShake();

	private _postProcessing: PostProcessing;

	private _collisionObject: THREE.Mesh;

	private _standardMaterial: THREE.MeshStandardMaterial = new THREE.MeshStandardMaterial({ roughness: 0.4, metalness: 0.1, color: 0x2940a3, flatShading: true, fog: false, transparent: true });

	private _lightspeedTunnel: LightspeedTunnel = new LightspeedTunnel();
	private _reindeers: Reindeers;
	private _sleigh: Sleigh;
	private _worldParticles: WorldParticles;
	private _lineGenerator: CustomLineGenerator;
	private _lights: Lights;
	private _trackRibbons: TrackRibbons = new TrackRibbons();
	private _asteroidField: AsteroidField = new AsteroidField();

	private _currentLevel: number = 0;

	private _planetColors = [0x2940a3, 0xc30101, 0x3af96b];

	private _trainingLevel: TrainingLevel;

	private _pauseFactor = { value: 1 };
	private _paused: boolean = false;

	private _lookAt = new THREE.Vector3(0, 0, 0);

	private _rings = [[], [], []];

	private _cameraController: CameraController;

	private _levelSpeeds: Array<number> = [0.006, 0.007, 0.008];

	private _animateInTimelineInitial: TimelineMax;
	private _animateInTimeline: TimelineMax;

	private _animateInParams = {
		percent: 0,
		timescale: 1
	};

	private _controlsActive: boolean = false;
	private _isInitial: boolean = true;

	constructor() {
		Globals.bgMusic = Globals.AUDIO_MANAGER.getClip('bg_music');

		this.setupScene();
		this.setupWorld();
		this.setupTimelines();

		this._lights = new Lights(this._scene, this._cameraController.main, this._mainGroup, this._planet2, this._planet2Group);

		this._spawner = new GameObjectSpawner(this._mainPlanet, this._planetGroup);
		this._spawner.signalHazardHit.add(this.shakeCamera);

		this._spawner.signalAllGiftsPickedUp.add(this.allGiftsPickedUp);

		this._mainPlanet.visible = false;
		this._planetGroup.add(this._trackRibbons);

		this._planetGroupOuter.add(this._lightspeedTunnel.group);
		this._planetGroupOuter.add(this._asteroidField.group);

		this._planetGroupOuter.add(this._planet2Group);

		this._controlsData.restart = this.restartCurrentLevel;

		// GUI
		if (Globals.DEBUG) {
			this.addGui();
		}

		// NOTE: Used for debugging asteroid explosion
		// this._planetGroup.rotation.x = 2.1722358119999896;

		this._cameraController.init(this._postProcessing, this._sleigh, this._reindeers, this);

		let url = new URL(window.location.href);

		// Training level
		if (this._inTrainingLevel) {
			this._lights.sunIntensity = 2;
			this._trainingLevel = new TrainingLevel(this._controlsData, this._cameraController);
			Globals.TRAINING_LEVEL = this._trainingLevel;
			this._mainGroup.add(this._trainingLevel.group);

			this._postProcessing.setCamera(this._cameraController.intro);

			this._postProcessing.bloomExposure = 0;
			// TweenMax.to(this._postProcessing, 0.8, { delay: 2, bloomStrength: 0.8, bloomExposure: 0.7 });

			if (url.origin.indexOf('localhost') !== -1) {
				//	this._cameraController.introComplete();
			} else {
				// this._cameraController.gotoIntroScene1();
			}
		}
		// Animate in (level 1)
		else {
			TweenMax.to(this._postProcessing, 2, { vignetteDarkness: 1 });
			this.animateIn();
		}

		// Start render loop
		this._fixedTimestep = new FixedTimeStep(this.drawUpdate);
		this.render();
	}

	private setupTimelines = () => {
		this._animateInTimelineInitial = new TimelineMax({ paused: true });
		this._animateInTimelineInitial.to(this._controlsData, 3, {
			delay: 0.5,
			cameraLookY: this._animateInData.cameraLookY,
			cameraY: this._animateInData.cameraY,
			cameraZ: this._animateInData.cameraZ,
			deersY: 6.52,
			deersZ: 3.09,
			ease: Power1.easeInOut,
			fov: this._animateInData.fov,
			onUpdate: this._cameraController.updateFov,
			onComplete: this.flyInComplete as () => void
		});

		// this._animateInTimeline = new TimelineMax({ paused: true });
	};

	private setupScene() {
		this._cameraController = new CameraController(this._controlsData);

		this._scene = new THREE.Scene();
		this._scene.background = Globals.getNamedTexture('bg_viginette_00');

		this._renderer = new THREE.WebGLRenderer({ antialias: false, alpha: false, powerPreference: 'high-performance', depth: false, stencil: false });
		this._renderer.setSize(window.innerWidth, window.innerHeight);
		this._renderer.autoClear = false;
		this._renderer.domElement.style.zIndex = '5';
		this._renderer.domElement.style.position = 'absolute';
		this._renderer.domElement.style.top = '0px';
		this._renderer.debug.checkShaderErrors = false;

		this._postProcessing = new PostProcessing(this._scene, this._cameraController.main, this._renderer);
		Globals.POST_PROCESSING = this._postProcessing;

		this._renderer.setPixelRatio(1);

		document.body.appendChild(this._renderer.domElement);

		// if(Globals.isMobile) {
		// 	window.addEventListener("orientationchange", this.onWindowResize, false);
		// }

		window.addEventListener('resize', this.onWindowResize, false);
		this.onWindowResize();

		this._scene.add(this._mainGroup);
	}

	private setupWorld() {
		// Add Sleigh
		this._sleigh = new Sleigh();

		this._reindeers = new Reindeers(this._inTrainingLevel);
		this._sleigh.group.add(this._reindeers.group);

		// Collision check object
		let geo = new THREE.SphereGeometry(1, 12, 12);
		let mat = new THREE.MeshBasicMaterial({ color: 0x00ee11 });
		this._collisionObject = new THREE.Mesh(geo, mat);
		this._collisionObject.visible = false;
		this._sleigh.group.add(this._collisionObject);
		this._collisionObject.position.set(0, 1, -4.5);
		this._mainGroup.add(this._sleigh.group);

		// Add Planets
		// planet radius
		let ratio = 0.0124 / 6.5;
		let scale = ratio * Globals.SPHERE_RADIUS;

		// Planet 1 (Main planet)
		this._mainPlanet = Globals.getNamedObject('Planet_1');
		let rings = this._rings;

		let _mat = this._standardMaterial;
		this._mainPlanet.traverse(function(node) {
			if (node.isMesh) {
				if (node.name.toLowerCase().indexOf('ring') !== -1) {
					rings[0].push(node.clone());
				}

				node.material = _mat;
				node.material.needsUpdate = true;
				node.geometry = new THREE.Geometry().fromBufferGeometry(node.geometry);
				node.geometry.mergeVertices();
				node.geometry.computeVertexNormals();
				node.geometry = new THREE.BufferGeometry().fromGeometry(node.geometry);
				node.receiveShadow = true;
			}
		});

		this._mainPlanet.receiveShadow = true;
		this._mainPlanet.castShadow = false;
		this._planetGroup.add(this._mainPlanet);
		this._mainPlanet.scale.set(scale, scale, scale);

		// Planet 2
		this._planet2 = Globals.getNamedObject('Planet_3');

		this._planet2.traverse(function(node) {
			if (node.isMesh) {
				if (node.name === 'Planet_3_Ring') {
					TweenMax.to(node.rotation, 40, { z: TAU, repeat: -1, ease: Linear.easeNone });
				} else {
					TweenMax.to(node.rotation, 40, { x: TAU, repeat: -1, ease: Linear.easeNone });
				}

				if (node.name.toLowerCase().indexOf('ring') !== -1) {
					rings[1].push(node.clone());
				}

				node.material = _mat;
				node.material.needsUpdate = true;
				node.geometry = new THREE.Geometry().fromBufferGeometry(node.geometry);
				node.geometry.mergeVertices();
				node.geometry.computeVertexNormals();
				node.geometry = new THREE.BufferGeometry().fromGeometry(node.geometry);
				node.receiveShadow = true;
			}
		});

		this._planet2.receiveShadow = false;
		this._planet2.castShadow = false;
		this._planet2.position.x = -60;
		this._planet2.position.y = 5;
		this._planet2.position.z = -350;
		this._planet2.scale.set(scale, scale, scale);
		this._planet2Group.add(this._planet2);

		// Planet 3 (only using the rings from this one)
		let planet3 = Globals.getNamedObject('Planet_8');

		planet3.traverse(function(node) {
			if (node.isMesh) {
				if (node.name.toLowerCase().indexOf('ring') !== -1) {
					rings[2].push(node.clone());
				}

				node.material = _mat;
				node.material.needsUpdate = true;
				node.geometry = new THREE.Geometry().fromBufferGeometry(node.geometry);
				node.geometry.mergeVertices();
				node.geometry.computeVertexNormals();
				node.geometry = new THREE.BufferGeometry().fromGeometry(node.geometry);
				node.receiveShadow = true;
			}
		});

		// Shooting stars
		this._lineGenerator = new CustomLineGenerator(this._scene);
		this._lineGenerator.start();
		this._scene.add(this._lineGenerator);

		// World particles
		this._worldParticles = new WorldParticles();
		this._planetGroup.add(this._worldParticles.element);

		this._scene.add(this._planetGroupOuter);
		this._planetGroupOuter.add(this._planetGroup);
	}

	public animateIn = (initial: boolean = true) => {
		this._gameStarted = false;
		this._intro = false;
		this._isInitial = initial;

		TweenMax.to(this._pauseFactor, 0.5, { value: 1 });

		// HUD
		TweenMax.delayedCall(0.2, Globals.gameHUD.animateInVideo);
		TweenMax.delayedCall(2.2, Globals.gameHUD.animateInRotationCircle);
		TweenMax.delayedCall(4.2, Globals.gameHUD.animateInGameInfoBar);
		TweenMax.delayedCall((initial ? 0.5 : 0) + 3, Globals.topContent.animateIn);
		TweenMax.delayedCall((initial ? 0.5 : 0) + 3, Globals.footer.animateIn);

		// Animate in
		this._sleigh.z = 5;

		if (initial) {
			Globals.bgMusic.play(0, { offset: 0, gain: 0.3, loop: true, loopStart: 8.0743, loopEnd: 128.9 });
			let introMusic = Globals.AUDIO_MANAGER.getClip('intro_bg_music');
			TweenMax.to(introMusic.gain, 1, { delay: 0.3, value: 0, onComplete: this.stopIntroMusic });

			this._animateInTimelineInitial.play(0);
		} else {
			let implode = Globals.AUDIO_MANAGER.getClip('hyperspeed_implode');
			implode.play(0, { gain: 0.5 });

			// this._animateInTimeline.play(0);

			TweenMax.to(this._controlsData, 3, {
				delay: initial ? 0.5 : 0,
				cameraLookY: this._animateInData.cameraLookY,
				cameraY: this._animateInData.cameraY,
				cameraZ: this._animateInData.cameraZ,
				deersY: 6.52,
				deersZ: 3.09,
				ease: initial ? Power1.easeInOut : Power1.easeOut,
				fov: this._animateInData.fov,
				onUpdate: this._cameraController.updateFov,
				onComplete: this.flyInComplete as () => void
			});

			TweenMax.to(Globals, 1, { delay: (initial ? 0.5 : 0) + 2, worldRotationSpeed: this._levelSpeeds[this._currentLevel] });

			TweenMax.to(this._worldParticles, 3, { delay: 2, alpha: 1 });

			TweenMax.to(this._controlsData, 1, {
				delay: 2,
				timescale: this._animateInData.timescale,
				ease: Power1.easeOut
			});
		}

		TweenMax.to(this._lights, 3, { delay: initial ? 0.5 : 0, sunIntensity: 1.5 });

		TweenMax.to(this._reindeers, initial ? 3 : 0.5, { delay: initial ? 0.5 : 0, alpha: 1 });
		TweenMax.to(this._sleigh, initial ? 3 : 0.5, { delay: initial ? 0.5 : 0, alpha: 1 });
		TweenMax.to(this._postProcessing, initial ? 3 : 0.5, { delay: initial ? 0.5 : 0, bloomStrength: 0.8, bloomExposure: 0.7, vignetteOffset: 1, vignetteDarkness: 1.2 });
	};

	private stopIntroMusic = () => {
		let introMusic = Globals.AUDIO_MANAGER.getClip('intro_bg_music');
		introMusic.stop();
	};

	private addGui = () => {
		// if (Globals.DEBUG) {
		let camera = Globals.GUI.addFolder('Camera');
		// 	camera
		// 		.add(this._controlsData, 'cameraX', -100, 100)
		// 		.step(0.01)
		// 		.listen();
		// 	camera
		// 		.add(this._controlsData, 'cameraY', -100, 300)
		// 		.step(0.01)
		// 		.listen();
		camera
			.add(this._controlsData, 'cameraZ', -100, 500)
			.step(0.01)
			.listen();
		// 	camera
		// 		.add(this._controlsData, 'cameraLookX', -360, 360)
		// 		.step(0.01)
		// 		.onChange(this.updateCameraLook)
		// 		.listen();
		// 	camera
		// 		.add(this._controlsData, 'cameraLookY', -100, 100)
		// 		.step(0.01)
		// 		.onChange(this.updateCameraLook)
		// 		.listen();
		// 	camera
		// 		.add(this._controlsData, 'cameraLookZ', -200, 100)
		// 		.step(0.01)
		// 		.onChange(this.updateCameraLook)
		// 		.listen();
		//
		// 	camera
		// 		.add(this._controlsData, 'fov', 10, 100)
		// 		.name('Fov')
		// 		.listen()
		// 		.onChange(this._cameraController.updateFov);
		//
		// 	camera
		// 		.add(this._controlsData, 'firstPerson', false, true)
		// 		.name('First Person')
		// 		.onChange(this.updateCameraMode);
		// 	//
		camera.open();
		//
		// let deers = Globals.GUI.addFolder('Deers');
		//
		// deers
		// 	.add(this._controlsData, 'deersX', -100, 100)
		// 	.step(0.01)
		// 	.listen();
		// deers
		// 	.add(this._controlsData, 'deersY', -100, 100)
		// 	.step(0.01)
		// 	.listen();
		// deers
		// 	.add(this._controlsData, 'deersZ', -100, 600)
		// 	.step(0.01)
		// 	.listen();
		//
		// 	deers
		// 		.add(this._controlsData, 'sleightRotationY', -360, 360)
		// 		.name('Rotation Y')
		// 		.step(0.01)
		// 		.listen();
		//
		// 	deers
		// 		.add(this._controlsData, 'sleightRotationX', -360, 360)
		// 		.name('Rotation X')
		// 		.step(0.01)
		// 		.listen();
		//
		// deers.open();
		// }

		let world = Globals.GUI.addFolder('World');

		this._controlsData.hyperspeed = this.allGiftsPickedUp;
		world
			.add(this._controlsData, 'timescale', -1, 2)
			.name('Timescale')
			.step(0.01)
			.listen();

		world.add(this._controlsData, 'hyperspeed').name('Hyperspeed');

		// world.add(this._controlsData, 'restart').name('Restart Level');
		world.open();
	};

	private updateCameraLook = () => {
		// this._cameraController.mainLookTarget.x = this._controlsData.cameraLookX;
		// this._cameraController.mainLookTarget.y = this._controlsData.cameraLookY;
		// this._cameraController.mainLookTarget.z = this._controlsData.cameraLookZ;
		//
		// this._cameraController.main.lookAt(this._lookAt);
	};

	private updateCameraMode = () => {
		TweenMax.to(this._controlsData, 0.7, {
			// cameraLookY: this._controlsData.firstPerson ? this._animateInData.cameraLookY : this._initialData.cameraLookY,
			// cameraY: this._controlsData.firstPerson ? this._animateInData.cameraY : 4.3,
			// cameraZ: this._controlsData.firstPerson ? this._animateInData.cameraZ : 30,
			fov: this._controlsData.firstPerson ? 25 : this._hyperspeedAnimationData.fov,
			ease: Power2.easeOut,
			onUpdate: this._cameraController.updateFov
		});
	};

	private flyInComplete = () => {
		TweenMax.to(Globals, 1, { worldRotationSpeed: this._levelSpeeds[this._currentLevel] });

		TweenMax.to(this._worldParticles, 3, { alpha: 1 });

		TweenMax.to(this._controlsData, 1, {
			timescale: this._animateInData.timescale,
			ease: Power1.easeOut
		});

		this._allGiftsPickedUp = false;

		this._gameStarted = true;
		this._spawner.addObjects();

		this._trackRibbons.animateIn();

		if (!this._isInitial && !Globals.noWebcam) {
			this.togglePause(!this._controlsActive);
		} else {
			this.toggleControls(this._controlsActive);
		}
	};

	private shakeCamera = () => {
		this._cameraShake.shake(this._cameraController.main, new THREE.Vector3(0, 0, 0.2), 0.2);
	};

	private allGiftsPickedUp = (triggered: boolean = true) => {
		this._allGiftsPickedUp = true;

		// Center planet and sleigh
		TweenMax.to(this._sleigh.group.rotation, 1, { delay: 0.5, z: 0, ease: Power1.easeInOut });
		TweenMax.to(this._sleigh.group.rotation, 1, { delay: 0.5, y: 0, ease: Power1.easeInOut });
		TweenMax.to(this._planetGroupOuter.position, 1, { delay: 0.5, x: 0, ease: Power1.easeInOut });

		TweenMax.delayedCall(triggered ? 0 : 2, this.gotoHyperSpeed);
	};

	private gotoHyperSpeed = () => {
		this._inHyperspeed = true;

		this.togglePause(false);

		// Make sure rings are not in the way of the lightspeed tunnel
		this.rotateRingsOutOfView();

		// Update HUD
		Globals.gameHUD.animateOutRotationAngle();
		Globals.gameHUD.animateOutGameInfoBar();
		Globals.gameHUD.animateOutVideo();
		Globals.levelScreen.animateIn(2);

		// Clean up remaining objects
		this._spawner.killAllObjects();

		// Camera
		TweenMax.to(this._controlsData, 3, {
			cameraY: this._hyperspeedAnimationData.cameraLookY,
			cameraLookY: this._hyperspeedAnimationData.cameraLookY,
			cameraLookZ: this._hyperspeedAnimationData.cameraLookZ,
			cameraX: 0,
			cameraZ: this._hyperspeedAnimationData.cameraZ,
			deersZ: -15.93,
			ease: Power1.easeInOut //,
		});

		TweenMax.to(this._sleigh, 3, { z: 0.6 });

		// Timescale
		TweenMax.to(this._controlsData, 2, {
			timescale: 4,
			ease: Power1.easeInOut
		});

		// Fov
		TweenMax.to(this._controlsData, 1, {
			delay: 3,
			onUpdate: this._cameraController.updateFov,
			ease: Power1.easeInOut,
			fov: this._hyperspeedAnimationData.fov
		});

		// Deers forward
		TweenMax.to(this._controlsData, 4, { delay: 5, deersZ: -100, ease: Power1.easeInOut });

		// Lightspeed Tunnel
		this._lightspeedTunnel.level = this._currentLevel;
		TweenMax.to(this._lightspeedTunnel, 4, { delay: 1.5, opacity: 1 });
		TweenMax.to(this._lightspeedTunnel, 3, { delay: 1, speed: -0.003 });
		// TweenMax.delayedCall(1, this._lightspeedTunnel.animateIn);

		// Lights
		TweenMax.to(this._lights, 4, { delay: 1.5, sunIntensity: 0 });

		// planet
		TweenMax.to(this._planet2Group.position, 4, { x: this._currentLevel === 0 || this._currentLevel === 2 ? -100 : 100, z: 300 });

		// Asteroid field
		TweenMax.to(this._asteroidField, 3, { delay: 1, alpha: 0 });

		// Change Level
		TweenMax.delayedCall(7.7, this.changeLevel as () => void);

		// Trigger sounds
		TweenMax.delayedCall(1.6, Globals.AUDIO_MANAGER.trigger as () => void, ['hyperspeed', 1]);
		TweenMax.delayedCall(4.3, Globals.AUDIO_MANAGER.trigger as () => void, ['hoho', 0.7]);

		// Post processing
		TweenMax.to(this._postProcessing, 4, { delay: 1.5, bloomExposure: 0.8, bloomStrength: 1.7, bloomRadius: 1 });
		TweenMax.to(this._postProcessing, 1, { delay: 6, bloomStrength: 30, bloomRadius: 0.87, bloomExposure: 3 });
		TweenMax.to(this._postProcessing, 1, { delay: 6, vignetteOffset: 0 });

		// Stop Shooting stars
		this._lineGenerator.stop();
	};

	private rotateRingsOutOfView = () => {
		let normalizedAngle = normalizeAngle(this._planetGroup.rotation.x);
		let normalizedDegrees = radiansToDegrees(normalizedAngle);
		let offsetX = false;
		let ringOffset;

		if (normalizedDegrees > 0 && normalizedDegrees < 90) {
			offsetX = true;

			if (normalizedDegrees <= 45) {
				ringOffset = -(Math.PI * 0.5); //shortestAngle(normalizedAngle, normalizedAngle - (Math.PI * 0.5));
			} else {
				ringOffset = Math.PI * 0.5; //shortestAngle(normalizedAngle, normalizedAngle + (Math.PI * 0.5));
			}
		}

		this._mainPlanet.traverse(function(node) {
			if (node.isMesh) {
				if (node.name.toLowerCase().indexOf('ring') !== -1) {
					TweenMax.killTweensOf(node.rotation);
					TweenMax.to(node.rotation, 2, { x: offsetX ? ringOffset : null, y: shortestAngle(node.rotation.y, 0), z: shortestAngle(node.rotation.z, 0), ease_: Power1.easeInOut });
				}
			}
		});
	};

	private changeLevel = (restart: boolean = false) => {
		this._currentLevel++;
		// this._currentLevel = 1;

		/*	if (this._currentLevel > 2) {
			this._currentLevel = 0;
		}*/

		Globals.gameVariables.currentLevel = this._currentLevel;

		Globals.levelScreen.animateOut();

		// Resetting HUD
		Globals.gameHUD.resetHealthBar();
		Globals.gameHUD.updateLevel(this._currentLevel + 1);

		if (this._currentLevel > 2) {
			//console.log('Game completed, xmas is saved!');
			// FIXME - show "Christmas is saved screen!"
			Globals.levelsCompleted.animateIn();
		} else {
			// // Change colors based on new level
			// var newBGColor = 'rgba(0, 0, 100, 0.9)';
			// if (this._currentLevel === 1) {
			// 	newBGColor = 'rgba(100, 0, 0, 0.9)';
			// } else if (this._currentLevel === 2) {
			// 	newBGColor = 'rgba(0, 100, 0, 0.9)';
			// }

			// Globals.tryAgainScreen.changeBGColor(newBGColor);
			// Globals.about.changeBGColor(newBGColor);

			// if(!restart) {
			this.swapPlanetRings();
			// }

			this._inHyperspeed = false;

			this._lineGenerator.start();

			this._lightspeedTunnel.opacity = 0;
			this._lightspeedTunnel.speed = 0;
			// this._lightspeedTunnel.reset();

			this._postProcessing.bloomExposure = 0.8;

			TweenMax.killTweensOf(this._controlsData);

			this._controlsData.cameraLookX = this._initialData.cameraLookX;
			this._controlsData.cameraLookY = this._initialData.cameraLookY;
			this._controlsData.cameraLookZ = this._initialData.cameraLookZ;
			this._controlsData.cameraX = this._initialData.cameraX;
			this._controlsData.cameraY = this._initialData.cameraY;
			this._controlsData.cameraZ = this._initialData.cameraZ;
			this._controlsData.timescale = this._initialData.timescale;
			this._controlsData.firstPerson = this._initialData.firstPerson;
			this._controlsData.deersY = this._initialData.deersY;
			this._controlsData.deersZ = this._initialData.deersZ;

			this._controlsData.fov = this._initialData.fov;
			this._cameraController.updateFov();

			this._planet2Group.position.x = 0;
			this._planet2Group.position.z = 0;
			this._planetGroup.rotation.x = 0;

			this._asteroidField.group.rotation.x = 0;

			this._worldParticles.alpha = 0;

			this._asteroidField.level = this._currentLevel;
			this._asteroidField.alpha = 1;

			this._planet2.position.x = this._currentLevel === 0 || this._currentLevel === 2 ? -67 : 60;
			this._lights.setPlanet2Position(new THREE.Vector3(this._currentLevel === 0 || this._currentLevel === 2 ? -67 : 60, this._currentLevel === 0 || this._currentLevel === 2 ? 5 : -26, -350));
			this._lights.sunIntensity = 0;

			this._trackRibbons.reset();
			this._trackRibbons.setLevel(this._currentLevel);

			this._spawner.level = this._currentLevel;

			this._worldParticles.changeLevel(this._currentLevel);

			this._scene.background = Globals.getNamedTexture('bg_viginette_0' + this._currentLevel);

			this._reindeers.changeLevel(this._currentLevel);
			this._sleigh.changeLevel(this._currentLevel);

			let planetColor = this._planetColors[this._currentLevel];

			// Update planet color
			this._mainPlanet.traverse(function(node) {
				if (node.isMesh) {
					node.material.color = new THREE.Color(planetColor);
					node.material.fog = false;
					node.material.needsUpdate = true;
				}
			});

			this.animateIn(false);
		}
	};

	private swapPlanetRings = () => {
		// Remove current rings
		let ringsToRemove = [];
		this._mainPlanet.traverse(function(node) {
			if (node.isMesh) {
				if (node.name.toLowerCase().indexOf('ring') !== -1) {
					ringsToRemove.push(node);
				}
			}
		});

		let l = ringsToRemove.length;
		for (let i = 0; i < l; i++) {
			TweenMax.killTweensOf(ringsToRemove[i].rotation);
			this._mainPlanet.remove(ringsToRemove[i]);
		}

		// Add new rings
		let rings = this._rings[this._currentLevel];
		l = rings.length;

		for (let i = 0; i < l; i++) {
			if (rings[i].name === 'Planet_3_Ring') {
				TweenMax.to(rings[i].rotation, 40, { z: TAU, repeat: -1, ease: Linear.easeNone });
			} else if (rings[i].name === 'Planet_8_Ring') {
				TweenMax.to(rings[i].rotation, 40, { y: TAU, repeat: -1, ease: Linear.easeNone });
			}

			this._mainPlanet.add(rings[i]);
		}
	};

	private onWindowResize = () => {
		this._renderer.setSize(window.innerWidth, window.innerHeight);

		this._postProcessing.resize();

		this._cameraController.resize();
	};

	private render = () => {
		requestAnimationFrame(this.render);
		this._fixedTimestep.update();
	};

	private drawUpdate = () => {
		// Camera
		this._cameraController.main.position.x = this._controlsData.cameraX;
		this._cameraController.main.position.y = this._controlsData.cameraY;
		this._cameraController.main.position.z = this._controlsData.cameraZ;

		this._lookAt.x = this._controlsData.cameraLookX;
		this._lookAt.y = this._controlsData.cameraLookY;
		this._lookAt.z = this._controlsData.cameraLookZ;

		this._cameraController.main.lookAt(this._lookAt);

		// Sleigh
		this._sleigh.group.position.x = this._controlsData.deersX;
		this._sleigh.group.position.y = this._controlsData.deersY;
		this._sleigh.group.position.z = this._controlsData.deersZ;

		this._cameraShake.update(this._cameraController.main);

		if (Globals.measureWebGLAnimateStarts) {
			Globals.stats.begin();
		}

		// Planet rotation
		if (!this._inHyperspeed && !this._inTrainingLevel) {
			this._planetGroup.rotation.x += this._controlsData.timescale * this._pauseFactor.value * Globals.worldRotationSpeed;
		}

		// planet steering
		if (!this._allGiftsPickedUp || this._inTrainingLevel) {
			let normalizedRotation = Globals.wheelRotationTweened.number;

			if (!this._inTrainingLevel) {
				let addToRotation = clamp(this._planetGroupOuter.position.x + THREE.Math.degToRad(Globals.wheelRotationTweened.number), -1, 1);
				TweenMax.to(this._planetGroupOuter.position, 0.2, { x: addToRotation, ease: Power1.easeOut });

				TweenMax.to(this._asteroidField.group.rotation, 0.2, { z: degreesToRadians(addToRotation * 2), ease: Power1.easeOut });
				TweenMax.to(this._asteroidField.group.position, 0.2, { x: addToRotation * 10, ease: Power1.easeOut });
				TweenMax.to(this._planet2Group.position, 0.2, { x: addToRotation * 20, ease: Power1.easeOut });

				TweenMax.to(this._sleigh.group.rotation, 0.3, { y: clamp(THREE.Math.degToRad(normalizedRotation), -0.17, 0.17) + degreesToRadians(this._controlsData.sleightRotationY) });

				this._spawner.checkCollisions(this._collisionObject, this._planetGroup.rotation.x);
			}
			// Training level
			else if (!this._intro) {
				this._trainingLevel.update(this._pauseFactor.value);

				if (!this._trainingLockCamera) {
					let addToRotation = clamp(this._trainingLevel.group.position.x + THREE.Math.degToRad(Globals.wheelRotationTweened.number), -1, 1);
					TweenMax.to(this._trainingLevel.group.position, 0.2, { x: addToRotation, ease: Power1.easeOut });

					let rotation = clamp(THREE.Math.degToRad(normalizedRotation), -0.17, 0.17);

					if (this._inTrainingLevel) {
						TweenMax.to(this._sleigh.group.rotation, 0.3, { y: rotation + degreesToRadians(this._controlsData.sleightRotationY) });
					}

					// Step 1 (Lock camera and move forward to pick up gift)
					if (this._trainingLevel.step === 0 && addToRotation >= 0.6 && this._trainingLevel.giftSpawned) {
						this._trainingLockCamera = true;

						TweenMax.to(this._trainingLevel.sphere.rotation, 0.6, { delay: 0.1, x: degreesToRadians(29), ease: Power1.easeInOut });
						TweenMax.to(this._sleigh.group.rotation, 0.3, { y: 0 + degreesToRadians(this._controlsData.sleightRotationY) });
					}
					// Step 2 (When asteroid is avoided, go to level 1)
					else if (this._trainingLevel.step === 1 && addToRotation <= 0.2) {
						// this._trainingLockCamera = false;
						this._inTrainingLevel = false;

						// TweenMax.delayedCall(0.5, this._trainingLevel.explodeAsteroid);

						TweenMax.to(this._trainingLevel.sphere.rotation, 0.6, { delay: 0.1, x: degreesToRadians(76), ease: Power1.easeInOut, onComplete: this.showSuccessPopup });

						TweenMax.to(this._sleigh.group.rotation, 0.3, { y: 0 + degreesToRadians(this._controlsData.sleightRotationY) });

						let delayBase = 0.5;
						TweenMax.delayedCall(1.5 + delayBase, Globals.instructionText.animateOut);
						TweenMax.delayedCall(2 + delayBase, this.animateIn as () => void, [true]);
						TweenMax.delayedCall(2 + delayBase, () => {
							Globals.trainingLevelDone = true;
							this._trainingLockCamera = false;
						});

						TweenMax.delayedCall(5 + delayBase, this.cleanUpTrainingLevel);
					}
				}

				let hit = this._trainingLevel.checkCollision(this._collisionObject);

				if (hit === true) {
					TweenMax.delayedCall(2, this.toggleCameraLock as () => void, [false]);
					// this._trainingLockCamera = false;
				}
			} else {
				TweenMax.to(this._sleigh.group.rotation, 0.3, { y: degreesToRadians(this._controlsData.sleightRotationY), x: degreesToRadians(this._controlsData.sleightRotationX) });
			}
		}

		this._reindeers.timeScale = clamp(this._controlsData.timescale, this._gameStarted ? -1 : 0.2, 1.5) * this._pauseFactor.value;
		this._reindeers.update();

		this._worldParticles.render();
		this._lineGenerator.update();

		this._postProcessing.update();

		this._asteroidField.group.rotation.x += this._controlsData.timescale * this._pauseFactor.value * (Globals.worldRotationSpeed * 0.1);
		this._asteroidField.render();

		if (this._inHyperspeed) {
			this._lightspeedTunnel.render();
		} else {
			this._trackRibbons.update();
		}

		if (Globals.measureWebGLAnimateStarts) {
			Globals.stats.end();
		}
	};

	private showSuccessPopup = () => {
		Globals.instructionText.changeText('Good job - let’s go!');
	};

	private toggleCameraLock = value => {
		this._trainingLockCamera = value;
	};

	private cleanUpTrainingLevel = () => {
		// console.log('cleanUpTrainingLevel');

		this._trainingLevel.cleanUp();
		this._trainingLevel = null;
	};

	public restartCurrentLevel = (restartGame: boolean = false) => {
		if (!restartGame) {
			this._currentLevel--;
		} else {
			Globals.topContent.restartTimer();
			Globals.topContent.startTimer();
			this._currentLevel = -1;
		}

		this._spawner.killAllObjects();
		this.changeLevel(true);
	};

	public toggleIntroActive = (value: boolean) => {
		this._intro = value;

		/*	if (Globals.noWebcam) {
			this._trainingLevel.addGift();
		} else {*/
		this._trainingLevel.start();
		//}

		this._sleigh.z = 1.9;

		// this._controlsData.deersY = -0.52;
		// this._controlsData.deersZ = -1;
	};

	public tweenTimeScale = value => {
		// TweenMax.to(this._controlsData, 1, {
		// 	// cameraY: 3,
		// 	timescale: value,
		// 	ease: Power1.easeInOut
		// });
	};

	public togglePause = (state: boolean) => {
		// console.log('togglePause: ' + state);

		this._paused = state;

		TweenMax.to(this._pauseFactor, 0.5, { value: state ? 0 : 1 });

		if (Globals.bgMusic.source) {
			TweenMax.to(Globals.bgMusic.source.playbackRate, 0.5, { value: state ? 0.2 : 1 });
		}

		TweenMax.to(Globals.mainScene.postProcessing, 0.3, { vignetteOffset: state ? 1.4 : 0, bloomStrength: state ? 0.6 : 0.8 });

		TweenMax.to(this._animateInParams, 0.3, { timescale: state ? 0 : 1, onUpdate: this.updateTimescale });

		// if (!this._gameStarted && state) {
		// 	TweenMax.to(this._animateInParams, 0.3, {timescale: 0, onUpdate: this.updateTimescale});
		// } else if (!this._gameStarted && !state) {
		// 	TweenMax.to(this._animateInParams, 0.3, {timescale: 1, onUpdate: this.updateTimescale});
		// }
	};

	public toggleControls = (state: boolean) => {
		this._controlsActive = state;

		if (!this._inHyperspeed && (this._gameStarted || this._isInitial)) {
			this.togglePause(!state);
		}
	};

	public isInHyperSpeed = () => {
		return this._inHyperspeed;
	};

	public getPauseFactor = () => {
		return this._pauseFactor.value;
	};

	private updateTimescale = () => {
		this._animateInTimelineInitial.timeScale(this._animateInParams.timescale);
	};

	public showMainPlanet = () => {
		this._mainPlanet.visible = true;
	};

	get mainGroup() {
		return this._mainGroup;
	}

	get postProcessing() {
		return this._postProcessing;
	}

	get level() {
		return this._currentLevel;
	}

	get cameraController() {
		return this._cameraController;
	}
}
