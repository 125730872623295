import { Linear, Power3, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';

export class LevelScreen {
	private _element;
	private _isShown: boolean = false;

	private _wayToGo;
	private _level;

	constructor(element) {
		this._element = element;
		this.init();
	}

	private init() {
		this._level = this._element.querySelector('.level');
		this._wayToGo = this._element.querySelector('.wayToGo');
		/*	this._pentagram = this._element.querySelector('.pentagramImage');

			this._clickToContinue = this._element.querySelector('.clickToContinue');
			this._clickToContinue.addEventListener('click', this.animateOut);*/

		TweenMax.set(this._element, { opacity: 0 });
	}

	public animateIn = delay => {
		if (this._isShown === false) {
			// Add text to the level screen
			this._level.innerHTML = '— LEVEL ' + (Globals.gameVariables.currentLevel + 1) + ' COMPLETED —';

			let textArray = ['Excellent deer steering', 'Next level sledding', 'Christmas is saved!'];

			window['ga']('send', 'event', 'Level Completed', Globals.gameVariables.currentLevel + 1);

			TweenMax.delayedCall(delay, Globals.AUDIO_MANAGER.trigger as () => void, ['level_complete_' + (Globals.gameVariables.currentLevel + 1), 1]);

			this._wayToGo.innerHTML = textArray[Globals.gameVariables.currentLevel];
			this._isShown = true;
			this._element.style.display = 'block';
			TweenMax.killTweensOf(this._element);
			TweenMax.to(this._element, 0.7, { delay: delay, opacity: 1, ease: Power3.easeOut });
			//TweenMax.delayedCall(5, this.autoAnimateOut);
		}
	};

	private autoAnimateOut = () => {
		if (this._isShown === true) {
			this.animateOut();
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			this._isShown = false;
			TweenMax.killTweensOf(this._element);
			TweenMax.killDelayedCallsTo(this.autoAnimateOut);
			TweenMax.to(this._element, 0.0, { opacity: 0, ease: Power3.easeIn, onComplete: this.hide });
		}
	};

	public hide = () => {
		this._element.style.display = 'none';
	};
}
