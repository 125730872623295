import * as THREE from 'three';
import { Globals } from '../../utils/Globals';
import { Power1, Power2, TimelineMax, TweenMax } from 'gsap/TweenMax';
import { clamp, degreesToRadians, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';

export class LightspeedTunnel {
	public group: THREE.Group = new THREE.Group();

	private _speed: number = 8;
	private _tubeMesh: THREE.Mesh;
	private _tubeMaterial: THREE.MeshBasicMaterial;
	private _tubeGeometry: THREE.TubeGeometry;
	private _curve: THREE.CatmullRomCurve3;
	private _splineMesh: THREE.Line;

	// private _texture = Globals.getNamedTexture('hyperspeed_tunnel');

	private _textures = [Globals.getNamedTexture('hyperspeed_tunnel'), Globals.getNamedTexture('hyperspeed_tunnel2'), Globals.getNamedTexture('hyperspeed_tunnel3')];

	private _textureParams = {
		offsetX: 0,
		offsetY: 0,
		repeatX: 20, //0.313,
		repeatY: 4
	};

	private _params = {
		scale: 1,
		radius: 3,
		depth: 200,
		speed: 0, //-0.001,//0.004,
		rotation: 0,
		positionY: 7, //10,
		positionZ: 0, //-15,
		positionX: 0,
		opacity: 0
	};

	private _replacingMesh: boolean = false;

	private _pointLight: THREE.PointLight;

	private _sphere: THREE.Mesh;
	private _sphereMaterial: THREE.MeshPhongMaterial;

	private _level = 0;

	constructor() {
		this.group.rotation.y = Math.PI;

		this.group.position.y = this._params.positionY;
		this.group.position.z = this._params.positionZ;

		// this.group.visible = false;

		let geo = new THREE.SphereGeometry(3, 20, 20);
		//let geo = new THREE.PlaneGeometry(6, 6, 4, 4);
		// let mat = new THREE.MeshBasicMaterial({ color: 0xFF0000 });

		this._sphereMaterial = new THREE.MeshPhongMaterial({
			side: THREE.DoubleSide,
			map: this._textures[this._level],
			fog: false,
			transparent: true,
			opacity: 0,
			depthWrite: true
		});

		this._sphere = new THREE.Mesh(geo, this._sphereMaterial);
		this._sphere.position.set(0, 0, 194);
		this.group.add(this._sphere);

		this._sphere.renderOrder = -1;

		this.createMesh(this._textures[this._level]);

		// this._pointLight = new THREE.PointLight(0xFF0000, 0, 170);
		// this._pointLight.position.set(20, 0, -47);
		// this._pointLight.lookAt(new THREE.Vector3(0, 0, -200));

		// TweenMax.to(this._pointLight.position, 5,{x: -20, y: 0, yoyo: true, repeat: -1});

		// this.group.add(this._pointLight);

		if (Globals.DEBUG) {
			this.addGui();
		}
	}

	private createMesh = texture => {
		this._replacingMesh = true;

		let points = [];
		let geometry = new THREE.Geometry();

		this.group.remove(this._tubeMesh);

		for (let i = 0; i < 5; i += 1) {
			points.push(new THREE.Vector3(0, 0, this._params.depth * (i / 4)));
		}
		// points[4].y = -0.06;

		this._curve = new THREE.CatmullRomCurve3(points, false, 'catmullrom');
		//this._curve.type = 'catmullrom';

		// geometry = new THREE.Geometry();
		geometry.vertices = this._curve.getPoints(70);
		this._splineMesh = new THREE.Line(geometry, new THREE.LineBasicMaterial());

		this._tubeMaterial = new THREE.MeshPhongMaterial({
			side: THREE.DoubleSide,
			map: texture,
			fog: false,
			transparent: true,
			opacity: this._params.opacity,
			depthWrite: false
		});
		this._tubeMaterial.map.wrapS = THREE.MirroredRepeatWrapping;
		this._tubeMaterial.map.wrapT = THREE.MirroredRepeatWrapping;
		this._tubeMaterial.map.repeat.set(this._textureParams.repeatX, this._textureParams.repeatY);

		this._tubeGeometry = new THREE.TubeGeometry(this._curve, 70, this._params.radius, 30, true);
		this._tubeGeometry = this._tubeGeometry.clone();
		this._tubeMesh = new THREE.Mesh(this._tubeGeometry, this._tubeMaterial);

		this._tubeMesh.renderOrder = -1;

		this.group.add(this._tubeMesh);

		this._replacingMesh = false;
	};

	// public animateIn = () => {
	// 	TweenMax.to(this._params, 3, { speed: -0.001 });
	// 	//TweenMax.to(this._textureParams, 3,{repeatX: 0.313});
	// };

	// public reset = () => {
	// 	this._params.speed = 0;
	// };

	get opacity() {
		return this._params.opacity;
		//return this._tubeMaterial.opacity;
	}

	set opacity(value: number) {
		this._tubeMaterial.opacity = value;
		this._sphereMaterial.opacity = value;
		// this._pointLight.intensity = value;

		this._params.opacity = value;
	}

	get speed() {
		return this._params.speed;
	}

	set speed(value: number) {
		this._params.speed = value;
	}

	private updateMaterialOffset = () => {
		this._tubeMaterial.map.offset.x += this._params.speed; //0.1;//this._textureParams.offsetX;
		this._tubeMaterial.map.offset.y += 0.001;
		this._tubeMaterial.map.repeat.set(this._textureParams.repeatX, this._textureParams.repeatY);
	};

	private updateCurve = () => {
		let index = 0;
		let vertice_o = null;
		let vertice = null;

		for (let i = 0; i < this._tubeGeometry.vertices.length; i += 1) {
			vertice_o = this._tubeGeometry.vertices[i];
			vertice = this._tubeGeometry.vertices[i];
			index = Math.floor(i / 30);
			//@ts-ignore
			vertice.x += (vertice_o.x + this._splineMesh.geometry.vertices[index].x - vertice.x) / 15;
			//@ts-ignore
			vertice.y += (vertice_o.y + this._splineMesh.geometry.vertices[index].y - vertice.y) / 15;
		}
		this._tubeGeometry.verticesNeedUpdate = true;

		this._curve.points[2].x = 0.6 - 0.3;
		this._curve.points[3].x = 0;
		this._curve.points[4].x = 0.6 - 0.3;

		this._curve.points[2].y = 0.6 - 0.3;
		this._curve.points[3].y = 0;
		this._curve.points[4].y = 0.6 - 0.3;

		// this._curve.points[2].x = 0.6 * (1 - this.mouse.ratio.x) - 0.3;
		// this._curve.points[3].x = 0;
		// this._curve.points[4].x = 0.6 * (1 - this.mouse.ratio.x) - 0.3;
		//
		// this._curve.points[2].y = 0.6 * (1 - this.mouse.ratio.y) - 0.3;
		// this._curve.points[3].y = 0;
		// this._curve.points[4].y = 0.6 * (1 - this.mouse.ratio.y) - 0.3;

		//@ts-ignore
		this._splineMesh.geometry.verticesNeedUpdate = true;
		//@ts-ignore
		this._splineMesh.geometry.vertices = this._curve.getPoints(70);
	};

	public render = () => {
		if (!this._replacingMesh) {
			this.updateMaterialOffset();
		}

		// this.updateCurve();
	};

	public update = () => {
		this.createMesh(this._textures[this._level]);
	};

	private addGui = () => {
		let top = Globals.GUI.addFolder('Hyperspeed tunnel');
		// top.add(this._params, 'scale', 0.0001, 100)
		// 	.step(0.01)
		// 	.onChange(this.guiUpdate);
		top.add(this.group, 'visible');

		top.add(this.group.position, 'y', -15, 15)
			.step(0.01)
			.name('Position Y');
		top.add(this.group.position, 'z', -100, 100)
			.step(0.01)
			.name('Position Z');

		top.add(this._params, 'speed', -0.2, 0.2)
			.step(0.0001)
			.listen();

		top.add(this._textureParams, 'repeatX', 0, 20).step(0.001);

		top.add(this.group.rotation, 'z', 0, TAU)
			.name('Rotation z')
			.step(0.001);

		top.add(this.group.rotation, 'x', 0, TAU)
			.name('Rotation x')
			.step(0.001);

		top.add(this._params, 'radius', 0.02, 200)
			.step(0.01)
			.onChange(this.guiSizeUpdate);
		top.add(this._params, 'depth', -150, 1000)
			.step(0.01)
			.onChange(this.guiSizeUpdate);
		// top.add(this._params, 'positionY', -100, 100)
		// 	.step(0.1)
		// 	.onChange(this.guiUpdate);
		// top.add(this._params, 'positionZ', -100, 100)
		// 	.step(0.1)
		// 	.onChange(this.guiUpdate);

		top.add(this, 'opacity', 0, 1)
			.name('Opacity')
			.step(0.01);

		top.add(this._sphere.position, 'z', -400, 400)
			.name('Sphere Z')
			.step(0.01);

		// top.add(this._pointLight.position, 'z', 0, 200)
		// 	.name('Pointlight Z')
		// 	.step(0.01);
		//
		// top.add(this._pointLight.position, 'y', -400, 400)
		// 	.name('Pointlight Y')
		// 	.step(0.01);
		//
		// top.add(this._pointLight.position, 'x', -400, 400)
		// 	.name('Pointlight X')
		// 	.step(0.01);

		// top.open();
	};

	get level() {
		return this.level;
	}

	set level(value: number) {
		this._level = clamp(value, 0, 2);

		this._sphereMaterial.map = this._textures[this._level];
		this.createMesh(this._textures[this._level]);
	}

	private guiSizeUpdate = () => {
		this.createMesh(this._textures[this._level]);
	};

	private guiUpdate = () => {
		this._tubeMesh.scale.set(this._params.scale, this._params.scale, this._params.scale);
	};
}
