import * as THREE from 'three';
import AnimatedMeshLine from './AnimatedMeshLine';
import { randomFloat, randomInt, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';
import { Globals } from '../../utils/Globals';

const COLORS = ['#FFFFFF'].map(col => new THREE.Color(col));

export class LineGenerator extends THREE.Object3D {
	public sphere;

	private _frequency: number;
	private _lineStaticProps;
	private _isStarted: boolean = false;
	private _lines = [];
	private _numberOfLines = -1;

	private _origin = new THREE.Vector3();
	private _raycaster = new THREE.Raycaster();
	private _direction = new THREE.Vector3();

	private _radius: number = 30;
	private _diameter: number = this._radius * 2;

	private _spread = { min: 0, max: 0.3 };

	constructor(frequency = 0.1, lineProps) {
		super();

		this._frequency = frequency;
		this._lineStaticProps = lineProps;

		if (Globals.DEBUG) {
			this.addGui();
		}
	}

	private addGui = () => {
		let top = Globals.GUI.addFolder('Line Generator');
		top.add(this._spread, 'min', 0, 1)
			.step(0.01)
			.name('Spread min');
		top.add(this._spread, 'max', 0, 1)
			.step(0.01)
			.name('Spread max');
		// top.open();
	};

	public start = () => {
		this._isStarted = true;
	};

	public stop = () => {
		this._isStarted = false;
	};

	public addLine(props?) {
		let p = this.getProps();

		if (p.points) {
			let line = new AnimatedMeshLine(p);
			this._lines.push(line);
			this.add(line);
			this._numberOfLines++;
		}
	}

	private removeLine = line => {
		this.remove(line);
		this._numberOfLines--;
	};

	public update = () => {
		// Add lines randomly
		if (this._isStarted && Math.random() < this._frequency) {
			this.addLine();
		}

		// Update current Lines
		for (let i = this._numberOfLines; i >= 0; i--) {
			this._lines[i].update();
		}

		// Filter and remove dead lines
		let filteredLines = [];
		for (let i = this._numberOfLines; i >= 0; i--) {
			if (this._lines[i].died) {
				this.removeLine(this._lines[i]);
			} else {
				filteredLines.push(this._lines[i]);
			}
		}
		this._lines = filteredLines;
	};

	private getProps = () => {
		// let x = randomFloat(-(this._radius * 0.2), this._radius * 0.3);
		let y = randomFloat(-(this._diameter * this._spread.min), this._diameter * this._spread.max); //-this._radius;//randomFloat(-this._radius, this._radius);
		let a = 0;

		// how far should the line travel around the sphere
		let aMax = Math.PI; // Math.PI; //TAU;

		let points = [];
		while (a < aMax) {
			a += 0.2;
			this._origin.set(this._radius * Math.cos(a), y, this._radius * Math.sin(a));
			this._direction.set(-this._origin.x, 0, -this._origin.z);

			// NOTE: Use this to use X axis as rotation instead
			// this._origin.set(x, this._radius * Math.cos(a), this._radius * Math.sin(a));
			// this._direction.set(0, -this._origin.y, -this._origin.z);

			this._direction.normalize();
			this._raycaster.set(this._origin, this._direction);

			// save the points
			const intersect = this._raycaster.intersectObject(this.sphere, true);

			if (intersect.length) {
				points.push(intersect[0].point.x, intersect[0].point.y, intersect[0].point.z);
			}
		}

		return {
			visibleLength: randomFloat(0.01, 0.05), //0.2), // randomFloat(0.05, 0.2), //
			points: points.length === 0 ? null : points,
			speed: 0.008, //randomFloat(0.003, 0.005),//randomFloat(0.003, 0.008), // randomFloat(0.01, 0.1), //
			color: this.getRandomItem(COLORS),
			width: randomFloat(0.01, 0.1)
		};
	};

	get radius() {
		return this._radius;
	}

	set radius(value: number) {
		this._radius = value;
		this._diameter = this._radius * 2;
	}

	private getRandomItem = arr => {
		return arr[randomInt(0, arr.length - 1)];
	};
}
