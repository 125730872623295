import * as THREE from 'three';
import { Globals } from '../../utils/Globals';
import { GlowMaterial } from '../../materials/GlowMaterial';
import { ParticleEmitter } from './ParticleEmitter';
import { Linear, TweenMax } from 'gsap/TweenMax';
import { degreesToRadians, spherePosition, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';

export class Gift extends THREE.Group {
	private _gift;
	private _glow;
	private _rotation;

	private _glowColor: THREE.Color;

	public isHit: boolean = false;

	private _particleEmitter: ParticleEmitter;

	constructor(rotation: number, material, glowMaterial, glowColor: THREE.Color) {
		super();

		this._glowColor = glowColor;
		this.name = 'gift';

		this._rotation = rotation;

		// let color = levelColor || 0;
		//
		// let material = new THREE.MeshLambertMaterial({
		// 	map: Globals.getNamedTexture('Gift_' + color),
		// 	color: 0xffffff,
		// 	emissive: 0x000000
		// });

		let obj = Globals.getNamedObject('gift001');
		let giftGeometry = obj.children[0].geometry;
		giftGeometry.center();

		this._gift = new THREE.Mesh(giftGeometry);

		this._gift.position.set(0, 0, 0);
		this._gift.rotation.z = Math.PI / 2;
		this._gift.rotation.y = -(Math.PI / 2);
		this._gift.position.set(0, 0, 0);
		this._gift.castShadow = false;
		this._gift.material = material;
		this._gift.material.needsUpdate = true;
		this._gift.scale.set(0.4, 0.4, 0.4);

		this.add(this._gift);

		if (Globals.isMobile) {
			glowMaterial.uniforms['power'].value = 0.2; //0.6;
		}

		// let glowMaterial = new GlowMaterial(glowColor);

		// if (!Globals.isMobile) {
		// 	glowMaterial.o = 0.3;

		this._glow = new THREE.Mesh(giftGeometry.clone(), glowMaterial);
		this._glow.position.copy(this._gift.position);
		this._glow.rotation.z = Math.PI / 2;
		this._glow.rotation.y = -(Math.PI / 2);
		this._glow.scale.multiplyScalar(0.5);
		this.add(this._glow);
		// }
	}

	public hit = (planetGroup: THREE.Object3D, particleRotation: number = 0) => {
		Globals.POST_PROCESSING.increaseLightAmplification();

		this._particleEmitter = new ParticleEmitter(this._glowColor, 25, 0.001, degreesToRadians(particleRotation));
		planetGroup.add(this._particleEmitter.element);

		this._particleEmitter.element.position.copy(this.position);
		this._particleEmitter.element.lookAt(planetGroup.position);

		this._particleEmitter.explode(this.kill);
	};

	set glowColor(color: THREE.Color) {
		this._glow.material.glowColor = color;
	}

	get glowUniforms() {
		return this._glow.material.uniforms;
	}

	get positionRotation() {
		return this._rotation;
	}

	public kill = () => {
		if (this._particleEmitter && this._particleEmitter.element.parent) {
			this._particleEmitter.element.parent.remove(this._particleEmitter.element);
			TweenMax.killTweensOf(this._particleEmitter.element.rotation);
			this._particleEmitter = null;
		}
	};
}
