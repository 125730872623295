import * as THREE from 'three';
import { PostProcessing } from '../utils/PostProcessing';
import { Globals } from '../../utils/Globals';
import { Linear, Power1, Sine, TweenMax } from 'gsap/TweenMax';
import { MainScene } from '../../utils/MainScene';
import { WorldParticles } from '../objects/WorldParticles';
import { degreesToRadians } from '../../../lib/com/hellomonday/utils/MathUtils';

const FADE_DURATION: number = 0.3;

export class CameraController {
	private _cameras = {
		intro: null,
		main: null
	};

	private _data;
	private _postProcessing: PostProcessing;

	private _introLookTarget = new THREE.Vector3(0, 7, 500);
	private _mainLookTarget = new THREE.Vector3(0, 0, 0);

	private _sleigh;
	private _reindeers;

	private _mainScene: MainScene;

	private _particles: WorldParticles = new WorldParticles(1000, 3);

	private _introScene1DataTween;
	private _introScene1DataTweenTwo;

	private _introScene2DataTween;
	private _introScene2DataTweenTwo;

	constructor(data) {
		this._data = data;

		this._cameras.intro = new THREE.PerspectiveCamera(data.fov, window.innerWidth / window.innerHeight, 1, 10000);
		this._cameras.main = new THREE.PerspectiveCamera(data.fov, window.innerWidth / window.innerHeight, 1, 10000);

		this._cameras.intro.name = 'INTRO';
		this._cameras.main.name = 'MAIN';

		if (Globals.DEBUG) {
			this.addGui();
		}
	}

	public init = (postProcessing: PostProcessing, sleigh, reindeers, mainScene: MainScene) => {
		this._postProcessing = postProcessing;
		this._sleigh = sleigh;
		this._reindeers = reindeers;
		this._mainScene = mainScene;

		// this.gotoIntroScene1();
	};

	public gotoIntroScene1 = (callback: () => void) => {
		// let introMusic = Globals.AUDIO_MANAGER.getClip('intro_bg_music');
		// TweenMax.to(introMusic.gain, 1, { value: 0.3 });
		TweenMax.killTweensOf(this._cameras.intro.position);
		this._postProcessing.setCamera(this._cameras.intro);

		// TweenMax.to(this._postProcessing, 0.8, { delay: 2, bloomStrength: 0.8, bloomExposure: 0.7 });

		// TODO: add particle field to this step
		this._cameras.intro.position.x = 0;
		this._cameras.intro.position.y = 0.85;
		this._cameras.intro.position.z = 474;

		this._introLookTarget.x = 0;
		this._introLookTarget.y = -0.52;
		this._introLookTarget.z = 469;

		this._cameras.intro.lookAt(this._introLookTarget);

		this._data.deersX = 0;
		this._data.deersY = -0.4;
		this._data.deersZ = 474;
		this._data.timescale = 0.7;

		TweenMax.to(this._postProcessing, FADE_DURATION, { delay: 0.1, bloomStrength: 0.8, bloomExposure: 0.7 });
		TweenMax.to(this._reindeers, FADE_DURATION, { delay: 0.1, alpha: 1 });
		TweenMax.to(this._sleigh, FADE_DURATION, { delay: 0.1, alpha: 1 });

		this._introScene1DataTween = TweenMax.to(this._cameras.intro.position, 3, { delay: FADE_DURATION + 0.1, y: 0, ease: Linear.easeNone, onUpdate: this.updateCameras });
		this._introScene1DataTweenTwo = TweenMax.to(this._data, 3, { delay: FADE_DURATION + 0.1, deersY: 0, deersZ: 469, ease: Linear.easeNone });

		let total = 3 + FADE_DURATION + 0.1;

		TweenMax.to(this._postProcessing, FADE_DURATION, { delay: total - FADE_DURATION, bloomStrength: 0.7, bloomExposure: 0, onComplete: callback });
		TweenMax.to(this._reindeers, FADE_DURATION, { delay: total - FADE_DURATION, alpha: 0 });
		TweenMax.to(this._sleigh, FADE_DURATION, { delay: total - FADE_DURATION, alpha: 0 });
	};

	public gotoIntroScene2 = (callback: () => void) => {
		TweenMax.killTweensOf(this._cameras.intro.position);
		this._mainScene.showMainPlanet();

		this._data.cameraX = -50;
		this._data.cameraY = 10;
		this._data.cameraZ = 200;

		this._data.cameraLookX = 0;
		this._data.cameraLookY = -0.52;
		this._data.cameraLookZ = 0;
		this._data.timescale = 0.7;

		this._postProcessing.setCamera(this._cameras.main);

		this.updateCameras();

		TweenMax.to(this._postProcessing, FADE_DURATION, { delay: 0.1, bloomStrength: 1.45, bloomExposure: 0.77, bloomThreshold: 0.11, bloomRadius: 1 });
		TweenMax.to(this._reindeers, FADE_DURATION, { delay: 0.1, alpha: 1 });
		TweenMax.to(this._sleigh, FADE_DURATION, { delay: 0.1, alpha: 1 });

		// Globals.LIGHTS.sunIntensity = 1.1;
		Globals.LIGHTS.sunY = 1000;
		TweenMax.to(Globals.LIGHTS, FADE_DURATION, { delay: 0.1, sunIntensity: 0.2 });

		this._introScene2DataTween = TweenMax.to(this._data, 40, { delay: 20, cameraX: 50, ease: Linear.easeNone, yoyo: true, repeat: -1 });
		this._introScene2DataTweenTwo = TweenMax.to(this._data, 40, { cameraZ: -120, ease: Linear.easeNone, onUpdate: this.updateCameras, yoyo: true, repeat: -1 });

		// TweenMax.to(this._postProcessing, FADE_DURATION, { delay: 5 - FADE_DURATION, bloomStrength: 0.7, bloomExposure: 0, onComplete: callback });
		// TweenMax.to(this._reindeers, FADE_DURATION, { delay: 5 - FADE_DURATION, alpha: 0 });
		// TweenMax.to(this._sleigh, FADE_DURATION, { delay: 5 - FADE_DURATION, alpha: 0 });
	};

	public gotoIntroScene3 = (callback: () => void) => {
		this._postProcessing.setCamera(this._cameras.intro);

		// TODO: add particle field to this step
		this._particles.element.position.z = 502;
		this._particles.alpha = 0;
		this._mainScene.mainGroup.add(this._particles.element);

		TweenMax.to(this._particles.element.position, 20, { z: 506 });
		TweenMax.to(this._particles.element.rotation, 20, { x: degreesToRadians(2) });

		TweenMax.to(this._particles, FADE_DURATION, { delay: 0.1, alpha: 1 });

		this._data.deersY = -0.24;
		this._data.deersZ = 502;
		this._data.timescale = 0.7;

		this._cameras.intro.position.x = -1.6;
		this._cameras.intro.position.y = 0.2;
		this._cameras.intro.position.z = 500;

		this._introLookTarget.x = 0;
		this._introLookTarget.y = 0;
		this._introLookTarget.z = 500;

		TweenMax.to(this._data, 3, { delay: FADE_DURATION, deersZ: 498, ease: Sine.easeOut, onUpdate: this.updateCameras });
		// TweenMax.to(this._introLookTarget, 3, {delay: 1.5, z: 498, onUpdate: this.updateCameras });

		this.updateCameras();

		TweenMax.to(this._postProcessing, FADE_DURATION, { delay: 0.1, bloomStrength: 0.8, bloomExposure: 0.7 });
		TweenMax.to(this._reindeers, FADE_DURATION, { delay: 0.1, alpha: 1 });
		TweenMax.to(this._sleigh, FADE_DURATION, { delay: 0.1, alpha: 1 });

		TweenMax.to(this._postProcessing, FADE_DURATION, { delay: 3, bloomStrength: 0.7, bloomExposure: 0, onComplete: callback });
		TweenMax.to(this._reindeers, FADE_DURATION, { delay: 3, alpha: 0 });
		TweenMax.to(this._sleigh, FADE_DURATION, { delay: 3, alpha: 0 });

		TweenMax.to(this._particles, FADE_DURATION, { delay: 2.8, alpha: 0 });
	};

	public startIntroScene = () => {
		this._postProcessing.setCamera(this._cameras.intro);

		this._cameras.intro.position.x = -100;
		this._cameras.intro.position.y = -17;
		this._cameras.intro.position.z = 60;

		this._introLookTarget.x = 0;
		this._introLookTarget.y = 0;
		this._introLookTarget.z = -300;

		this.updateCameras();

		TweenMax.to(this._cameras.intro.position, 40, { x: 100, y: 17, ease: Linear.easeNone, onUpdate: this.updateCameras });

		// Fade in
		TweenMax.to(this._postProcessing, FADE_DURATION, { bloomStrength: 0.55, bloomExposure: 0.65 });
		// TweenMax.to(this._reindeers, FADE_DURATION, { delay: 0.1, alpha: 1 });
		// TweenMax.to(this._sleigh, FADE_DURATION, { delay: 0.1, alpha: 1 });

		// Fade out
		// TweenMax.to(this._postProcessing, FADE_DURATION, { delay: 3, bloomStrength: 0.7, bloomExposure: 0, onComplete: callback });
		// TweenMax.to(this._reindeers, FADE_DURATION, { delay: 3, alpha: 0 });
		// TweenMax.to(this._sleigh, FADE_DURATION, { delay: 3, alpha: 0 });
		//
		// TweenMax.to(this._particles, FADE_DURATION, { delay: 2.8, alpha: 0 });
	};

	public fadeDown = (callback?: () => void) => {
		if (this._introScene2DataTween) {
			this._introScene2DataTween.kill();
			this._introScene2DataTweenTwo.kill();
		}

		if (this._introScene1DataTween) {
			this._introScene1DataTween.kill();
			this._introScene1DataTweenTwo.kill();
		}

		TweenMax.killTweensOf(this._postProcessing);
		TweenMax.killTweensOf(this._reindeers);
		TweenMax.killTweensOf(this._sleigh);

		TweenMax.to(this._postProcessing, FADE_DURATION, { bloomStrength: 0.7, bloomExposure: 0, onComplete: callback });
		TweenMax.to(this._reindeers, FADE_DURATION, { alpha: 0 });
		TweenMax.to(this._sleigh, FADE_DURATION, { alpha: 0 });
	};

	public introComplete = () => {
		let introMusic = Globals.AUDIO_MANAGER.getClip('intro_bg_music');
		TweenMax.to(introMusic.gain, 1, { value: 0.05 });
		this._mainScene.showMainPlanet();

		// this._particles.alpha = 0;
		// this._mainScene.mainGroup.remove(this._particles.element);
		// TweenMax.killTweensOf(this._particles.element.position);
		// TweenMax.killTweensOf(this._particles.element.rotation);
		//
		// this._particles = null;

		// TweenMax.killTweensOf(this._data);
		TweenMax.killTweensOf(this._cameras.intro.position);

		this._data.cameraX = 0;
		this._data.cameraY = 7;
		this._data.cameraZ = 402;

		// this._data.cameraLookX = 0;
		// this._data.cameraLookY = -0.52;
		this._data.cameraLookZ = -100;

		this._data.deersY = 6.55; //-0.52;
		this._data.deersZ = 401.07; //398;//-1; //-0.9;

		this._postProcessing.setCamera(this._cameras.main);
		this.updateCameras();

		//Globals.LIGHTS.sunIntensity = 1;
		Globals.LIGHTS.sunY = -1000;
		TweenMax.to(Globals.LIGHTS, FADE_DURATION, { delay: 0.1, sunIntensity: 2 });

		Globals.TRAINING_LEVEL.addParticles();

		TweenMax.to(this._data, 2, {
			delay: FADE_DURATION - 0.1,
			deersZ: 393.07,
			cameraZ: 394,
			ease: Sine.easeOut,
			onUpdate: this.updateCameras,
			onComplete: this._mainScene.toggleIntroActive as () => void,
			onCompleteParams: [false]
		});

		// TweenMax.to(this._postProcessing, 2, { delay: FADE_DURATION - 0.1, vignetteDarkness: 1 });

		// TweenMax.to(this._data, 0, {
		// 	delay: 2 + FADE_DURATION - 0.1,
		// 	deersY: -0.52,
		// 	deersZ: -1,
		// 	ease: Sine.easeOut,
		// 	onUpdate: this.updateCameras
		// });

		// this._mainScene.toggleIntroActive(false);

		TweenMax.to(this._postProcessing, FADE_DURATION, { delay: 0.1, bloomStrength: 0.8, bloomExposure: 0.7, bloomRadius: 0, bloomThreshold: 0 });
		TweenMax.to(this._reindeers, FADE_DURATION, { delay: 0.1, alpha: 1 });
		TweenMax.to(this._sleigh, FADE_DURATION, { delay: 0.1, alpha: 1 });

		// this._mainScene.animateIn();
	};

	private addGui = () => {
		let camera = Globals.GUI.addFolder('Intro Camera');
		camera
			.add(this._cameras.intro.position, 'x', -100, 100)
			.name('X')
			.step(0.01)
			.onChange(this.updateCameras)
			.listen();
		camera
			.add(this._cameras.intro.position, 'y', -100, 100)
			.name('Y')
			.step(0.01)
			.onChange(this.updateCameras)
			.listen();
		camera
			.add(this._cameras.intro.position, 'z', -200, 500)
			.name('Z')
			.step(0.01)
			.onChange(this.updateCameras)
			.listen();

		camera
			.add(this._introLookTarget, 'x', -100, 100)
			.name('Look X')
			.step(0.01)
			.onChange(this.updateCameras)
			.listen();
		camera
			.add(this._introLookTarget, 'y', -100, 100)
			.name('Look Y')
			.step(0.01)
			.onChange(this.updateCameras)
			.listen();
		camera
			.add(this._introLookTarget, 'z', -600, 600)
			.name('Look Z')
			.step(0.01)
			.onChange(this.updateCameras)
			.listen();

		// camera.open();
	};

	private updateCameras = () => {
		this._cameras.intro.lookAt(this._introLookTarget);
		// this._cameras.main.lookAt(this._mainLookTarget);
	};

	public updateFov = () => {
		this._cameras.intro.fov = this._data.fov;
		this._cameras.intro.updateProjectionMatrix();

		this._cameras.main.fov = this._data.fov;
		this._cameras.main.updateProjectionMatrix();
	};

	public resize = () => {
		this._cameras.intro.aspect = window.innerWidth / window.innerHeight;
		this._cameras.intro.updateProjectionMatrix();

		this._cameras.main.aspect = window.innerWidth / window.innerHeight;
		this._cameras.main.updateProjectionMatrix();
	};

	get main() {
		return this._cameras.main;
	}

	get mainLookTarget() {
		return this._mainLookTarget;
	}

	get intro() {
		return this._cameras.intro;
	}
}
