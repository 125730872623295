import { Power2, TweenMax } from 'gsap/TweenMax';
import { Globals } from './Globals';

export class About {
	private _element;
	private _isShown: boolean = false;

	constructor(element) {
		this._element = element;

		this.init();
	}

	private init() {}

	public animateIn = () => {
		if (this._isShown === false) {
			Globals.bodyPixController.toggleInputs(false);

			Globals.pauseControls = true;
			Globals.topContent.pauseTimer();
			this._isShown = true;
			this._element.style.display = 'block';
			Globals.mainScene.toggleControls(false);
			TweenMax.to(this._element, 0.3, { y: 0, opacity: 1, ease: Power2.easeOut });
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			Globals.bodyPixController.toggleInputs(true);

			this._isShown = false;
			TweenMax.to(this._element, 0.3, { y: 0, opacity: 0, ease: Power2.easeOut, onComplete: this.hide });
		}
	};

	public changeBGColor = newColor => {
		TweenMax.to(this._element, 0.2, { backgroundColor: newColor });
	};

	public toggle = () => {
		if (this._isShown === true) {
			this.animateOut();
		} else {
			this.animateIn();
		}
		return this._isShown;
	};

	private hide = () => {
		Globals.pauseControls = false;
		if (Globals.levelsCompletedOpen !== true) {
			Globals.topContent.startTimer();
		}
		this._element.style.display = 'none';
	};
}
