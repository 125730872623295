import { Power2, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';

export class LevelsCompleted {
	private _element;
	private _playAgain;
	private _time;
	private _isShown: boolean = false;

	constructor(element) {
		this._element = element;
		this._playAgain = element.querySelector('.button');
		this._time = element.querySelector('.yourTimeDisplay');

		this.init();
	}

	private init() {
		this._playAgain.addEventListener('click', this.animateOut);
	}

	public animateIn = () => {
		if (this._isShown === false) {
			Globals.levelsCompletedOpen = true;
			this._isShown = true;
			this._element.style.display = 'block';

			// let winnerText = 'And you did it in ' + Globals.topContent.getTime();
			this._time.innerHTML = Globals.topContent.getTime();

			Globals.topContent.pauseTimer();
			window['ga']('send', 'event', 'Game Completed', Globals.topContent.getTime());

			TweenMax.to(this._element, 0.3, { y: 0, opacity: 1, ease: Power2.easeOut });
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			Globals.levelsCompletedOpen = true;
			Globals.mainScene.restartCurrentLevel(true);

			this._isShown = false;
			TweenMax.to(this._element, 0.3, { y: 0, opacity: 0, ease: Power2.easeOut, onComplete: this.hide });
		}
	};

	public toggle = () => {
		if (this._isShown === true) {
			this.animateOut();
		} else {
			this.animateIn();
		}
		return this._isShown;
	};

	private hide = () => {
		this._element.style.display = 'none';
	};
}
