import * as THREE from 'three';

// @ts-ignore
import * as fragment from '../shaders/particle/ParticleShader.fs';
// @ts-ignore
import * as vertex from '../shaders/particle/ParticleShader.vs';

export class ParticleMaterial extends THREE.ShaderMaterial {
	constructor(pointTexture, depthTest: boolean = true, blending = THREE.AdditiveBlending) {
		super({
			uniforms: {
				pointTexture: { value: pointTexture },
				opacity: { value: 1 }
			},
			vertexShader: vertex,
			fragmentShader: fragment,
			blending: blending,
			depthTest: depthTest,
			transparent: true,
			// @ts-ignore
			vertexColors: true
		});
	}

	get alpha() {
		return this.uniforms['opacity'].value;
	}

	set alpha(value: number) {
		this.uniforms['opacity'].value = value;
	}
}
