import {Power1, TweenMax} from "gsap/TweenMax";
import SplitText from '../../lib/com/greensock/gsap-bonus/SplitText.js';


export class InstructionText {

	private _element;
	private _isShown: boolean = false;
	private _textContent;

	private _nextText = '';

	constructor(element) {
		this._element = element;
		this.init();
	}

	private init() {
		this._textContent = this._element.querySelector('.textContent');
		//TweenMax.set(this._element, {opacity: 0});
	}

	public changeText = (newText) => {
		//this._textContent = newText;

		this._nextText = newText;

		if (this._isShown === false) {
			this.animateIn();
		}
		else {
			this.animateOut();
		}
	};

	public animateIn = () => {
		if (this._isShown === false) {
			this._textContent.innerHTML = this._nextText;
			this._nextText = '';
			this._element.style.display = 'block';
			this._isShown = true;
			TweenMax.killTweensOf(this._element);

			// animate text in
			var mySplitTextOne = new SplitText(this._textContent, {type:"words,chars"});
			TweenMax.set(this._textContent, {perspective: 400});
			TweenMax.staggerFrom(mySplitTextOne.chars,  0.8, {opacity:0, scale:0, y:80, rotationX:180, transformOrigin:"0% 50% -50",  ease:"back", stagger: 0.01}, 0.01);

			TweenMax.to(this._element, 0.4, {y: 0, opacity: 1, ease: Power1.easeOut});
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			this._isShown = false;
			TweenMax.killTweensOf(this._element);

			// animate text out
			var mySplitTextOne = new SplitText(this._textContent, {type:"words,chars"});
			TweenMax.staggerTo(mySplitTextOne.chars,  0.4, {opacity:0, scale:0, y:80, rotationX:180, transformOrigin:"0% 50% -50",  ease:Power1.easeIn, stagger: 0.01}, Math.random() * 0.1);

			TweenMax.to(this._element, 0.4, {y: 0, opacity: 0, ease: Power1.easeIn, onComplete: this.hide});
		}
	};

	public hide = () => {

		if (this._nextText === '')
		{
			this._element.style.display = 'none';
		}
		else {
			this.animateIn()
		}
	}
}
