import { TopContent } from './TopContent';
import { LevelScreen } from './LevelScreen';
import * as THREE from 'three';
import { InstructionText } from './InstructionText';
import { Footer } from './Footer';
import { About } from './About';
import { LevelsCompleted } from './LevelsCompleted';
import { WebcamNotAllowed } from './WebcamNotAllowed';
import { BodyPixController } from './BodyPixController';
import { AudioManager } from '../game/audio/AudioManager';
import { GameHUD } from './GameHUD';
import { TryAgainScreen } from './TryAgainScreen';
import { PostProcessing } from '../game/utils/PostProcessing';
import { Reindeers } from '../game/objects/Reindeers';
import { TrainingLevel } from '../game/levels/TrainingLevel';
import { AllowWebcamScreen } from './AllowWebcamScreen';
import { Lights } from '../game/world/Lights';

declare class DocumentTouch {}

(window as any).THREE = THREE;

export class Globals {
	public static DEBUG: boolean = true;

	public static SKIP_TRAINING: boolean = false;

	// World
	public static worldRotationSpeed = 0; //0.004;
	public static SPHERE_RADIUS: number = 6.5;
	public static LEVEL_COLORS = [new THREE.Color('#0d18a0'), new THREE.Color('#e90505'), new THREE.Color('#3af96b')];
	public static POST_PROCESSING: PostProcessing;
	public static LIGHTS: Lights;

	// Audio
	public static AUDIO_MANAGER: AudioManager;

	// GUI
	public static GUI;

	public static SITE_WRAPPER: HTMLDivElement = document.getElementById('App') as HTMLDivElement;
	public static IS_TOUCH_DEVICE: boolean = 'ontouchstart' in window || ((window as any).DocumentTouch && document instanceof DocumentTouch); // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static IS_IE: boolean = false;
	public static IS_WINDOWS: boolean = navigator.platform.indexOf('Win') > -1;

	public static debugUseMouse: boolean = false;
	public static allowKeyboardCheat: boolean = false;
	public static speedUpIntroWithFactor = 1; //1;// 0.01;

	public static debugSkipIntro: boolean = false;

	public static speedUpTextIntroWithFactor: number = 1; // 1;//0.01;
	public static speedUpFloatingIslandWith: number = 1;

	public static skipMoveLeftAndRight: boolean = false;
	public static skipSkeletonInIntro: boolean = false;

	public static keyboardOnly: boolean = false;

	public static measureWebGLAnimateStarts: boolean = true;

	public static noWebcam: boolean = false;

	public static main;
	public static stats: any;

	public static mainScene;

	public static bgMusic;

	public static useKeyboard: boolean = false;

	public static levelsCompletedOpen: boolean = false;

	public static startWebCamFunction;
	public static topContent: TopContent;
	public static levelScreen: LevelScreen;
	public static instructionText: InstructionText;

	public static footer: Footer;
	public static levelsCompleted: LevelsCompleted;
	public static about: About;
	public static webcamNotAllowed: WebcamNotAllowed;
	public static tryAgainScreen: TryAgainScreen;
	public static allowWebcamScreen: AllowWebcamScreen;

	public static TRAINING_LEVEL: TrainingLevel;

	public static pauseControls: boolean = false;
	public static ignoreControls: boolean = false;

	public static wheelRotation = 0;
	public static wheelRotationTweened = { number: 0 };
	public static numberOfHands = 0;

	public static bodyPixController: BodyPixController;

	public static gameHUD: GameHUD;

	public static isMobile: boolean = false;

	public static allowSteering: boolean = false;

	public static trainingLevelDone: boolean = false;

	public static loadTexturesArray: Array<any> = [
		{ path: '/assets/images/particles/whitelight.png', name: 'particle1', texture: '' },
		{ path: '/assets/images/particles/particle4.png', name: 'particle2', texture: '' },

		{ path: '/assets/images/bg_viginette_00.jpg', name: 'bg_viginette_00', texture: '' },
		{ path: '/assets/images/bg_viginette_01.jpg', name: 'bg_viginette_01', texture: '' },
		{ path: '/assets/images/bg_viginette_02.jpg', name: 'bg_viginette_02', texture: '' },

		{ path: '/assets/images/hyperspeed_tunnel_01.jpg', name: 'hyperspeed_tunnel', texture: '' },
		{ path: '/assets/images/hyperspeed_tunnel3.jpg', name: 'hyperspeed_tunnel2', texture: '' },
		{ path: '/assets/images/hyperspeed_tunnel4.jpg', name: 'hyperspeed_tunnel3', texture: '' },

		{ path: '/assets/3d/Gifts/SantaHelpers.Gift.001-blue.png', name: 'Gift_0', texture: '' },
		{ path: '/assets/3d/Gifts/SantaHelpers.Gift.001-yellow.png', name: 'Gift_1', texture: '' },
		{ path: '/assets/3d/Gifts/SantaHelpers.Gift.001-green.png', name: 'Gift_2', texture: '' },
		{ path: '/assets/3d/Gifts/SantaHelpers.Gift.001-red.png', name: 'Gift_4', texture: '' }
	];

	public static getNamedTexture(findName: string) {
		for (let i = 0; i < this.loadTexturesArray.length; i++) {
			let currentObject = this.loadTexturesArray[i];
			let getName = currentObject.name;
			if (getName === findName) {
				return currentObject.texture;
			}
		}
		console.warn('Error: Not texture with the name ' + findName + ' found');
	}

	public static loadObjectsArray: Array<any> = [
		// { path: '/assets/3d/xmas/ChristmasAssets.obj', mtl: '/assets/3d/xmas/ChristmasAssets.mtl', name: 'ChristmasAssets', object: '' },
		// { path: '/assets/3d/earth.obj', mtl: '/assets/3d/earth.mtl', name: 'earth', object: '' },
		{ path: '/assets/3d/Planets/Planet_1.obj', name: 'Planet_1', object: '' },
		{ path: '/assets/3d/Planets/Planet_3.obj', name: 'Planet_3', object: '' },
		{ path: '/assets/3d/Planets/Planet_8.obj', name: 'Planet_8', object: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_1.obj', name: 'Asteroid_1', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_2.obj', name: 'Asteroid_2', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_3.obj', name: 'Asteroid_3', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_4.obj', name: 'Asteroid_4', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_5.obj', name: 'Asteroid_5', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_5_fractured.obj', name: 'Asteroid_5_fractured', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_6.obj', name: 'Asteroid_6', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_7.obj', name: 'Asteroid_7', texture: '' },
		{ path: '/assets/3d/Asteroids/Asteroid_8.obj', name: 'Asteroid_8', texture: '' },

		{ path: '/assets/3d/Gifts/gift001.obj', name: 'gift001', texture: '' },

		{ path: '/assets/3d/xmas/sleigh.obj', name: 'sleigh', texture: '' }
	];

	public static getNamedObject(findName: string) {
		for (var i = 0; i < this.loadObjectsArray.length; i++) {
			var currentObject = this.loadObjectsArray[i];
			var getName = currentObject.name;
			if (getName === findName) {
				return currentObject.object;
			}
		}
		console.warn('Error: Not object with the name ' + findName + ' found');
	}

	public static gameVariables = {
		gameMapWidth: 2.5,
		gameMapHeight: 3.95,
		brickWidth: 2.5 / 11,
		brickHeight: 2.5 / 20,
		brickMargin: 0.03,
		currentLevel: 0,
		paddleMoveDistance: 6.3
	};

	public static average(data) {
		var sum = data.reduce(function(sum, value) {
			return sum + value;
		}, 0);
		var avg = sum / data.length;
		return avg;
	}

	public static Normalize(array, value) {
		for (var i = 0, len = array.length; i < len; i++) {
			if (parseInt(array[i]) > value) array[i] = value;
		}
	}
}
