import * as THREE from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { randomFloat, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';

export default class AnimatedMeshLine extends THREE.Mesh {
	private _speed: number;
	private _voidLength;
	private _dashLength;
	private _dyingAt: number = 1;
	private _diedAt: number;

	private _preventDying: boolean = false;

	private _debug: boolean = false;

	constructor(props) {
		super();

		this._debug = props.debug;

		if (props.preventDying) {
			this._preventDying = props.preventDying;
		}

		let width = props.width || 0.1;
		let opacity = 0.3; //0.3;
		let position = new THREE.Vector3(0, 0, 0);
		let turbulence = props.turbulence || new THREE.Vector3(Math.random(), Math.random(), Math.random());

		let points = props.points;

		// Params to create the array of points
		let length = 10;
		let nbrOfPoints = 30;
		let orientation = new THREE.Vector3(Math.random(), Math.random(), Math.random());
		let transformLineMethod = false;

		// Create the main line
		let linePoints: Array<any> = [];

		if (!points) {
			let currentPoint = new THREE.Vector3();
			let segment;
			// The size of each segment oriented in the good directon
			linePoints.push(currentPoint.clone());
			for (let i = 0; i < nbrOfPoints - 1; i++) {
				segment = orientation.normalize().multiplyScalar(length / nbrOfPoints);

				// Increment the point depending to the orientation
				currentPoint.add(segment);
				// Add turbulence to the current point
				linePoints.push(
					currentPoint
						.clone()
						.set(
							currentPoint.x + randomFloat(-turbulence.x, turbulence.x),
							currentPoint.y + randomFloat(-turbulence.y, turbulence.y),
							currentPoint.z + randomFloat(-turbulence.z, turbulence.z)
						)
				);
			}
			// Finish the curve to the correct point without turbulence
			linePoints.push(currentPoint.add(segment).clone());
			// Smooth the line
			let curve = new THREE.SplineCurve(linePoints);
			//@ts-ignore
			linePoints = new THREE.Geometry().setFromPoints(curve.getPoints(50)) as Array;
		} else {
			let l = points.length;

			for (let i = 0; i < l; i += 3) {
				points[i] += randomFloat(-turbulence.x, turbulence.x);
				points[i + 1] += randomFloat(-turbulence.y, turbulence.y);
				points[i + 2] += randomFloat(-turbulence.z, turbulence.z);
			}

			linePoints = points;
		}

		// Create the MeshLineGeometry
		let line = new MeshLine();
		line.setGeometry(linePoints, transformLineMethod);
		let geometry = line.geometry;

		let dashArray = props.dashArray || 2;
		let dashOffset = props.dashOffset || 0;
		let dashRatio = props.dashRatio || 1 - props.visibleLength * 0.5; // between 0.5 and 1.

		this.geometry = geometry;
		this.material = new MeshLineMaterial({
			lineWidth: width,
			dashArray,
			dashOffset,
			dashRatio, // The ratio between that is visible or not for each dash
			opacity,
			// orientation: orientation,
			// points: props.points || false,
			transparent: true,
			depthWrite: true,
			color: props.color,
			fog: props.fog || false
		});

		this.position.copy(position);

		this._speed = props.speed;
		this._voidLength = dashArray * dashRatio; // When the visible part is out
		this._dashLength = dashArray - this._voidLength;

		this._dyingAt = 1;
		this._diedAt = this._dyingAt + this._dashLength;
	}

	public setColor = (color: THREE.Color) => {
		//@ts-ignore
		this.material.color = color;
	};

	public update = () => {
		// Increment the dash
		//@ts-ignore
		this.material.uniforms.dashOffset.value -= this._speed;

		if (this._debug) {
			//@ts-ignore
			console.log(this.material.uniforms.dashOffset.value);
		}

		// TODO make that into a decorator
		// Reduce the opacity when the dash start to disappear
		if (!this._preventDying && this.isDying) {
			// @ts-ignore
			this.material.uniforms.opacity.value = 0.9 + (this.material.uniforms.dashOffset.value + 1) / this.dashLength;
		}
	};

	get dashOffset() {
		//@ts-ignore
		return this.material.uniforms.dashOffset.value;
	}

	set dashOffset(value: number) {
		//@ts-ignore
		this.material.uniforms.dashOffset.value = value;
	}

	get dashRatio() {
		//@ts-ignore
		return this.material.uniforms.dashRatio.value;
	}

	set dashRatio(value: number) {
		//@ts-ignore
		this.material.uniforms.dashRatio.value = value;
	}

	get dashArray() {
		//@ts-ignore
		return this.material.uniforms.dashArray.value;
	}

	set dashArray(value: number) {
		//@ts-ignore
		this.material.uniforms.dashArray.value = value;
	}

	get died() {
		if (this._preventDying) {
			return false;
		} else {
			// @ts-ignore
			return this.material.uniforms.dashOffset.value < -this._diedAt;
		}
	}

	get isDying() {
		if (this._preventDying) {
			return false;
		} else {
			// @ts-ignore
			return this.material.uniforms.dashOffset.value < -this._dyingAt;
		}
	}
}
