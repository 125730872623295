export class Helpers {
	//public static DEBUG: boolean = false;

	public static returnCenterPoints = useArray => {
		var bounds = { left: 99999, top: 99999, right: 0, bottom: 0 };
		var _length = useArray.length;

		for (var i = 0; i < _length; i++) {
			var getPixel = useArray[i];
			var getXPixel = getPixel[0];
			var getYPixel = getPixel[1];

			if (getXPixel < bounds.left) {
				bounds.left = getXPixel;
			}
			if (getXPixel > bounds.right) {
				bounds.right = getXPixel;
			}
			if (getYPixel < bounds.top) {
				bounds.top = getYPixel;
			}
			if (getYPixel > bounds.bottom) {
				bounds.bottom = getYPixel;
			}
		}

		var width = bounds.right - bounds.left;
		var height = bounds.bottom - bounds.top;

		var findCenterX = bounds.left + width / 2;
		var findCenterY = bounds.top + height / 2;

		return { x: findCenterX, y: findCenterY, width: width, height: height, bounds: bounds };
	};

	public static smooth = (arr, windowSize) => {
		const get = value => value;
		const result = [];
		// If the array is larger than 10 - we make it into 10 values
		var _arrLength = arr.length;
		if (_arrLength > 10) {
			arr = arr.slice(arr.length - 10, arr.length);
		}
		_arrLength = arr.length;

		for (let i = 0; i < _arrLength; i += 1) {
			const leftOffeset = i - windowSize;
			const from = leftOffeset >= 0 ? leftOffeset : 0;
			const to = i + windowSize + 1;

			let count = 0;
			let sum = 0;
			for (let j = from; j < to && j < _arrLength; j += 1) {
				sum += get(arr[j]);
				count += 1;
			}

			result[i] = sum / count;
		}
		return result;
	};

	public static calcDistance(p0, p1) {
		return Math.sqrt((p1.x - p0.x) * (p1.x - p0.x) + (p1.y - p0.y) * (p1.y - p0.y));
	}
}
