import * as THREE from 'three';
import { degreesToRadians, radiansToDegrees, randomFloat, randomInt, spherePosition, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';
import { Gift } from '../objects/Gift';
import { Globals } from '../../utils/Globals';
import { Back, Linear, Power1, TweenMax } from 'gsap/TweenMax';
import { Astroid } from '../objects/Astroid';
import { CameraController } from '../controllers/CameraController';
import { WorldParticles } from '../objects/WorldParticles';
import Global = WebAssembly.Global;
import { GlowMaterial } from '../../materials/GlowMaterial';

export class TrainingLevel {
	public group: THREE.Group = new THREE.Group();

	private _gift: Gift;
	private _asteroid: Astroid;
	private _checkForBothHands: number;
	private _checkPauseFactor: number;
	private _numberOfChecksTwoHandsFound: number = 0;
	public giftSpawned: boolean = false;

	public step: number = 0;

	public sphere: THREE.Mesh;

	private _allowMovement: boolean = false;

	private _sphereParticles: WorldParticles;
	private _particlesAdded: boolean = false;

	constructor(params, cameraController: CameraController) {
		this.group.position.z = 390;

		let geometry = new THREE.SphereBufferGeometry(Globals.SPHERE_RADIUS, 32, 32, 0);
		let material = new THREE.MeshBasicMaterial({ fog: false, color: 0x000033, side: THREE.FrontSide });
		this.sphere = new THREE.Mesh(geometry, material);

		this.group.add(this.sphere);
	}

	public addParticles = () => {
		this._sphereParticles = new WorldParticles(2000, Globals.SPHERE_RADIUS, 0.05, 'particle2', false);

		// this._sphereParticles.alpha = 1;
		this._sphereParticles.render();
		this._sphereParticles.speedMultiplier = 6;
		// this._sphereParticles.animateSize = false;
		this.sphere.add(this._sphereParticles.element);

		TweenMax.to(this._sphereParticles, 1, { alpha: 1 });

		this._particlesAdded = true;
	};

	public start = () => {
		//	TweenMax.delayedCall(1.6, this.addGift);
		// this.addGift();Globals.instructionText.changeText('Time to learn how to steer');
		TweenMax.delayedCall(0.5, Globals.instructionText.changeText as () => void, ['Learn to steer the deer.']);

		TweenMax.delayedCall(2, Globals.gameHUD.animateInVideo);

		if (Globals.useKeyboard === false) {
			TweenMax.delayedCall(4, this.holdUpBothHands);
		} else {
			Globals.mainScene.toggleControls(false);
			TweenMax.delayedCall(4, this.holdDownUpKey);
		}
	};

	private holdDownUpKey = () => {
		if (Globals.isMobile) {
			Globals.instructionText.changeText('Touch the screen to move.');
		} else {
			Globals.instructionText.changeText('Press the up arrow key to move.');
		}

		this._checkPauseFactor = setInterval(this.checkPauseFactor, 10);
	};

	private checkPauseFactor = () => {
		// FIXME - instead do a check for when the pause factor reaches 1 (full acceleration)
		if (Globals.mainScene.getPauseFactor() === 1) {
			if (Globals.isMobile) {
				Globals.instructionText.changeText('Great. Move your finger left and right to move from side to side.');
			} else {
				Globals.instructionText.changeText('Great. Continue holding the up arrow. Use the left and right arrows to move from side to side.');
			}

			TweenMax.delayedCall(3, this.addGift);
			clearInterval(this._checkPauseFactor);
		}
	};

	private holdUpBothHands = () => {
		Globals.instructionText.changeText('Hold up both hands in fists like you’re clutching the reins.');

		this._checkForBothHands = setInterval(this.checkForBothHands, 10);
	};

	private checkForBothHands = () => {
		if (Globals.numberOfHands === 2) {
			this._numberOfChecksTwoHandsFound++;
			if (this._numberOfChecksTwoHandsFound === 6) {
				clearInterval(this._checkForBothHands);
				Globals.instructionText.changeText('Remember to keep them within the frame of the web cam.');

				TweenMax.delayedCall(3, this.addGift);
			}
		} else {
			this._numberOfChecksTwoHandsFound = 0;
		}
	};

	public addGift = () => {
		let giftMaterial = new THREE.MeshLambertMaterial({
			map: Globals.getNamedTexture('Gift_0'),
			color: 0xffffff,
			emissive: 0x000000
		});

		let giftGlowMaterial = new GlowMaterial(new THREE.Color('#3ab5f9'));

		this._gift = new Gift(0, giftMaterial, giftGlowMaterial, new THREE.Color('#3ab5f9'));

		let radians = degreesToRadians(-10);

		let position = spherePosition(Globals.SPHERE_RADIUS - 0.4, radians, 'y');
		position.normalize();
		position.multiplyScalar(7);

		this._gift.position.x = -0.8;
		this._gift.position.y = position.y;
		this._gift.position.z = position.z;

		this._gift.lookAt(this.sphere.position);

		this._gift.scale.set(0.00001, 0.00001, 0.00001);
		this.sphere.add(this._gift);

		position = spherePosition(Globals.SPHERE_RADIUS, radians, 'y');
		position.normalize();
		position.multiplyScalar(7);

		TweenMax.to(this._gift.scale, 0.5, { delay: 1, x: 1, y: 1, z: 1, ease: Back.easeOut });
		TweenMax.to(this._gift.position, 0.5, { delay: 1, y: position.y, z: position.z, ease: Back.easeOut });

		this._gift.rotation.z = 0;
		// TweenMax.to(this._gift.rotation, 7, { z: -TAU, repeat: -1, ease: Linear.easeNone });

		TweenMax.delayedCall(1.6, this.animateGift);

		TweenMax.delayedCall(1.7, Globals.instructionText.changeText as () => void, ['Steer left to pick up the gift.']);
		//
		// window.addEventListener('click', this.testing);
		this._allowMovement = true;
	};

	private animateGift = () => {
		this.giftSpawned = true;

		let position = spherePosition(Globals.SPHERE_RADIUS, degreesToRadians(-10), 'y');
		position.normalize();
		position.multiplyScalar(6.9);

		TweenMax.to(this._gift.position, 2, { y: position.y, z: position.z, ease: Power1.easeInOut, yoyo: true, repeat: -1 });
	};

	private addAsteroid = () => {
		this._asteroid = new Astroid(0, 0);

		let radians = degreesToRadians(-50);

		let position = spherePosition(Globals.SPHERE_RADIUS - 0.7, radians, 'y');
		position.normalize();
		position.multiplyScalar(7);

		this._asteroid.position.x = -0.8;
		this._asteroid.position.y = position.y;
		this._asteroid.position.z = position.z;

		this._asteroid.scale.set(0.00001, 0.00001, 0.00001);

		TweenMax.to(this._asteroid.scale, 0.5, { x: 1, y: 1, z: 1, ease: Back.easeOut });

		position = spherePosition(Globals.SPHERE_RADIUS - 0.4, radians, 'y');
		position.normalize();
		position.multiplyScalar(7);

		TweenMax.to(this._asteroid.position, 0.5, { y: position.y, ease: Back.easeOut });

		TweenMax.to(this._asteroid.rotation, 15 + randomFloat(-2, 2), { y: TAU, z: TAU, repeat: -1, ease: Linear.easeNone });

		this.sphere.add(this._asteroid);

		TweenMax.delayedCall(0.5, Globals.instructionText.changeText as () => void, ['Steer right to avoid hitting the asteroid.']);
	};

	public explodeAsteroid = () => {
		this._asteroid.explode(null);
	};

	public checkCollision = collisionObject => {
		let hit = false;

		if (!this._gift) {
			return hit;
		}

		let gift = this._gift;
		let spaceshipWorldPosition = new THREE.Vector3();
		let giftWorldPosition = new THREE.Vector3();

		collisionObject.getWorldPosition(giftWorldPosition);
		gift.getWorldPosition(spaceshipWorldPosition);

		let checkDistanceToSpaceShip = spaceshipWorldPosition.distanceTo(giftWorldPosition);

		if (checkDistanceToSpaceShip <= 0.4) {
			//distanceToObject) {
			if (gift.isHit !== true) {
				this.step++;

				Globals.instructionText.animateOut();

				Globals.AUDIO_MANAGER.trigger('pickup', 0.2);
				gift.isHit = true;

				gift.hit(this.sphere);

				TweenMax.to(gift.scale, 0.4, { x: 0.1, y: 0.1, z: 0.1 });

				let position = spherePosition(Globals.SPHERE_RADIUS - 0.4, 0, 'y');
				position.normalize();
				position.multiplyScalar(7);

				TweenMax.to(gift.position, 0.2, { x: -0.8, y: position.y, z: position.z });
				// TweenMax.to(gift.position, 0.2, {delay: 0.2, y: 6});
				// TweenMax.to(gift.position, 0.4, {z: 398});

				TweenMax.delayedCall(1, this.addAsteroid);

				Globals.gameHUD.updateGiftCount(0, true);

				hit = true;
			}
		}

		return hit;
	};

	// private testing = () => {
	// 	this._gift.hit(this.sphere, 0);
	// };

	public cleanUp = () => {
		TweenMax.killTweensOf(this._asteroid.position);
		TweenMax.killTweensOf(this._asteroid.rotation);
		this._asteroid.killGlowTween();

		TweenMax.killTweensOf(this._gift.position);
		TweenMax.killTweensOf(this._gift.rotation);
		// this._gift.kill();

		if (this._particlesAdded) {
			this.group.remove(this._sphereParticles.element);
			this._sphereParticles = null;
		}

		this.group.parent.remove(this.group);
	};

	public update = (pauseFactor: number = 1) => {
		if (this._particlesAdded) {
			this._sphereParticles.render();
		}

		if (!this._allowMovement) {
			return;
		}

		let sphereRotationDegrees = radiansToDegrees(this.sphere.rotation.x);
		let speed = 0.001 * pauseFactor;

		if (this.step === 0 && sphereRotationDegrees < 15) {
			this.sphere.rotation.x += speed;
		} else if (this.step === 1 && sphereRotationDegrees < 53) {
			this.sphere.rotation.x += speed;
		}
	};
}
