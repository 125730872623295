import * as THREE from 'three';

export const TAU = Math.PI * 2;

export function randomInt(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomFloat(min, max) {
	return Math.random() * (max - min) + min;
}

export function getRandomIntsFromRange(count: number, range: number) {
	let i = 0;
	let stack = [];
	let randomImages: number[] = [];

	// Generate stack
	for (i; i < range; i++) {
		stack.push(i + 1);
	}

	// Add random from stack
	i = 0;
	let tempTotal = range - 1;
	let randomInt;
	for (i; i < count; i++) {
		randomInt = randomInt(0, tempTotal);
		randomImages.push(stack[randomInt]);

		stack.splice(randomInt, 1);

		tempTotal--;
	}

	return randomImages;
}

export function clamp(value: number, min: number, max: number) {
	return Math.min(max, Math.max(min, value));
}

export function degreesToRadians(degrees: number) {
	return (degrees * Math.PI) / 180;
}

export function radiansToDegrees(radians: number) {
	return (radians * 180) / Math.PI;
}

/** This method will calculate how big a circle is needed to make the square fit in it */
export function squareToCircle(width: number) {
	let radius: number;

	let a = width;
	let b = width;
	let c = Math.pow(a, 2) + Math.pow(b, 2);

	radius = Math.sqrt(c);

	return radius;
}

/** This method will calculate the largest square that can fit in the circle */
export function circleToSquare(radius: number) {
	let width: number;

	let c = radius;
	let ab = Math.pow(c, 2);
	let a = ab * 0.5;

	width = Math.sqrt(a);

	return width;
}

/**
 * Get spherical position
 *
 * @param radius - radius of the sphere
 * @param angle - position angle in radians
 * @param axis - can be either "x" or "y". Which axis to get spherical position from
 */
export function spherePosition(radius: number, angle: number, axis: string = 'y') {
	let position = new THREE.Vector3(0, 0, 0);

	if (axis === 'y') {
		position.y = radius * Math.cos(angle);
	} else if (axis === 'x') {
		position.x = radius * Math.cos(angle);
	}

	position.z = radius * Math.sin(angle);

	return position;
}

/**
 * returns the normalized angle (0 - TAU)
 * @param {number} radians
 * @return {number} normalized angle in radians
 */
export function normalizeAngle(radians) {
	return radians - TAU * Math.floor(radians / TAU);
}

/**
 * returns a target angle that is the shortest way to rotate an object between start and to--may choose a negative angle
 * @param {number} start
 * @param {number} to
 * @return {number} shortest target angle
 */
export function shortestAngle(start, to) {
	let difference = differenceAngles(to, start);
	let sign = differenceAnglesSign(to, start);
	let delta = difference * sign;
	return delta + start;
}

/**
 * returns +1 or -1 based on whether the difference between two angles is positive or negative (in radians)
 * @param {number} target angle
 * @param {number} source angle
 * @return {number} 1 or -1
 */
function differenceAnglesSign(target, source) {
	function mod(a, n) {
		return ((a % n) + n) % n;
	}

	let a = target - source;
	return mod(a + Math.PI, TAU) - Math.PI > 0 ? 1 : -1;
}

/**
 * returns the normalized difference between two angles (in radians)
 * @param {number} a - first angle
 * @param {number} b - second angle
 * @return {number} normalized difference between a and b
 */
export function differenceAngles(a, b) {
	var c = Math.abs(a - b) % TAU;
	return c > Math.PI ? TAU - c : c;
}
