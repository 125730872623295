import * as THREE from 'three';
import { Globals } from '../../utils/Globals';
import { degreesToRadians } from '../../../lib/com/hellomonday/utils/MathUtils';
import { GlowMaterial } from '../../materials/GlowMaterial';
import { TweenMax } from 'gsap/TweenMax';

export class Sleigh {
	public group: THREE.Group;

	private _params = {
		positionY: 1,
		positionZ: 0.5, //1.9,
		positionX: 0,
		rotation: 0,
		c: 0.6,
		p: 1.7,
		glowColor: new THREE.Color(0x3ab5f9)
	};

	private _levelColors = [new THREE.Color('#3ab5f9'), new THREE.Color('#e90505'), new THREE.Color('#3af96b')];
	private _glowMaterial = new GlowMaterial(this._params.glowColor, false, THREE.FrontSide, THREE.NormalBlending);

	private _mobileMaterial;

	constructor() {
		this._glowMaterial.c = this._params.c;
		this._glowMaterial.p = this._params.p;

		this.group = Globals.getNamedObject('sleigh');

		this.group.position.set(0, 0, 0);

		let firstChild = this.group.children[0] as any;
		firstChild.geometry.center();

		if (this.isWindows()) {
			this._mobileMaterial = new THREE.MeshBasicMaterial({ color: 0x3ab5f9, flatShading: true, fog: false, transparent: true, skinning: true });
		}

		let mat = this.isWindows() ? this._mobileMaterial : this._glowMaterial;

		this.group.traverse(function(node: any) {
			if (node.isMesh) {
				node.material = mat;
				node.material.needsUpdate = true;
				node.geometry = new THREE.Geometry().fromBufferGeometry(node.geometry);
				node.geometry.mergeVertices();
				node.geometry.computeVertexNormals();
				node.geometry = new THREE.BufferGeometry().fromGeometry(node.geometry);
				node.castShadow = true;
			}
		});

		this.group.children[0].rotation.y = -(Math.PI / 2);

		this.group.scale.set(0.13, 0.13, 0.13);

		this._glowMaterial.o = 0;
		TweenMax.to(this._glowMaterial, 0.8, { delay: 1.4, o: 1 });

		this.guiUpdate();

		// if (Globals.DEBUG) {
		this.addGui();
		// }
	}

	private isWindows = () => {
		return false; //navigator.platform.indexOf('Win') > -1;
	};

	public changeLevel = (id: number) => {
		if (this.isWindows()) {
			this._mobileMaterial.color = this._levelColors[id];
		} else {
			this._glowMaterial.glowColor = this._levelColors[id];
		}
	};

	private addGui = () => {
		let top = Globals.GUI.addFolder('Sleigh');
		top.add(this._params, 'positionX', -10, 10).onChange(this.guiUpdate);
		top.add(this._params, 'positionY', -40, 40)
			.step(0.1)
			.onChange(this.guiUpdate);
		top.add(this._params, 'positionZ', -40, 40)
			.step(0.1)
			.onChange(this.guiUpdate);
		top.add(this._params, 'rotation', 0, 360)
			.step(0.1)
			.onChange(this.guiUpdate);
		// top.open();
	};

	private guiUpdate = () => {
		this.group.children[0].rotation.x = degreesToRadians(this._params.rotation);
		this.group.children[0].position.x = this._params.positionX;
		this.group.children[0].position.y = this._params.positionY;
		this.group.children[0].position.z = this._params.positionZ;
	};

	get z() {
		return this._params.positionZ;
	}

	set z(value: number) {
		this._params.positionZ = value;
		this.guiUpdate();
	}

	get alpha() {
		return this._glowMaterial.o;
	}

	set alpha(value: number) {
		this._glowMaterial.o = value;
	}
}
