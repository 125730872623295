import { Linear, TweenMax } from 'gsap/TweenMax';

export class AudioManager {
	private _clips = [];
	private _loaded: boolean = false;

	private _musicVolume = { value: 0.3 };

	private _masterVolume = window.blip.masterGain.node().gain;

	constructor() {}

	public loaded = () => {
		this._loaded = true;

		this._clips['pickup'] = window.blip.clip().sample('pickup');
		this._clips['hazard_astroid'] = window.blip.clip().sample('hazard_astroid');

		this._clips['hyperspeed'] = window.blip.clip().sample('hyperspeed');
		this._clips['hyperspeed_implode'] = window.blip.clip().sample('hyperspeed_implode');

		this._clips['hazard_hit1'] = window.blip.clip().sample('hazard_hit1');
		this._clips['hazard_hit2'] = window.blip.clip().sample('hazard_hit2');
		this._clips['hazard_hit3'] = window.blip.clip().sample('hazard_hit3');
		this._clips['hazard_hit4'] = window.blip.clip().sample('hazard_hit4');
		this._clips['hazard_hit5'] = window.blip.clip().sample('hazard_hit5');

		this._clips['level_complete_1'] = window.blip.clip().sample('level_complete_1');
		this._clips['level_complete_2'] = window.blip.clip().sample('level_complete_2');
		this._clips['level_complete_3'] = window.blip.clip().sample('level_complete_3');

		this._clips['hoho'] = window.blip.clip().sample('hoho');
		this._clips['intro'] = window.blip.clip().sample('intro');
		this._clips['bg_music'] = window.blip.clip().sample('bg_music');

		this._clips['intro_bg_music'] = window.blip.clip().sample('intro_bg_music');

		//	this._clips['bg_music'].play(0, { offset: 0, gain: this._musicVolume.value, loop: true, loopStart: 8.0743, loopEnd: 128.9 });
	};

	public toggleMute = (state: boolean) => {
		TweenMax.to(this._masterVolume, 0.5, { value: state ? 0 : 1 });
	};

	public getClip = (id: string) => {
		return this._clips[id];
	};

	public toggleBgMusic = (state: boolean) => {
		if (this._loaded) {
			TweenMax.to(this._clips['bg_music'].gain, 0.5, { value: state ? 0.3 : 0, ease: Linear.easeNone });
		} else {
			this._musicVolume.value = state ? 0.3 : 0;
		}
	};

	public trigger = (id: string, gain?: number, rate?: number) => {
		if (this._loaded && this._clips[id]) {
			this._clips[id].play(0, { gain: gain || 0.3, rate: rate || 1 });
		}
	};
}
