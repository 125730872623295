import * as THREE from 'three';
import { Linear, Power1, Power2, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../../utils/Globals';
import { clamp, degreesToRadians, normalizeAngle, radiansToDegrees, randomFloat, randomInt, spherePosition, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';
import { Signal } from '../../../lib/com/hellomonday/signals/Signal';
import { Gift } from './Gift';
import * as dat from 'dat.gui';
import { Astroid } from './Astroid';
import { Levels } from '../levels/Levels';
import { GlowMaterial } from '../../materials/GlowMaterial';

const OBJECT_DISTANCE: number = 7;

export class GameObjectSpawner {
	public signalHazardHit: Signal = new Signal();
	public signalAllGiftsPickedUp: Signal = new Signal();

	private _gifts = [];
	private _hazards = [];

	private _mainPlanet;
	private _planetGroup;

	// private _lanes: Array<number> = [-1.2, -0.96, -0.72, -0.48, -0.24, 0, 0.24, 0.48, 0.72, 0.96, 1.2];
	private _lanes: Array<number> = [-1, -0.8, -0.6, -0.4, -0.2, 0, 0.2, 0.4, 0.6, 0.8, 1];
	private _laneCount: number = this._lanes.length - 1;
	private _currentLane = 0;
	private _currentAngle = 34;

	private _glowParams = { c: 0.42, p: 3.0, color: '#FF3333', intensity: 1 };

	private _initialized: boolean = false;

	private _levelGiftColors = [0, 4, 2];
	private _levelColors = [new THREE.Color('#3ab5f9'), new THREE.Color('#e90505'), new THREE.Color('#3af96b')];
	private _level = 0;

	// private _limit = 0;

	private _pitch: number = 1;

	private _giftMaterial;
	private _giftGlowMaterial;

	constructor(mainPlanet, planetGroup) {
		this._mainPlanet = mainPlanet;
		this._planetGroup = planetGroup;

		this.updateGiftMaterial();

		// this.addGifts();

		if (Globals.DEBUG) {
			// this.addGui();
		}
	}

	// private addGui = () => {
	// 	let top = Globals.GUI.addFolder('Glow Shader');
	//
	// 	let cGUI = top
	// 		.add(this._glowParams, 'c')
	// 		.min(0.0)
	// 		.max(1.0)
	// 		.step(0.01)
	// 		.name('c')
	// 		.listen();
	// 	cGUI.onChange(this.updateGlow);
	//
	// 	let pGUI = top
	// 		.add(this._glowParams, 'p')
	// 		.min(0.0)
	// 		.max(6.0)
	// 		.step(0.01)
	// 		.name('p')
	// 		.listen();
	// 	pGUI.onChange(this.updateGlow);
	//
	// 	let iGUI = top
	// 		.add(this._glowParams, 'intensity')
	// 		.min(0.0)
	// 		.max(6.0)
	// 		.step(0.01)
	// 		.name('intensity')
	// 		.listen();
	// 	iGUI.onChange(this.updateGlow);
	//
	// 	let glowColor = top
	// 		.addColor(this._glowParams, 'color')
	// 		.name('Glow Color')
	// 		.listen();
	// 	glowColor.onChange(this.updateGlow);
	// 	// top.open();
	//
	// 	// this.updateGlow();
	// };

	// private updateGlow = () => {
	// 	let l = this._hazards.length;
	//
	// 	for (let i = 0; i < l; i++) {
	// 		this._hazards[i].glowUniforms['c'].value = this._glowParams.c;
	// 		this._hazards[i].glowUniforms['p'].value = this._glowParams.p;
	// 		this._hazards[i].glowUniforms['intensity'].value = this._glowParams.intensity;
	// 		this._hazards[i].glowUniforms.glowColor.value.setHex(this._glowParams.color.replace('#', '0x'));
	// 	}
	// };

	public addObjects() {
		this._initialized = true;

		let giftCount = 0;

		let stage = Levels.STAGES[this._level];
		let l = stage.length;
		let row = 0;
		let column = 0;

		for (let i = 0; i < l; i++) {
			this._currentAngle = -(OBJECT_DISTANCE * row) - 70;

			if (stage[i] === 1) {
				this.addGift(column, giftCount);
				giftCount++;
			} else if (stage[i] === 2) {
				this.addAsteroid(column, i);
			}

			column++;

			if (column > this._laneCount) {
				column = 0;
				row++;
			}
		}

		Globals.gameHUD.setTotalGiftCount(giftCount);
	}

	private addGift = (column: number, id: number) => {
		let radians = degreesToRadians(this._currentAngle);

		let gift = new Gift(radians, this._giftMaterial, this._giftGlowMaterial, this._levelColors[this._level]);

		let position = spherePosition(Globals.SPHERE_RADIUS, radians, 'y');
		position.normalize();
		position.multiplyScalar(7);

		gift.position.x = this._lanes[column];
		gift.position.y = position.y;
		gift.position.z = position.z;

		TweenMax.delayedCall(id * 0.1, this.startGiftHover as () => void, [gift, radians]);
		TweenMax.to(gift.rotation, 7, { z: -TAU, repeat: -1, ease: Linear.easeNone });

		gift.lookAt(this._mainPlanet.position);

		this._planetGroup.add(gift);
		this._gifts.push(gift);
	};

	set level(id: number) {
		this._level = id;

		this.updateGiftMaterial();
	}

	private updateGiftMaterial = () => {
		let color = this._levelGiftColors[this._level];

		this._giftMaterial = new THREE.MeshLambertMaterial({
			map: Globals.getNamedTexture('Gift_' + color),
			color: 0xffffff,
			emissive: 0x000000
		});

		//let glowColor = {r: this._levelColors[1].r, g: this._levelColors[1].g, b: this._levelColors[1].b};
		this._giftGlowMaterial = new GlowMaterial(this._levelColors[this._level]);
	};

	private getRounded = (num: number) => {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	};

	private startGiftHover = (gift: THREE.Group, radians: number) => {
		let downPosition = spherePosition(6.5, radians, 'y');
		downPosition.normalize();
		downPosition.multiplyScalar(6.9);

		TweenMax.to(gift.position, 2, { y: downPosition.y, z: downPosition.z, repeat: -1, yoyo: true, ease: Power1.easeInOut });
	};

	private addAsteroid = (lane: number, id: number) => {
		let radians = degreesToRadians(this._currentAngle);

		let asteroid = new Astroid(radians, this._level);

		let position = spherePosition(6.5, radians, 'y');
		asteroid.position.x = this._lanes[lane];
		asteroid.position.y = position.y;
		asteroid.position.z = position.z;
		asteroid.position.normalize();
		asteroid.position.multiplyScalar(7);

		asteroid.lookAt(this._mainPlanet.position);

		this._planetGroup.add(asteroid);
		this._hazards.push(asteroid);
	};

	public checkCollisions = (collisionObject, planetRotation: number) => {
		if (!this._initialized) return;

		let distanceToObject = 0.3; //0.9;

		// Check hit Asteroid
		for (let i = 0; i < this._hazards.length; i++) {
			let asteroid = this._hazards[i];

			asteroid.rotation.y += degreesToRadians(0.3) * Math.random();
			asteroid.rotation.z += degreesToRadians(0.3) * Math.random();

			let spaceshipWorldPosition = new THREE.Vector3();
			let asteroidWorldPosition = new THREE.Vector3();
			collisionObject.getWorldPosition(asteroidWorldPosition);
			asteroid.getWorldPosition(spaceshipWorldPosition);

			let checkDistanceToSpaceShip = spaceshipWorldPosition.distanceTo(asteroidWorldPosition);
			if (checkDistanceToSpaceShip < distanceToObject) {
				if (asteroid._isHit !== true) {
					this.signalHazardHit.dispatch();

					Globals.AUDIO_MANAGER.trigger('hazard_astroid');

					let id = randomInt(1, 5);
					let hit = Globals.AUDIO_MANAGER.getClip('hazard_hit' + id);
					TweenMax.delayedCall(0.2, hit.play, [0, { gain: 0.5 }]);
					// hit.play(0, { gain: 0.5 });

					asteroid._isHit = true;
					asteroid.explode(this.removeFromGroup);
					asteroid.killGlowTween();
					TweenMax.killTweensOf(asteroid.rotation);
					Globals.gameHUD.updateHealthBar(-1);
				}
			}
		}

		// Check hit Gift
		for (let i = 0; i < this._gifts.length; i++) {
			let gift = this._gifts[i];
			let spaceshipWorldPosition = new THREE.Vector3();
			let giftWorldPosition = new THREE.Vector3();

			collisionObject.getWorldPosition(giftWorldPosition);
			gift.getWorldPosition(spaceshipWorldPosition);

			let checkDistanceToSpaceShip = spaceshipWorldPosition.distanceTo(giftWorldPosition);

			if (checkDistanceToSpaceShip <= 0.4) {
				//distanceToObject) {
				if (gift._isHit !== true) {
					Globals.AUDIO_MANAGER.trigger('pickup', 0.2, this._pitch);
					gift._isHit = true;
					gift.hit(this._planetGroup, -90);

					TweenMax.killTweensOf(this.resetPitch);
					TweenMax.delayedCall(0.5, this.resetPitch);

					this._pitch = clamp(this._pitch + 0.08, 1, 1.5);

					let sphericalPos = new THREE.Spherical(Globals.SPHERE_RADIUS + 2, gift.positionRotation + degreesToRadians(20), 0);
					let newPos = new THREE.Vector3().setFromSpherical(sphericalPos);

					TweenMax.to(gift.scale, 1.4, { x: 0.0001, y: 0.0001, z: 0.0001 });
					TweenMax.to(gift.position, 1.4, { y: newPos.y, z: newPos.z, onComplete: this.removeFromGroup as () => void, onCompleteParams: [gift] });

					Globals.gameHUD.updateGiftCount(1);
				}
			}
		}

		this.cleanup();
	};

	private resetPitch = () => {
		this._pitch = 1;
	};

	public killAllObjects = () => {
		for (let i = 0; i < this._hazards.length; i++) {
			let asteroid = this._hazards[i];
			asteroid._isHit = true;
			asteroid.killGlowTween();
			TweenMax.killTweensOf(asteroid.rotation);
			TweenMax.to(asteroid.scale, 0.1, { x: 0.0000001, y: 0.0000001, z: 0.0000001, onComplete: this.removeFromGroup as () => void, onCompleteParams: [asteroid] });
		}

		for (let i = 0; i < this._gifts.length; i++) {
			let gift = this._gifts[i];
			gift._isHit = true;

			TweenMax.to(gift.scale, 0.4, { x: 0.1, y: 0.1, z: 0.1, onComplete: this.removeFromGroup as () => void, onCompleteParams: [gift] });
		}

		this.cleanup(false);
	};

	// Remove gifts and hazards that has been hit
	private cleanup = (signal: boolean = true) => {
		let i = this._gifts.length;
		while (i--) {
			if (this._gifts[i]._isHit) {
				this._gifts.splice(i, 1);
			}
		}

		i = this._hazards.length;
		while (i--) {
			if (this._hazards[i]._isHit) {
				this._hazards.splice(i, 1);
			}
		}

		if (this._gifts.length === 0) {
			// Remove remaining hazards
			let l = this._hazards.length;

			for (let i = 0; i < l; i++) {
				this._hazards[i]._isHit = true;
				TweenMax.to(this._hazards[i].scale, 0.1, { x: 0.0000001, y: 0.0000001, z: 0.0000001, onComplete: this.removeFromGroup as () => void, onCompleteParams: [this._hazards[i]] });
			}

			this._hazards = [];

			// set new start angle + 45 degrees from current rotation
			// this._currentAngle = radiansToDegrees(this._planetGroup.rotation.x) + 45;
			this._currentAngle = 34;

			// Add new gifts
			//this.addGifts();

			this._initialized = false;

			if (signal) {
				this.signalAllGiftsPickedUp.dispatch([false]);
			}
		}
	};

	// Clean up 3d objects after they have been removed by collision-check
	private removeFromGroup = item => {
		TweenMax.killTweensOf(item.position);
		TweenMax.killTweensOf(item.rotation);
		this._planetGroup.remove(item);

		item = null;
	};
}
