import { Linear, Power3, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';

export class AllowWebcamScreen {
	private _element;
	private _isShown: boolean = false;

	private _useKeyboardButton;
	private _useWebcamButton;

	constructor(element) {
		this._element = element;
		this.init();
	}

	private init() {
		this._useKeyboardButton = this._element.querySelector('.useKeyboard');
		this._useWebcamButton = this._element.querySelector('.useWebcamButton');

		this._useKeyboardButton.addEventListener('click', this.keyboardSelected);
		this._useWebcamButton.addEventListener('click', this.webcamSelected);

		/*	this._pentagram = this._element.querySelector('.pentagramImage');

			this._clickToContinue = this._element.querySelector('.clickToContinue');
			this._clickToContinue.addEventListener('click', this.animateOut);*/

		TweenMax.set(this._element, { opacity: 0 });
	}

	private keyboardSelected = event => {
		window['ga']('send', 'event', 'Select Screen', 'Keyboard');

		Globals.useKeyboard = true;
		Globals.noWebcam = true;
		Globals.keyboardOnly = true;
		this.animateOut();
	};

	private webcamSelected = event => {
		window['ga']('send', 'event', 'Select Screen', 'Webcam');

		//Globals.useKeyboard = true;
		//this.animateOut();
		Globals.bodyPixController.startWebcam(event);
	};

	public animateIn = () => {
		if (this._isShown === false) {
			this._isShown = true;
			this._element.style.display = 'block';
			TweenMax.killTweensOf(this._element);
			TweenMax.to(this._element, 0.7, { delay: 0, opacity: 1, ease: Power3.easeOut });
		//	Globals.mainScene.cameraController.fadeDown(Globals.mainScene.cameraController.gotoIntroScene2);

			Globals.mainScene.cameraController.gotoIntroScene2();
		}
	};

	public animateOut = (onCompleteCallback?) => {
		if (this._isShown === true) {
			Globals.mainScene.cameraController.fadeDown();
			this._isShown = false;
			TweenMax.killTweensOf(this._element);
			TweenMax.to(this._element, 0.2, { opacity: 0, ease: Power3.easeIn, onComplete: this.hide, onCompleteParams: [onCompleteCallback] });
		}
	};

	public hide = (callbackFunction?) => {
		if (callbackFunction) {
			callbackFunction();
		}

		this._element.style.display = 'none';

		//Globals.bodyPixController.turnOnBodyPix();
		if (Globals.useKeyboard === false) {
			TweenMax.delayedCall(0.01, Globals.bodyPixController.initBodyPixFirstFrame);
			Globals.bodyPixController.bodyPixDoSegmentation();
		}
		TweenMax.delayedCall(0.05, Globals.mainScene.cameraController.introComplete);
	};
}
