export class FixedTimeStep {
	private _fps: number = 60;
	private _fpsInterval;
	private _startTime;
	private _now;
	private _then;
	private _elapsed;

	private _callback: Function;

	constructor(callback: Function) {
		this._callback = callback;
		this._fpsInterval = 1000 / this._fps;
		this._then = Date.now();
		this._startTime = this._then;
	}

	public update = () => {
		// calc elapsed time since last loop
		this._now = Date.now();
		this._elapsed = this._now - this._then;

		// if enough time has elapsed, draw the next frame
		if (this._elapsed > this._fpsInterval) {
			// Get ready for next frame by setting then=now, but also adjust for your
			// specified fpsInterval not being a multiple of RAF's interval (16.7ms)
			this._then = this._now - (this._elapsed % this._fpsInterval);

			this._callback();
		}
	};
}
