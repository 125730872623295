import * as THREE from 'three';
import AnimatedMeshLine from '../utils/AnimatedMeshLine';
import { Globals } from '../../utils/Globals';
import { degreesToRadians, randomFloat, randomInt, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';
import { TweenMax } from 'gsap/TweenMax';

const NUMBER_OF_SEGMENTS: number = 100;
const STEP_UNIT: number = TAU / NUMBER_OF_SEGMENTS;

export class TrackRibbons extends THREE.Object3D {
	private _ribbons: Array<AnimatedMeshLine> = [];
	private _params = {
		dashOffset: -1,
		dashRatio: 0,
		dashArray: 0,
		rotationX: 110,
		rotationY: 180,
		rotationZ: 90
	};

	private _levelColors = [new THREE.Color('#3ab5f9'), new THREE.Color('#e90505'), new THREE.Color('#3af96b')];

	constructor() {
		super();

		this.createRibbons();
		// this.rotation.z = Math.PI * 0.5;
		// this.rotation.x = Math.PI * 0.5;
		// this.rotation.y = Math.PI;

		if (Globals.DEBUG) {
			// this.addGui();
		}
		this.guiUpdate();
	}

	private createRibbons = () => {
		let range = 14; // full area is range x 2

		let left = this.createRibbon(-range);
		this.add(left);
		this._ribbons.push(left);

		let right = this.createRibbon(range);
		this.add(right);
		this._ribbons.push(right);
	};

	private createRibbon = angle => {
		let points = [];

		let radius = Globals.SPHERE_RADIUS - 0.45;

		let spherical = new THREE.Spherical(radius, 0, 0);
		let vec3 = new THREE.Vector3(0, 0, 0);

		let theta = 0;

		let phi = 0;
		let phiUnit = Math.PI / NUMBER_OF_SEGMENTS;

		let firstPoint;

		for (let i = 0; i < NUMBER_OF_SEGMENTS; i++) {
			spherical.phi = Math.PI / 2 + degreesToRadians(angle);
			spherical.theta = theta;
			spherical.makeSafe();
			spherical.radius = radius;

			vec3.setFromSpherical(spherical);

			points.push(vec3.x, vec3.y, vec3.z);

			if (i === 0) {
				firstPoint = vec3.clone();
			}

			phi += phiUnit;
			theta += STEP_UNIT;
		}

		// Connect to first point
		points.push(firstPoint.x, firstPoint.y, firstPoint.z);

		let ribbon = new AnimatedMeshLine({
			visibleLength: 0.02,
			points: points,
			speed: 0.01,
			dashArray: 0,
			dashRatio: 0, // 0.0001,//0.1,//0.01,
			dashOffset: -1, //0,//-1,//0.1,
			color: this._levelColors[0],
			width: 0.04, // 0.05
			preventDying: true,
			turbulence: new THREE.Vector3(0, 0, 0),
			fog: false
			// debug: true
		});

		ribbon.dashOffset = -1;
		ribbon.dashArray = 0;
		ribbon.dashRatio = 0;

		// // @ts-ignore
		// ribbon.material.transparent = true;
		//
		// // @ts-ignore
		// ribbon.material.opacity = 0.2;

		// ribbon.update();

		return ribbon;
	};

	public setLevel = (id: number) => {
		let l = this._ribbons.length;

		for (let i = 0; i < l; i++) {
			//@ts-ignore
			this._ribbons[i].material.color = this._levelColors[id];
		}
	};

	private addGui = () => {
		let top = Globals.GUI.addFolder('Track Ribbons');
		top.add(this._params, 'dashOffset', -1, 2)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'dashRatio', 0, 1)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'dashArray', 0, 10)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'rotationX', 0, 360)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'rotationY', 0, 360)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'rotationZ', 0, 360)
			.step(0.01)
			.onChange(this.guiUpdate);

		// top.open();
	};

	private guiUpdate = () => {
		let l = this._ribbons.length;

		for (let i = 0; i < l; i++) {
			this._ribbons[i].dashOffset = this._params.dashOffset;
			this._ribbons[i].dashArray = this._params.dashArray;
			this._ribbons[i].dashRatio = this._params.dashRatio;
		}

		this.rotation.x = degreesToRadians(this._params.rotationX);
		this.rotation.y = degreesToRadians(this._params.rotationY);
		this.rotation.z = degreesToRadians(this._params.rotationZ);
	};

	public reset = () => {
		let l = this._ribbons.length;

		for (let i = 0; i < l; i++) {
			this._ribbons[i].dashOffset = -1;
		}
	};

	public animateIn = () => {
		// let l = this._ribbons.length;
		//
		// for (let i = 0; i < l; i++) {this._params
		// 	TweenMax.to(this._ribbons[i], 8, { dashOffset: 2 });
		// }

		TweenMax.to(this._params, 3, { dashOffset: 0, onUpdate: this.guiUpdate });
		TweenMax.to(this._params, 0.6, { delay: 3, dashOffset: 2, onUpdate: this.guiUpdate });
	};

	public update = () => {
		// let l = this._ribbons.length;
		//
		// for (let i = 0; i < l; i++) {
		// 	this._ribbons[i].update();
		// }
		// this.rotation.x -= 0.001;
	};
}
