import { Back, Linear, Power1, Power3, TimelineMax, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';
import * as bodyPix from '@tensorflow-models/body-pix';
import * as THREE from 'three';

(window as any).THREE = THREE;

//@ts-ignore: Using Require to import ES5
require('./../OBJLoader.js');

//@ts-ignore: Using Require to import ES5
require('./../MTLLoader.js');

export class Preloader {
	private _introHTMLElement;
	private _loaderElement;

	private _logoElement;
	private _subHeaderElement;
	private _beginButton;
	private _turnOnSoundElement;
	private _loaderForeground;
	private _note;

	private _detectionCallback;

	private prepareCallback;
	private onCompleteCallback;

	private objectLoadCount: number = 0;
	private textureLoadCount: number = 0;
	private audioLoadedCount: number = 0;

	private objectLoadCompleted: Boolean = false;
	private textureLoadCompleted: Boolean = false;
	private audioLoadComplete: Boolean = false;

	private audioToLoad: number = 0;
	private modelLoadCount: number = 0;
	private modelsToLoad: number = 1;
	private modelLoadCompleted: boolean = false;

	private _logoAnimationElements = [];

	constructor(introHTMLElement, callbackPrepare, detectionCallback) {
		this._introHTMLElement = introHTMLElement;
		this.prepareCallback = callbackPrepare;
		this._detectionCallback = detectionCallback;

		//	this.onCompleteCallback = callbackWhenDone;
		this.init();
	}

	private init() {
		this.animateInPreloaderScreen();
		this.startLoadingAssets();
	}

	private animateInPreloaderScreen() {
		this._logoElement = this._introHTMLElement.querySelector('.animated_logo');
		this._subHeaderElement = this._introHTMLElement.querySelector('.subheader');
		this._beginButton = this._introHTMLElement.querySelector('.button');
		this._note = this._introHTMLElement.querySelector('.note');
		this._turnOnSoundElement = this._introHTMLElement.querySelector('.rememberSound');
		this._loaderForeground = this._introHTMLElement.querySelector('.loaderForeground');

		this._loaderElement = this._introHTMLElement.querySelector('.loader');

		if (Globals.isMobile === false) {
			this._note.innerHTML = 'A Christmas game in outer space.<br><br>'; //<br>You can steer the deer using nothing but your hands.';
		}

		let easeType = Power1.easeOut;

		TweenMax.set(this._logoElement, {
			y: -0,
			rotation: -90,
			scale: 0,
			filter: 'blur(10px)',
			autoRound: false
		});

		let speed = 0.65;

		TweenMax.to(this._logoElement, speed, { filter: 'blur(0px)', ease: Power1.easeOut });
		TweenMax.to(this._logoElement, speed, { y: 0, opacity: 1, scale: 1, rotation: 10, ease: Back.easeOut }); //, onComplete: this.startLogoAnimation}); //
		TweenMax.to(this._logoElement, 2, { delay: speed, rotation: 3, yoyo: true, repeat: -1, ease: Power1.easeInOut });
		this.startLogoAnimation();

		TweenMax.set(this._subHeaderElement, { y: -0 });
		TweenMax.set(this._beginButton, { y: -0 });
		TweenMax.set(this._turnOnSoundElement, { y: 100 });

		TweenMax.to(this._subHeaderElement, 3, { y: 0, opacity: 1, ease: easeType });
		TweenMax.to(this._note, 3, { y: 0, opacity: 1, ease: easeType });
		TweenMax.to(this._loaderElement, 1, { y: 0, opacity: 1, delay: 0, ease: easeType });
		TweenMax.to(this._turnOnSoundElement, 1.5, { y: 0, opacity: 1, delay: 1.5, ease: easeType });

		//	TweenMax.to(this._logoElement, 2, { repeat: -1, yoyo: true, rotation: 15, ease: Power3.easeInOut });
	}

	private startLogoAnimation = () => {
		// Letters
		let letters = this._logoElement.querySelectorAll('.letter');

		let l = letters.length;

		for (let i = 0; i < l; i++) {
			TweenMax.set(letters[i], { rotation: -2, transformOrigin: '50% 50%' });
			TweenMax.to(letters[i], 0.5 + 0.2 * Math.random(), { delay: 0.1 * Math.random(), rotation: 2, scale: 0.98, yoyo: true, repeat: -1, ease: Power1.easeInOut });
		}

		// Antlers
		let leftAntler = this._logoElement.querySelector('.leftAntler');

		TweenMax.set(leftAntler, { rotation: -3, transformOrigin: '50% 50%' });
		TweenMax.to(leftAntler, 0.4, { rotation: 3, transformOrigin: '100% 65.51724138%', yoyo: true, repeat: -1, ease: Power1.easeInOut });

		let rightAntler = this._logoElement.querySelector('.rightAntler');

		TweenMax.set(rightAntler, { rotation: -3, transformOrigin: '0% 96.26168224%' });
		TweenMax.to(rightAntler, 0.4, { rotation: 3, yoyo: true, repeat: -1, ease: Power1.easeInOut });

		// The
		let the = this._logoElement.querySelector('.the');

		TweenMax.set(the, { rotation: -2, transformOrigin: '50% 50%' });
		TweenMax.to(the, 0.75, { rotation: 2, yoyo: true, repeat: -1, scale: 0.95, ease: Power1.easeInOut });

		this._logoAnimationElements.push(...letters);
		this._logoAnimationElements.push(leftAntler);
		this._logoAnimationElements.push(rightAntler);
		this._logoAnimationElements.push(the);
	};

	private clickBegin = () => {
		Globals.AUDIO_MANAGER.trigger('pickup', 0.001);

		TweenMax.killTweensOf(this._logoElement);

		let l = this._logoAnimationElements.length;

		for (let i = 0; i < l; i++) {
			TweenMax.killTweensOf(this._logoAnimationElements[i]);
		}

		this._beginButton.removeEventListener('click', this.clickBegin);
		//@ts-ignore
		document.querySelector('#_webcam').play();

		var easeType = Power1.easeIn;

		TweenMax.killTweensOf(this._logoElement);
		TweenMax.killTweensOf(this._subHeaderElement);
		TweenMax.killTweensOf(this._beginButton);
		TweenMax.killTweensOf(this._turnOnSoundElement);

		TweenMax.to(this._logoElement, 0.5, { delay: 0.4, filter: 'blur(10px)', opacity: 0, scale: 0, rotation: -90, ease: Power1.easeOut });

		TweenMax.to(this._subHeaderElement, 0.5, { y: 0, opacity: 0, ease: easeType });
		TweenMax.to(this._beginButton, 0.5, { y: 0, opacity: 0, ease: easeType });
		TweenMax.to(this._note, 0.5, { y: 0, opacity: 0, ease: easeType });
		TweenMax.to(this._turnOnSoundElement, 0.5, { y: 100, opacity: 0, delay: 0, ease: easeType, onComplete: this.everythingIsAnimatedOut });
	};

	private everythingIsAnimatedOut = () => {
		TweenMax.to(this._introHTMLElement, 0.5, { opacity: 0, onComplete: this.kill });
	};

	private kill = () => {
		Globals.main.startSite();
		this._introHTMLElement.parentNode.removeChild(this._introHTMLElement);
		//this.onCompleteCallback();
	};

	private startLoadingAssets() {
		this.loadObjects();
		this.loadTextures();
		this.loadModels();
		this.loadAudio();
	}

	private loadAudio() {
		let loader = window.blip.sampleLoader();
		let samplesToLoad = {
			pickup: '/assets/sounds/pickup2.mp3',
			hazard_astroid: '/assets/sounds/hazard_astroid.mp3',
			bg_music: '/assets/sounds/bg_music.mp3',
			intro_bg_music: '/assets/sounds/intro_bg_music.mp3',
			hyperspeed: '/assets/sounds/hyperspeed.mp3',

			level_complete_1: '/assets/sounds/level_complete_1.mp3',
			level_complete_2: '/assets/sounds/level_complete_2.mp3',
			level_complete_3: '/assets/sounds/level_complete_3.mp3',

			hyperspeed_implode: '/assets/sounds/hyperspeed_implode.mp3',
			hazard_hit1: '/assets/sounds/hazards/hazard_hit1.mp3',
			hazard_hit2: '/assets/sounds/hazards/hazard_hit2.mp3',
			hazard_hit3: '/assets/sounds/hazards/hazard_hit3.mp3',
			hazard_hit4: '/assets/sounds/hazards/hazard_hit4.mp3',
			hazard_hit5: '/assets/sounds/hazards/hazard_hit5.mp3',
			intro: '/assets/sounds/intro/intro.mp3',
			hoho: '/assets/sounds/hoho.mp3'
		};

		loader.samples(samplesToLoad);

		this.audioToLoad = Object.keys(samplesToLoad).length;

		loader
			.done(this.audioLoaded)
			.each(this.itemLoaded)
			.load();
	}

	private itemLoaded = () => {
		this.audioLoadedCount++;
		this.checkObjectAndTextureLoad();
	};

	private audioLoaded = () => {
		this.audioLoadComplete = true;
		Globals.AUDIO_MANAGER.loaded();
		this.checkObjectAndTextureLoad();
	};

	private loadModels = () => {
		//	var get = faceapi.nets.tinyFaceDetector.loadFromUri('/models').then(this.modelsLoaded);

		if (Globals.noWebcam) {
			this.modelsLoaded(null);
		} else {
			var get = bodyPix.load(0.75).then(this.modelsLoaded);
		}

		//var get = faceapi.nets.faceExpressionNet.loadFromUri('/models').then(this.modelsLoaded);
		//	var get = faceapi.nets.faceLandmark68TinyNet.loadFromUri('/models').then(this.modelsLoaded);
		//var get = faceapi.nets.ageGenderNet.loadFromUri('/models').then(this.modelsLoaded);
	};

	private modelsLoaded = data => {
		this.modelLoadCount++;
		if (this.modelLoadCount === this.modelsToLoad) {
			this.modelLoadCompleted = true;
			this.checkObjectAndTextureLoad();
			//TweenMax.delayedCall(1, this._detectionCallback, [data]);

			this._detectionCallback(data);
		}
	};

	private loadObjects() {
		//	for (var i = 0; i < Globals.loadObjectsArray.length; i++) {
		// instantiate a loader

		//@ts-ignore: Using Require to import ES5
		var loader = new THREE.OBJLoader();

		var loadObject = Globals.loadObjectsArray[this.objectLoadCount];
		var _objectL = this.objectLoaded;
		var temp = false;
		if (temp && loadObject.mtl) {
			//@ts-ignore: Using Require to import ES5
			var mtlLoader = new THREE.MTLLoader();

			var url = loadObject.mtl;
			mtlLoader.load(url, function(materials) {
				//materials.preload();
				//@ts-ignore: Using Require to import ES5
				var objLoader = new THREE.OBJLoader();
				objLoader.setMaterials(materials);

				objLoader.load(
					// resource URL
					loadObject.path,
					// called when resource is loaded
					_objectL,
					// called when loading is in progresses
					function(xhr) {
						//	console.log((xhr.loaded / xhr.total * 100) + '% loaded');
					},
					// called when loading has errors
					function(error) {
						//	console.log('An error happened');
						console.warn(error);
					}
				);
			});
		} else {
			// load a resource
			loader.load(
				// resource URL
				loadObject.path,
				// called when resource is loaded
				this.objectLoaded,
				// called when loading is in progresses
				function(xhr) {
					//	console.log((xhr.loaded / xhr.total * 100) + '% loaded');
				},
				// called when loading has errors
				function(error) {
					//	console.log('An error happened');
					console.warn(error);
				}
			);
		}
	}

	private objectLoaded = (object: THREE.Group) => {
		Globals.loadObjectsArray[this.objectLoadCount].object = object;
		this.objectLoadCount++;

		if (this.objectLoadCount === Globals.loadObjectsArray.length) {
			this.objectLoadCompleted = true;
		} else {
			this.loadObjects();
		}

		this.checkObjectAndTextureLoad();
	};

	private loadTextures() {
		//	for (var i = 0; i < Globals.loadObjectsArray.length; i++) {
		// instantiate a loader
		var loader = new THREE.TextureLoader();

		var loadTextures = Globals.loadTexturesArray[this.textureLoadCount];

		// load a resource
		loader.load(
			// resource URL
			loadTextures.path,
			// called when resource is loaded
			this.texturesLoaded,
			// called when loading is in progresses
			function(xhr) {
				//	console.log((xhr.loaded / xhr.total * 100) + '% loaded');
			},
			// called when loading has errors
			function(error) {
				//	console.log('An error happened');
				console.warn(error);
			}
		);
	}

	private texturesLoaded = texture => {
		Globals.loadTexturesArray[this.textureLoadCount].texture = texture;
		this.textureLoadCount++;
		if (this.textureLoadCount === Globals.loadTexturesArray.length) {
			this.textureLoadCompleted = true;
		} else {
			this.loadTextures();
		}

		this.checkObjectAndTextureLoad();
	};

	private checkObjectAndTextureLoad() {
		var getAssetLength = Globals.loadTexturesArray.length + Globals.loadObjectsArray.length + this.modelsToLoad + this.audioToLoad;
		var numberOfAssetsLoaded = this.textureLoadCount + this.objectLoadCount + this.modelLoadCount + this.audioLoadedCount;
		var percentageLoaded = Math.round((numberOfAssetsLoaded / getAssetLength) * 100);

		//	console.log('percentageLoaded : ' + percentageLoaded);

		TweenMax.to(this._loaderForeground, 0.2, { width: percentageLoaded + '%', ease: Linear.easeNone });

		if (this.objectLoadCompleted === true && this.textureLoadCompleted === true && this.modelLoadCompleted === true && this.audioLoadComplete === true) {
			this.setupObjects();
		}
	}

	private setupObjects() {
		this.allDone();
	}

	private allDone = () => {
		this.prepareCallback();

		this._beginButton.style.pointerEvents = 'all';
		TweenMax.to(this._beginButton, 1.5, { delay: 0.6, y: -10, opacity: 1, ease: Power1.easeOut });

		TweenMax.to(this._loaderElement, 0.6, { opacity: 0, ease: Power1.easeIn });

		this._beginButton.addEventListener('click', this.clickBegin);

		//	TweenMax.killTweensOf(this._logoElement);

		if (Globals.debugSkipIntro) {
			this.clickBegin();
		}
	};
}
