import * as THREE from 'three';

// @ts-ignore
import * as fragment from '../shaders/glow/GlowShader.fs';
// @ts-ignore
import * as vertex from '../shaders/glow/GlowShader.vs';
import { Globals } from '../utils/Globals';

export class GlowMaterial extends THREE.ShaderMaterial {
	constructor(glowColor: THREE.Color, useSkinning: boolean = true, side = THREE.FrontSide, blending = THREE.AdditiveBlending) {
		super({
			uniforms: {
				opacity: { type: 'f', value: 1.0 },
				c: { type: 'f', value: 0.56 },
				p: { type: 'f', value: 3.0 },
				power: { type: 'f', value: 0.5 },
				mobile: { type: 'b', value: Globals.isMobile },
				windows: { type: 'b', value: Globals.IS_WINDOWS },
				// intensity: { type: 'f', value: 1.0 },
				glowColor: { type: 'c', value: glowColor || new THREE.Color(0x3ab5f9) },
				viewVector: { type: 'v3', value: new THREE.Vector3(0, 0, 0) }
			},
			vertexShader: vertex,
			fragmentShader: fragment,
			side: side,
			// toggle normal blending / additive blending
			blending: blending, // THREE.NormalBlending; THREE.AdditiveBlending
			transparent: true,
			skinning: useSkinning
		});
	}

	get o() {
		return this.uniforms['opacity'].value;
	}

	set o(value: number) {
		this.uniforms['opacity'].value = value;
	}

	get c() {
		return this.uniforms['c'].value;
	}

	set c(value: number) {
		this.uniforms['c'].value = value;
	}

	get p() {
		return this.uniforms['p'].value;
	}

	set p(value: number) {
		this.uniforms['p'].value = value;
	}

	// get intensity() {
	// 	return this.uniforms['intensity'].value;
	// }
	//
	// set intensity(value: number) {
	// 	this.uniforms['intensity'].value = value;
	// }

	set glowColor(value: THREE.Color) {
		this.uniforms['glowColor'].value.r = value.r;
		this.uniforms['glowColor'].value.g = value.g;
		this.uniforms['glowColor'].value.b = value.b;
	}
}
