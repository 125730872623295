import { Linear, Power3, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';

export class TryAgainScreen {
	private _element;
	private _isShown: boolean = false;

	private _headline;
	private _tryAgain;

	constructor(element) {
		this._element = element;
		this.init();
	}

	private init() {
		this._headline = this._element.querySelector('.headline');
		this._tryAgain = this._element.querySelector('.button');
		this._tryAgain.addEventListener('click', this.animateOut);

		TweenMax.set(this._element, { opacity: 0 });
	}

	public animateIn = delay => {
		if (this._isShown === false) {
			// Add text to the level screen
			//TweenMax.delayedCall(delay, Globals.AUDIO_MANAGER.trigger as () => void, ['level_complete_' + (Globals.gameVariables.currentLevel + 1), 1]);

			this._isShown = true;
			this._element.style.display = 'block';
			TweenMax.killTweensOf(this._element);
			TweenMax.to(this._element, 0.7, { delay: delay, opacity: 1, ease: Power3.easeOut });
			//TweenMax.delayedCall(5, this.autoAnimateOut);
		}
	};

	private autoAnimateOut = () => {
		if (this._isShown === true) {
			this.animateOut();
		}
	};

	public animateOut = () => {
		if (this._isShown === true) {
			Globals.mainScene.restartCurrentLevel();

			this._isShown = false;
			TweenMax.killTweensOf(this._element);
			TweenMax.killDelayedCallsTo(this.autoAnimateOut);
			TweenMax.to(this._element, 0.0, { opacity: 0, ease: Power3.easeIn, onComplete: this.hide });
		}
	};

	public changeBGColor = newColor => {
		TweenMax.to(this._element, 0.2, { backgroundColor: newColor });
	};

	public hide = () => {
		this._element.style.display = 'none';
	};
}
