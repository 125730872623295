import { Linear, Power1, Power3, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';
import { Helpers } from './Helpers';
import { clamp } from '../../lib/com/hellomonday/utils/MathUtils';
import { WindowManager } from './WindowManager';

export class BodyPixController {
	private _data;
	private _callback;

	private rotationAngle: HTMLDivElement = <HTMLDivElement>document.querySelector('#rotationAngle');
	private rotationAngleElement: HTMLDivElement = <HTMLDivElement>document.querySelector('.steeringLineTop');
	private rotationText: HTMLDivElement = <HTMLDivElement>document.querySelector('.rotationText');
	private rotationLine: HTMLDivElement = <HTMLDivElement>document.querySelector('.line');

	private _videoArea: HTMLDivElement = <HTMLDivElement>document.querySelector('#videoArea');

	private _webcam: HTMLVideoElement = <HTMLVideoElement>document.querySelector('#_webcam');
	private canvasDom;
	private canvasOriginal: HTMLCanvasElement = <HTMLCanvasElement>document.querySelector('#_canvasOriginal');
	private _canvasOriginalContext: CanvasRenderingContext2D = this.canvasOriginal.getContext('2d');
	private _steeringAxis: HTMLDivElement = <HTMLDivElement>document.querySelector('.steeringAxis');
	private _lineToCenter: HTMLDivElement = <HTMLDivElement>document.querySelector('.lineToCenter');

	private _placeHandsHere: HTMLDivElement = <HTMLDivElement>document.querySelector('.placeHandsHere');
	private _leftHand: HTMLDivElement = <HTMLDivElement>document.querySelector('.leftHand');
	private _rightHand: HTMLDivElement = <HTMLDivElement>document.querySelector('.rightHand');

	private _leftHandImage: SVGElement = <SVGElement>document.querySelector('.leftHandImage');
	private _rightHandImage: SVGElement = <SVGElement>document.querySelector('.rightHandImage');

	private _leftHandText: HTMLDivElement = this._leftHand.querySelector('.handText');
	private _rightHandText: HTMLDivElement = this._rightHand.querySelector('.handText');

	private _boxLeft: HTMLDivElement = this._leftHand.querySelector('.boxLeft');
	private _boxRight: HTMLDivElement = this._rightHand.querySelector('.boxRight');

	private _leftKey: HTMLDivElement = <HTMLDivElement>document.querySelector('.keyLeft');
	private _rightKey: HTMLDivElement = <HTMLDivElement>document.querySelector('.keyRight');
	private _upKey: HTMLDivElement = <HTMLDivElement>document.querySelector('.keyUp');


	private _videoCanvas: HTMLCanvasElement;
	private _videoCanvasContext: CanvasRenderingContext2D;

	private videoWidth: number = 160;
	private videoHeight: number = 120;

	private divideWith: number = 5;

	private keyLeftDown: boolean = false;
	private keyRightDown: boolean = false;
	private keyUpDown: boolean = false;

	private bodyPixNet;

	private canvasContext;

	private numberOfTimesResultNotFound: number = 0;

	private numberOfTimesRightHandNotFound: number = 4;
	private numberOfTimesLeftHandNotFound: number = 4;

	private _timeScalePaused: boolean = true;

	private _bodyPixTurnedOff = true;

	private _keyTurnSpeed: number = 4;

	private _highlightColor: string = 'rgba(' + 100 + ',' + 243 + ',' + 167 + ',' + 0.6 + ')';

	private handState = {
		leftHand: {
			isFound: false,
			xPos: 0,
			yPos: 0,
			initialYPos: 0,
			initialXPos: 0,
			distanceOnX: 0,
			distanceOnY: 0,
			xPosPixel: 0,
			yPosPixel: 0,
			numberOfHandPixels: 0
		},
		rightHand: {
			isFound: false,
			xPos: 0,
			yPos: 0,
			initialYPos: 0,
			initialXPos: 0,
			distanceOnX: 0,
			distanceOnY: 0,
			xPosPixel: 0,
			yPosPixel: 0,
			numberOfHandPixels: 0
		},
		head: {
			isFound: false,
			xPos: 0,
			yPos: 0,
			initialYPos: 0,
			initialXPos: 0,
			distanceOnX: 0,
			distanceOnY: 0,
			xPosPixel: 0,
			yPosPixel: 0,
			numberOfHandPixels: 0
		},
		imageState: { xPos: 0 }
	};

	private _touchActive: boolean = false;
	private _inputsActive: boolean = true;

	constructor(data, callback) {
		this._data = data;
		this.bodyPixNet = data;
		this._callback = callback;
		this.init();
	}

	private init = () => {
		//	if (Globals.debugUseMouse != true) {

		//	} else {
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener('mousedown', this.onMouseDown);
		window.addEventListener('mouseup', this.onMouseUp);

		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
		//}
		if (Globals.IS_TOUCH_DEVICE) {
			window.addEventListener('touchstart', this.touchStart); //, {passive: false});
			window.addEventListener('touchmove', this.touchMove); //, {passive: false});
			window.addEventListener('touchend', this.touchEnd);
		}
		this._videoCanvas = document.createElement('canvas');
		this._videoCanvas.width = this.videoWidth;
		this._videoCanvas.height = this.videoHeight;
		this._videoCanvasContext = this._videoCanvas.getContext('2d');
		this.updateColors(1);
		this.setupCanvas();
		if (Globals.noWebcam) {
			//	this.startMainScene();

			this._callback();
		} else {
			this.bodyPixLoaded();
		}

		TweenMax.to(this._leftHandImage, 0.4, { rotation: 10, ease: Power1.easeInOut, repeat: -1, yoyo: true, transformOrigin: '50% 100%' });
		TweenMax.to(this._rightHandImage, 0.4, { rotation: -10, ease: Power1.easeInOut, repeat: -1, yoyo: true, transformOrigin: '50% 100%' });

		if (Globals.isMobile) {
			this.keyUpDown = true;

			TweenMax.to(this._upKey, 0.1, { backgroundColor: 'rgba(255,255,255,0.5)' });

			//this._timeScalePaused = false;
			if (Globals.trainingLevelDone && Globals.pauseControls === false) {
				// Globals.instructionText.animateOut();
			}
			// Globals.mainScene.tweenTimeScale(1);
			// Globals.mainScene.toggleControls(true);
		}
	};

	private setupCanvas() {
		this.canvasDom = document.getElementById('_canvas') as HTMLCanvasElement;

		this.canvasDom.width = this.videoWidth / this.divideWith;
		this.canvasDom.height = this.videoHeight / this.divideWith;

		this.canvasContext = this.canvasDom.getContext('2d');
	}

	private bodyPixLoaded = () => {
		if (Globals.keyboardOnly) {
		} else {
			//this.startWebcam();
		}
	};

	public startWebcam = event => {
		var constraints = {
			video: {
				facingMode: 'user',
				width: this.videoWidth,
				height: this.videoHeight,

				frameRate: 30
			},
			audio: false
		};

		navigator.mediaDevices
			.getUserMedia(constraints)
			.then(this.userMediaReady)
			.catch(this.webcamNotReady);
	};

	private userMediaReady = stream => {
		this._webcam.addEventListener('loadeddata', this.onLoadedData);
		this._webcam.addEventListener('canplay', this.onWebcamPlaying);

		window['ga']('send', 'event', 'Webcam', 'Allowed');
		try {
			this._webcam.srcObject = stream;
			this._webcam.play();
		} catch (e) {}
	};

	private onLoadedData = () => {



		this._webcam.removeEventListener('loadeddata', this.onLoadedData);
		this._callback();
		Globals.allowWebcamScreen.animateOut();
	};

	private onWebcamPlaying = () => {};

	private webcamNotReady(error) {
		//console.dir(error);
		if (error && error.name) {
			if (error.name == 'NotAllowedError' || error.name == 'PermissionDismissedError' || error.name == 'NotFoundError') {
				//Globals.webcamNotAllowed.animateIn();
				alert(
					'Either you did not allow the camera - or no camera was found.If you clicked "Do not allow" my mistake. Click the video-icon in the browser-bar and change it to "Always allow" - and refresh your browser. Or you can play the game without your webcam by clicking "Use Keyboard".'
				);
				window['ga']('send', 'event', 'Webcam', 'Not allowed - or not working');
			}
		}
	}

	public initBodyPixFirstFrame = () => {
		var getTestImage = document.querySelector('.giftIcon');
		this.bodyPixNet.estimatePartSegmentation(this._webcam, 16, 0.3).then(this.bodyPixFirstInitDone);
	};

	private bodyPixFirstInitDone = () => {
		//console.log('first init done')
	};

	public bodyPixDoSegmentation = () => {
		if (this._bodyPixTurnedOff === true) {
			TweenMax.delayedCall(0.25, this.bodyPixDoSegmentation);
		} else {
			try {
				//console.log(this._webcam.videoHeight)
				if (this._webcam.videoWidth === this.videoWidth && this._webcam.videoHeight === this.videoHeight)
				{
					// dimensions match - we can process the webcam directly
					this.bodyPixNet.estimatePartSegmentation(this._webcam, 16, 0.3).then(this.bodyPixPartsSegmented);
				}
				else {
					// domensions do not match - need to scale the webcam feed down
					this._videoCanvasContext.drawImage(this._webcam,0, 0, this.videoWidth, this.videoHeight)
					this.bodyPixNet.estimatePartSegmentation(this._videoCanvas, 16, 0.3).then(this.bodyPixPartsSegmented);
				}

			} catch (e) {
				TweenMax.delayedCall(0.25, this.bodyPixDoSegmentation);
			}
		}
	};

	private bodyPixPartsSegmented = segmented => {
		// Thought: Should we scale the video down significantly - in order to only get highly pixelated items? And faster processing?
		var getData = segmented.data;
		
		// Drawing the video feed into the canvas
		//	this._canvasOriginalContext.drawImage(this._webcam, 0, 0);

		// We are storing this data in an array
		/*	var getVideoData = this._canvasOriginalContext.getImageData(0, 0, this.videoWidth, this.videoHeight);
		var canvasData = getVideoData.data;
		// Create new canvas data
		//var canvasData = new Uint8ClampedArray(this.videoWidth * this.videoHeight * 4);

		// FIX ME - should be able to update this just directly within the pixels instead of re-applying "putimagedata" -- need to optimize

		var writeToCanvas = new ImageData(new Uint8ClampedArray(canvasData), this.videoWidth, this.videoHeight); //{data: [], width: this.videoWidth, height: this.videoHeight};
		this._canvasOriginalContext.putImageData(writeToCanvas, 0, 0);*/

		//this.canvasContext.clearRect(0, 0, this.canvasDom.width, this.canvasDom.height);
		//this.canvasContext.drawImage(this.canvasOriginal, 0, 0, this.videoWidth, this.videoHeight, 0, 0, this.canvasDom.width, this.canvasDom.height);

		//	getData = this.canvasContext.getImageData(0, 0, this.canvasDom.width, this.canvasDom.height);

		//getData = canvasData;

		var handState = this.handState;

		var leftHandPixelArray = [];
		var rightHandPixelArray = [];
		//var headPixelArray = [];

		var lineCount = 0;
		var xPosCount = 0;

		this._canvasOriginalContext.clearRect(0, 0, this.videoWidth, this.videoHeight);

		/*handState.rightHand.numberOfHandPixels = 0;
		handState.leftHand.numberOfHandPixels = 0;
		handState.head.numberOfHandPixels = 0;*/

		var _length = getData.length;
		var _halfVideoWidth = this.videoWidth / 2;
		var _allowCrossSceneValue = Math.floor(_halfVideoWidth / 4);
		//	_length = _length - _ignoreLinesCount;

		var jumpByFactor = 1;

		for (var i = 0; i < _length; i += jumpByFactor) {
			var pixelValueRed = getData[i];

			/*	if (pixelValueRed === 0 || pixelValueRed === 1) {
				headPixelArray.push([xPosCount, lineCount]);
			}*/

			if (pixelValueRed === 23 && xPosCount > _halfVideoWidth - _allowCrossSceneValue) {
				leftHandPixelArray.push([xPosCount, lineCount]);
			}

			// Right hand
			if (pixelValueRed === 21 && xPosCount < _halfVideoWidth + _allowCrossSceneValue) {
				rightHandPixelArray.push([xPosCount, lineCount]);
			}

			// Left or right hand
			if (pixelValueRed === 21) {
				this._canvasOriginalContext.fillStyle = this._highlightColor;
				this._canvasOriginalContext.fillRect(xPosCount, lineCount, 1, 1);
			} else if (pixelValueRed === 23) {
				this._canvasOriginalContext.fillStyle = this._highlightColor;
				this._canvasOriginalContext.fillRect(xPosCount, lineCount, 1, 1);
			} else {
				// FIXME - took out the pixels because it was hard on the processor.
				/*	if (pixelValueRed >= 0) {
						//	 this._canvasOriginalContext.fillStyle = 'rgba(' + 0 + ',' + 0 + ',' + 200 + ',' + 0.3 + ')';
						//	 this._canvasOriginalContext.fillRect(xPosCount, lineCount, 1, 1);
					}
					if (lineCount % 2 === 0) {
						if (xPosCount % 2 === 0) {
							this._canvasOriginalContext.fillStyle = 'rgba(' + 0 + ',' + 0 + ',' + 0 + ',' + 255 + ')';
							this._canvasOriginalContext.fillRect(xPosCount, lineCount, 1, 1);
						}
					} else if (xPosCount % 2 === 1) {
						this._canvasOriginalContext.fillStyle = 'rgba(' + 0 + ',' + 0 + ',' + 0 + ',' + 255 + ')';
						this._canvasOriginalContext.fillRect(xPosCount, lineCount, 1, 1);
					}*/
			}

			xPosCount += jumpByFactor;

			if (xPosCount >= this.videoWidth) {
				lineCount++;
				xPosCount = 0;
			}
		}

		// Find Middle of Left hand
		var centerObjectLeftHand = Helpers.returnCenterPoints(leftHandPixelArray);
		handState.leftHand.xPosPixel = centerObjectLeftHand.bounds.left;
		handState.leftHand.yPosPixel = centerObjectLeftHand.bounds.top;
		//	handState.leftHand.xPos = centerObjectLeftHand.x / canvas.width;
		//	handState.leftHand.yPos = centerObjectLeftHand.y / canvas.height;

		var centerObjectRightHand = Helpers.returnCenterPoints(rightHandPixelArray);
		handState.rightHand.xPosPixel = centerObjectRightHand.bounds.left;
		handState.rightHand.yPosPixel = centerObjectRightHand.bounds.top;
		//	handState.rightHand.xPos = centerObject.x / canvas.width;
		//	handState.rightHand.yPos = centerObject.y / canvas.height;

		/*	var centerObjectHead = Helpers.returnCenterPoints(headPixelArray);
		handState.head.xPosPixel = centerObjectHead.bounds.left;
		handState.head.yPosPixel = centerObjectHead.bounds.top;*/

		// Set Hand Count
		Globals.numberOfHands = 0;
		if (leftHandPixelArray.length > 100) {
			Globals.numberOfHands = Globals.numberOfHands + 1;
			this.numberOfTimesLeftHandNotFound = 0;
			TweenMax.to(this._leftHand, 0.3, { opacity: 0 });

			//	TweenMax.to(this._gloveLeft, 0.2, {ease: Power1.easeIn, scaleX: 1, scaleY: 1});
		} else {
			if (this.numberOfTimesLeftHandNotFound >= 4) {
				TweenMax.to(this._leftHand, 0.3, { opacity: 1 });
			} else {
				this.numberOfTimesLeftHandNotFound++;

				TweenMax.to(this._leftHand, 0.3, { opacity: 0 });
			}
			//	TweenMax.to(this._gloveLeft, 0.2, {ease: Power1.easeIn, scaleX: 1, scaleY: 1});
		}

		if (rightHandPixelArray.length > 100) {
			Globals.numberOfHands = Globals.numberOfHands + 1;

			TweenMax.to(this._rightHand, 0.3, { opacity: 0 });
			this.numberOfTimesRightHandNotFound = 0;
			//	TweenMax.to(this._gloveRight, 0.2, {ease: Power1.easeIn, scaleX: 1, scaleY: 1});
		} else {
			if (this.numberOfTimesRightHandNotFound >= 4) {
				TweenMax.to(this._rightHand, 0.3, { opacity: 1 });
			} else {
				this.numberOfTimesRightHandNotFound++;

				TweenMax.to(this._rightHand, 0.3, { opacity: 0 });
				//	TweenMax.to(this._gloveRight, 0.2, {ease: Power1.easeIn, scaleX: 1, scaleY: 1});
			}
		}

		/*if (headPixelArray.length > 50) {
			var scaleHat = centerObjectHead.width / 70;
			TweenMax.to(this._hat, 0.1, {x: 200 - centerObjectHead.x - 70, y: centerObjectHead.bounds.top - 35});
		}*/

		if (Globals.numberOfHands === 2 && Globals.pauseControls === false) {
			var angleDeg = (Math.atan2(handState.leftHand.yPosPixel - handState.rightHand.yPosPixel, centerObjectLeftHand.x - centerObjectRightHand.x) * 180) / Math.PI;
			angleDeg = angleDeg / 10;

			// Clamp values
			Globals.wheelRotation = clamp(angleDeg, -5, 5);

			//var leftHandPos = handState.leftHand.yPosPixel - this.videoHeight / 2;
			//TweenMax.to(this._gloveLeft, 0.2, {y: leftHandPos * 2, ease: Power1.easeOut});

			//var rightHandPos = handState.rightHand.yPosPixel - this.videoHeight / 2;
			//TweenMax.to(this._gloveRight, 0.2, {y: rightHandPos * 2, ease: Power1.easeOut});

			// Position the steering axis between the two points

			var differenceInPosition = handState.rightHand.yPosPixel - handState.leftHand.yPosPixel;
			var moveToAxisPosition = handState.leftHand.yPosPixel + differenceInPosition / 2;
			if (handState.leftHand.yPosPixel > handState.rightHand.yPosPixel) {
				differenceInPosition = handState.leftHand.yPosPixel - handState.rightHand.yPosPixel;
				moveToAxisPosition = handState.rightHand.yPosPixel + differenceInPosition / 2;
			}

			var moveToPos = moveToAxisPosition + 20;
			//console.log(moveToAxisPosition)
			TweenMax.to(this._steeringAxis, 0.1, { y: moveToPos });

			TweenMax.to(this._lineToCenter, 0.1, { height: 110 - 45 + moveToPos + 'px' });

			TweenMax.to(Globals.wheelRotationTweened, 0.1, { delay: 0.0, number: Globals.wheelRotation, ease: Linear.easeNone, onUpdate: this.updateRotation });
			if (this._timeScalePaused === true) {
				Globals.mainScene.tweenTimeScale(1);

				TweenMax.to(this._steeringAxis, 0.1, { scaleX: 1, opacity: 1 });
				TweenMax.to(this._lineToCenter, 0.1, { scaleY: 1, ease: Power1.easeOut, transformOriginY: '100%' });

				TweenMax.to(this._videoArea, 0.1, { opacity: 1 });
				//	TweenMax.to(this._webcam, 0.1, {opacity: 0.7});

				TweenMax.set(this.rotationAngle, { opacity: 1, ease: Power1.easeOut });
				Globals.mainScene.toggleControls(true);

				// TweenMax.to(Globals.mainScene.postProcessing, 0.3, { vignetteOffset: 1 });
				TweenMax.to(this._placeHandsHere, 0.3, { opacity: 0 });
				if (Globals.trainingLevelDone) {
					Globals.instructionText.animateOut();
				}

				this._timeScalePaused = false;
			}

			this.numberOfTimesResultNotFound = 0;
		} else {
			this.numberOfTimesResultNotFound++;
			if (this.numberOfTimesResultNotFound === 4) {
				TweenMax.to(Globals.wheelRotationTweened, 0.2, { delay: 0.2, number: 0, ease: Power1.easeOut, onUpdate: this.updateRotation });
				if (this._timeScalePaused === false && Globals.mainScene) {
					Globals.mainScene.tweenTimeScale(0);

					TweenMax.to(this._steeringAxis, 0.3, { scaleX: 0.0, opacity: 0, ease: Power1.easeIn });
					TweenMax.to(this._lineToCenter, 0.3, { scaleY: 0.0, ease: Power1.easeIn, transformOriginY: '100%' });
					this._timeScalePaused = true;

					//	TweenMax.to(this._videoArea, 0.3, { yoyo: true, repeat: -1, opacity: 0.6 });
					//	TweenMax.to(this._webcam, 0.3, {opacity: 1});
					TweenMax.set(this.rotationAngle, { opacity: 0, ease: Power1.easeOut });
					TweenMax.to(this._placeHandsHere, 0.3, { opacity: 1 });

					Globals.mainScene.toggleControls(false);
					if (Globals.trainingLevelDone && Globals.pauseControls === false && Globals.mainScene.isInHyperSpeed() === false) {
						Globals.instructionText.changeText('Remember to keep your hands visible in the webcam frame at the bottom.');
					}
				}
			}
		}

		TweenMax.delayedCall(0.05, this.bodyPixDoSegmentation);
		//requestAnimationFrame(this.bodyPixDoSegmentation);
	};

	private updateRotation = () => {
		this.rotationText.innerHTML = Math.round(Globals.wheelRotationTweened.number * 4) + '°';
		TweenMax.set(this.rotationAngleElement, { rotation: Globals.wheelRotationTweened.number * -5 + 0, ease: Power1.easeOut });
		TweenMax.set(this._steeringAxis, { rotation: Globals.wheelRotationTweened.number * -10 });
	};

	private onMouseMove = (event: MouseEvent) => {
		var getPercentageX = event.pageX / window.innerWidth;
	};

	private onMouseDown = (event: MouseEvent) => {};

	private onMouseUp = (event: MouseEvent) => {};

	private handleKeyUp = keyEvent => {
		//left
		if (keyEvent.keyCode === 37) {
			this.keyLeftDown = false;
			TweenMax.to(this._leftKey, 0.1, { backgroundColor: 'rgba(255,255,255,0.1)' });
		}

		//right
		if (keyEvent.keyCode === 39) {
			this.keyRightDown = false;
			TweenMax.to(this._rightKey, 0.1, { backgroundColor: 'rgba(255,255,255,0.1)' });
		}

		if (!this.keyLeftDown && !this.keyRightDown) {
			TweenMax.to(Globals.wheelRotationTweened, 0.2, { number: 0 });
		} else if (keyEvent.keyCode === 37 && this.keyRightDown) {
			TweenMax.to(Globals.wheelRotationTweened, 0.1, { number: -this._keyTurnSpeed, ease: Power3.easeOut });
		} else if (keyEvent.keyCode === 39 && this.keyLeftDown) {
			TweenMax.to(Globals.wheelRotationTweened, 0.1, { number: this._keyTurnSpeed, ease: Power3.easeOut });
		}

		//up
		if (keyEvent.keyCode === 38) {
			this.keyUpDown = false;

			// TweenMax.killTweensOf(Globals.wheelRotationTweened);
			TweenMax.to(Globals.wheelRotationTweened, 0.2, { number: 0, overwrite: 'true' });

			TweenMax.to(this._upKey, 0.1, { backgroundColor: 'rgba(255,255,255,0.1)' });

			if (this._bodyPixTurnedOff === false) {
				if (this._timeScalePaused === false) {
					this._timeScalePaused = true;
					Globals.mainScene.tweenTimeScale(0);
					Globals.mainScene.toggleControls(false);

					if (Globals.trainingLevelDone && Globals.pauseControls === false && Globals.mainScene.isInHyperSpeed() === false) {
						Globals.instructionText.changeText('Keep holding the up arrow key to move. ');
					}
				}
			}
		}
	};

	private handleKeyDown = keyEvent => {
		if (Globals.pauseControls === false) {
			let overrideLeft = false;
			let overrideRight = false;

			//up
			if (keyEvent.keyCode === 38 && !this.keyUpDown) {
				TweenMax.to(this._upKey, 0.1, { backgroundColor: 'rgba(255,255,255,0.5)' });

				if (this._bodyPixTurnedOff === false) {
					if (this._timeScalePaused === true) {
						this._timeScalePaused = false;
						if (Globals.trainingLevelDone && Globals.pauseControls === false) {
							Globals.instructionText.animateOut();
						}
						Globals.mainScene.tweenTimeScale(1);
						Globals.mainScene.toggleControls(true);
					}
				}

				if (this.keyLeftDown) {
					overrideLeft = true;
				} else if (this.keyRightDown) {
					overrideRight = true;
				}
			}

			//left
			if ((keyEvent.keyCode === 37 && !this.keyLeftDown) || overrideLeft) {
				TweenMax.to(this._leftKey, 0.1, { backgroundColor: 'rgba(255,255,255,0.5)' });

				if (this._timeScalePaused === false) {
					if (Globals.wheelRotationTweened.number < 1) {
						Globals.wheelRotationTweened.number = 1;
					}
					TweenMax.to(Globals.wheelRotationTweened, 0.1, { number: this._keyTurnSpeed, ease: Power3.easeOut });
				}

				this.keyLeftDown = true;
			}

			//right
			if ((keyEvent.keyCode === 39 && !this.keyRightDown) || overrideRight) {
				TweenMax.to(this._rightKey, 0.1, { backgroundColor: 'rgba(255,255,255,0.5)' });

				if (this._timeScalePaused === false) {
					if (Globals.wheelRotationTweened.number > -1) {
						Globals.wheelRotationTweened.number = -1;
					}
					TweenMax.to(Globals.wheelRotationTweened, 0.1, { number: -this._keyTurnSpeed, ease: Power3.easeOut });
				}

				this.keyRightDown = true;
			}
		}
	};

	private touchStart = e => {
		if (!this._inputsActive) {
			return;
		}

		// e.preventDefault();

		if (this._bodyPixTurnedOff === false) {
			this._touchActive = true;

			let x = e.changedTouches[0].clientX;
			this.touchUpdate(x);

			if (this._timeScalePaused === true) {
				this._timeScalePaused = false;
				if (Globals.trainingLevelDone && Globals.pauseControls === false) {
					Globals.instructionText.animateOut();
				}
				// Globals.mainScene.tweenTimeScale(1);
				Globals.mainScene.toggleControls(true);
			}
		}
	};

	private touchMove = e => {
		if (!this._inputsActive) {
			return;
		}

		// e.preventDefault();

		if (Globals.pauseControls === false && this._touchActive) {
			let x = e.changedTouches[0].clientX;
			this.touchUpdate(x);
		}
	};

	private touchUpdate = (x: number) => {
		let center = window.innerWidth * 0.5;
		let quater = center * 0.5;
		let distance = clamp(Math.abs(center - x) / quater, 0, 1);

		TweenMax.to(Globals.wheelRotationTweened, 0.2, { number: x <= center ? distance * 4 : -(distance * 4) });

		// if (x <= center) {
		// 	this.keyLeftDown = true;
		// 	TweenMax.to(this._leftKey, 0.1, {backgroundColor: 'rgba(255,255,255,0.5)'});
		// 	TweenMax.killTweensOf(Globals.wheelRotationTweened);
		// 	Globals.wheelRotationTweened.number = clamp(Globals.wheelRotationTweened.number + 2, -5, 5);
		// } else {
		// 	this.keyRightDown = true;
		// 	TweenMax.to(this._rightKey, 0.1, {backgroundColor: 'rgba(255,255,255,0.5)'});
		// 	TweenMax.killTweensOf(Globals.wheelRotationTweened);
		// 	Globals.wheelRotationTweened.number = clamp(Globals.wheelRotationTweened.number - 2, -5, 5);
		// }
	};

	private touchEnd = e => {
		this._touchActive = false;

		if (this._bodyPixTurnedOff === false) {
			if (this._timeScalePaused === false) {
				TweenMax.to(Globals.wheelRotationTweened, 0.2, { number: 0 });

				this._timeScalePaused = true;
				// Globals.mainScene.tweenTimeScale(0);
				Globals.mainScene.toggleControls(false);
				if (Globals.trainingLevelDone && Globals.pauseControls === false && Globals.mainScene.isInHyperSpeed() === false) {
					Globals.instructionText.changeText('To move keep pressing the screen');
				}
			}
		}
	};

	public toggleInputs = (state: boolean) => {
		this._inputsActive = state;
	};

	public updateColors = level => {
		var newColor = '#38e2ed';
		var highlightColor = 'rgba(' + 56 + ',' + 226 + ',' + 237 + ',' + 0.6 + ')';
		if (level === 2) {
			newColor = '#e72b32';
			highlightColor = 'rgba(' + 231 + ',' + 43 + ',' + 50 + ',' + 0.6 + ')';
		} else if (level === 3) {
			newColor = '#64f3a7';
			highlightColor = 'rgba(' + 100 + ',' + 243 + ',' + 167 + ',' + 0.6 + ')';
		}
		this._highlightColor = highlightColor;

		// @ts-ignore
		this._leftHandImage.children[0].style.fill = newColor;
		// @ts-ignore
		this._rightHandImage.children[0].style.fill = newColor;

		this._leftHandText.style.color = newColor;
		this._rightHandText.style.color = newColor;

		this._boxLeft.style.borderColor = newColor;
		this._boxRight.style.borderColor = newColor;
	};

	public turnOffBodyPix() {
		this._bodyPixTurnedOff = true;
	}

	public turnOnBodyPix() {
		this._bodyPixTurnedOff = false;
	}
}
