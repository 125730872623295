import * as THREE from 'three';
import { Globals } from '../../utils/Globals';
import { degreesToRadians, randomFloat, randomInt, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';

const ASTEROID_COUNT: number = 500;
const RADIUS: number = Globals.SPHERE_RADIUS + 110;

export class AsteroidField {
	public group: THREE.Group = new THREE.Group();

	private _material = new THREE.MeshLambertMaterial({
		flatShading: true,
		color: Globals.LEVEL_COLORS[0],
		emissive: 0x222222, //Globals.LEVEL_COLORS[0],
		fog: false,
		transparent: true,
		opacity: 1
	});

	private _asteroids: Array<THREE.Mesh> = [];

	constructor() {
		let geometry;
		let object;
		let mesh;

		let spherical = new THREE.Spherical(RADIUS, 0, 0);

		// let asteroids = [1, 3, 4, 5];
		// let points = this.randomOnSphereEven(RADIUS, 23, 22, 0.5);

		for (let i = 0; i < ASTEROID_COUNT; i++) {
			object = Globals.getNamedObject('Asteroid_' + ((i % 8) + 1));
			// object = Globals.getNamedObject('Asteroid_' + asteroids[i % 4]);
			geometry = object.children[0].geometry;
			geometry.center();

			mesh = new THREE.Mesh(geometry, this._material);
			mesh.castShadow = false;
			mesh.material.needsUpdate = true;
			// mesh.position.set(-10 + Math.random() * 20, -10 + Math.random() * 20, -10 + Math.random() * 20);

			spherical.phi = Math.random() * Math.PI;
			spherical.theta = Math.random() * TAU;
			spherical.radius = RADIUS - 40 * Math.random();
			spherical.makeSafe();

			// mesh.position.setFromSpherical(points[i]);
			mesh.position.setFromSpherical(spherical);

			// mesh.scale.set(0.0004, 0.0004, 0.0004);
			mesh.scale.set(0.001, 0.001, 0.001);

			this._asteroids.push(mesh);

			this.group.add(mesh);
		}

		// this.group.rotation.z = Math.PI * 0.5;
	}

	// private randomOnSphereEven = (radius, PhiNum, thetaNum, turbulence) => {
	// 	let points = [];
	// 	let phiSpan = Math.PI / PhiNum;
	// 	let thetaSpan = TAU / thetaNum;
	//
	// 	// create random spherical coordinate
	// 	for (let i = 1; i < PhiNum + 1; i++) {
	// 		let phi = (phiSpan * i) + randomFloat(-(Math.PI * turbulence), (Math.PI * turbulence));
	// 		let theta;
	//
	// 		for (let j = 0; j < thetaNum; j++) {
	// 			theta = (thetaSpan * j) + randomFloat(-(TAU * turbulence), (TAU * turbulence));
	// 			let spherical = new THREE.Spherical();
	// 			spherical.set(radius, phi, theta);
	// 			spherical.makeSafe();
	//
	// 			points.push(spherical);
	// 		}
	// 	}
	// 	return points;
	// };

	get alpha() {
		return this._material.opacity;
	}

	set alpha(value: number) {
		this._material.opacity = value;
	}

	set level(id: number) {
		this._material.color = Globals.LEVEL_COLORS[id];
		// this._material.emissive = Globals.LEVEL_COLORS[id];
	}

	public render = () => {
		for (let i = 0; i < ASTEROID_COUNT; i++) {
			this._asteroids[i].rotation.x += 0.005;
			this._asteroids[i].rotation.y += 0.005;
		}
	};
}
