import { Power1, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';

export class TopContent {
	private _element;
	private _topContent;
	private _leftContent;
	private _time;
	private _aboutButton;
	private _aboutButtonText;
	private _intervalID;
	private _timePassed: number = 0;
	private _isShown: boolean = false;

	constructor(element) {
		this._element = element;
		this._topContent = element.querySelector('.topContent');
		this._time = element.querySelector('.time');

		this._leftContent = element.querySelector('.leftContent');
		this._aboutButton = element.querySelector('.aboutButton');
		this._aboutButton.addEventListener('click', this.openAbout);

		this.init();
	}

	private openAbout = () => {
		var willOpen = Globals.about.toggle();
		if (willOpen === true) {
			this._aboutButton.innerHTML = 'CLOSE';
				TweenMax.to(this._time, 0.3, { y: -150, ease: Power1.easeIn });
		} else {
			this._aboutButton.innerHTML = 'ABOUT';
				TweenMax.to(this._time, 0.3, { y: 0, ease: Power1.easeOut });
		}
	};

	private init() {
		if (this._isShown === false) {
			this._isShown = true;

			TweenMax.set(this._element, { y: -80 });
			//	TweenMax.set(this._gameStatsLeftElements, { y: -80 });
			//	TweenMax.set(this._gameStatsRightElements, { y: -150 });
			// this.startTimer();
		}
	}

	public animateIn = () => {
		this._element.style.visibility = 'visible';
		TweenMax.to(this._element, 0.5, { y: 0, opacity: 1, ease: Power1.easeOut, onComplete: this.startTimer });
	};

	public animateInAbout = () => {
		TweenMax.to(this._leftContent, 1, { y: 0, opacity: 1, ease: Power1.easeOut });
	};

	public startTimer = () => {
		this.pauseTimer();
		this._intervalID = setInterval(this.updateTimer, 1000);
	};

	public updateTimer = () => {
		this._timePassed++;
		/*	if (this._timePassed >= 60) {
				this._timePassed = this._timePassed / 60;
			}

			var displayTime = this.lpad(this._timePassed, 4);
			var changeToTimeFormat = displayTime.replace(/..\B/g, '$&:');*/
		this._time.innerHTML = this.fancyTimeFormat(this._timePassed);
	};

	private fancyTimeFormat(time) {
		// Hours, minutes and seconds
		var hrs = ~~(time / 3600);
		var mins = ~~((time % 3600) / 60);
		var secs = ~~time % 60;

		var minsString = mins.toString();

		if (minsString.length === 1) {
			minsString = '0' + mins;
		}

		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = '';

		if (hrs > 0) {
			ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
		}

		ret += '' + minsString + ':' + (secs < 10 ? '0' : '');
		ret += '' + secs;
		return ret;
	}

	private lpad(value, padding) {
		var zeroes = new Array(padding + 1).join('0');
		return (zeroes + value).slice(-padding);
	}

	public restartTimer() {
		this._timePassed = 0;
	}

	public pauseTimer = () => {
		clearInterval(this._intervalID);
	};

	public getTime() {
		return this._time.innerHTML;
	}
}
