import { Linear, Power1, Power3, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';
import SplitText from '../../lib/com/greensock/gsap-bonus/SplitText.js';
import { image } from '@tensorflow/tfjs-core';

export class IntroScreen {
	private _element;
	private _isShown: boolean = false;

	private _skip;
	private _line1;
	private _line2;

	private _skipCallback;

	private _currentLine: number = 0;

	private _currentImage;

	private _imageHolder;

	private speakMusic;

	private isDoingMainSceneAnim: boolean = true;

	private _lineArray = [
		{ line1: 'Our planet’s too warm for Santa and co.,', line2: 'So they packed up their things and are moving to Pluto.', timeCode: 6, showLine2: 2.3, image: 'santa1' },
		{ line1: 'But as they went flying they couldn’t see', line2: '- critters gnawing their bags with gluttonous glee.', timeCode: 5, showLine2: 2.4, image: 'santa2' },
		{ line1: 'And now with every lightyear they race,', line2: 'presents go tumbling out into space.', timeCode: 5, showLine2: 2.4, image: 'santa3' },
		{ line1: 'You can save Christmas but you need to be quick,', line2: 'follow them now - we’ll show you the trick.', timeCode: 5, showLine2: 2.8, image: 'santa4' },
		{ line1: 'Do it for Santa, do it for a smile', line2: '(or do it just to avoid work for a while)', timeCode: 5.1, showLine2: 2.6, image: 'santa5' },
		{ line1: 'Down in the warehouse your space sleds await.', line2: 'Just grab the reins and let’s do something great!', timeCode: 7, showLine2: 3.2 }
	];

	constructor(element, skipCallback) {
		this._element = element;
		this._skipCallback = skipCallback;
		this.init();
	}

	private init() {
		this._skip = this._element.querySelector('.skip');
		this._line1 = this._element.querySelector('.line1');
		this._line2 = this._element.querySelector('.line2');
		this._imageHolder = this._element.querySelector('.images');

		this._skip.addEventListener('click', this.animateOut);

		let introMusic = Globals.AUDIO_MANAGER.getClip('intro_bg_music');
		introMusic.play(0, { offset: 0, gain: 0.1, loop: true, loopStart: 20.3, loopEnd: 30.49 });

		// introMusic.gain.value = 0;
		// TweenMax.to(introMusic.gain, 0.5, {delay: 0.1, value: 0.5});

		TweenMax.set(this._skip, { y: 80 });

		TweenMax.delayedCall(1, this.startIntro);

		this.resize();
	}

	private resize = () => {
		//	TweenMax.set(this._imageHolder, {scale: 0.5});
	};

	private startIntro = () => {
		//	Globals.mainScene.cameraController.gotoIntroScene2(null);

		if (this._isShown === false) {
			Globals.mainScene.cameraController.startIntroScene();

			this._isShown = true;
			this._element.style.display = 'block';

			this.speakMusic = Globals.AUDIO_MANAGER.getClip('intro');
			this.speakMusic.play(0, { gain: 1 });

			Globals.bgMusic = Globals.AUDIO_MANAGER.getClip('bg_music');
			// Globals.bgMusic.play(0, { offset: 0, gain: 0.1, loop: true, loopStart: 8.0743, loopEnd: 128.9 });

			this.showNextLine();
			TweenMax.to(this._skip, 0.7, { y: 0, ease: Power1.easeOut });
			this._isShown = true;

			if (Globals.debugSkipIntro) {
				this.animateOut();
			}
		}
	};

	private showNextLine = () => {
		if (this._isShown === true) {
			if (this._currentLine >= this._lineArray.length) {
				// Intro is done
				//this.animateOut();
				TweenMax.delayedCall(0.4, this.animateOut);
			} else {
				if (this._currentLine === 3) {
					//	TweenMax.delayedCall(2, Globals.mainScene.cameraController.gotoIntroScene3);
				}
				if (this._currentLine === 4) {
					//		Globals.mainScene.cameraController.gotoIntroScene2();
				}
				if (this._currentLine === 5) {
					Globals.mainScene.cameraController.fadeDown(Globals.mainScene.cameraController.gotoIntroScene1);
					//	TweenMax.delayedCall(1, );
				}

				var imageName = this._lineArray[this._currentLine].image;
				if (imageName) {
					var imageElement = this._element.querySelector('.' + imageName);
					imageElement.style.display = 'block';
					this._currentImage = imageElement;

					TweenMax.set(imageElement.parentNode, { perspective: 500 });
					TweenMax.set(imageElement, { rotationY: -5 });

					TweenMax.to(imageElement, 2, { delay: 1, scale: 1, opacity: 1, rotationY: 0.1, ease: Power1.easeOut });
				} else {
					this._currentImage = null;
				}

				var getTextElement = this._lineArray[this._currentLine];
				this._line1.textContent = getTextElement.line1;
				this._line2.textContent = getTextElement.line2;

				var mySplitTextOne = new SplitText(this._line1, { type: 'words,chars' });
				TweenMax.set(this._line1, { perspective: 400, rotationX: 0.1 });
				TweenMax.staggerFrom(mySplitTextOne.chars, 0.8, { opacity: 0, scale: 0, y: 80, rotationX: 180, transformOrigin: '0% 50% -50', ease: 'back', stagger: 0.01 }, 0.01);

				var mySplitTextTwo = new SplitText(this._line2, { type: 'words,chars' });
				TweenMax.set(this._line2, { perspective: 400, rotationX: 0.1 });
				TweenMax.staggerFrom(
					mySplitTextTwo.chars,
					0.8,
					{ delay: getTextElement.showLine2, opacity: 0, scale: 0, y: -80, rotationX: -180, transformOrigin: '0% 50% -50', ease: 'back', stagger: 0.01 },
					0.01
				);

				TweenMax.to(this._line1, 0.2, { opacity: 1 });
				TweenMax.to(this._line2, 0.2, { delay: getTextElement.showLine2, opacity: 1 });

				TweenMax.delayedCall(getTextElement.timeCode, this.hideCurrentLine);
			}
		}
	};

	private hideCurrentLine = () => {
		TweenMax.to(this._line1, 0.5, { opacity: 0 });
		TweenMax.to(this._line2, 0.5, { delay: 0, opacity: 0 });

		var mySplitTextOne = new SplitText(this._line1, { type: 'words,chars' });
		TweenMax.staggerTo(mySplitTextOne.chars, 0.4, { opacity: 0, scale: 0, y: 80, rotationX: 180, transformOrigin: '0% 50% -50', ease: Power1.easeIn, stagger: 0.01 }, Math.random() * 0.1);

		var mySplitTextTwo = new SplitText(this._line2, { type: 'words,chars' });
		TweenMax.staggerTo(
			mySplitTextTwo.chars,
			0.4,
			{ delay: 0, opacity: 0, scale: 0, y: -80, rotationX: -180, transformOrigin: '0% 50% -50', ease: Power1.easeIn, stagger: 0.01 },
			Math.random() * 0.1
		);

		if (this._currentImage) {
			TweenMax.to(this._currentImage, 1, { opacity: 0, rotationY: 5, ease: Power1.easeIn });
		}

		this._currentLine++;

		TweenMax.delayedCall(0.4, this.showNextLine);
	};

	public animateOut = () => {
		this._skip.removeEventListener('click', this.animateOut);
		if (this._isShown === true) {
			this._isShown = false;
			TweenMax.to(this.speakMusic.gain, 0.3, { value: 0, onComplete: this.stopSpeak });
			TweenMax.killTweensOf(this._element);
			TweenMax.killTweensOf(this._line1);
			TweenMax.killTweensOf(this._line2);
			TweenMax.killTweensOf(this._currentImage);

			TweenMax.killDelayedCallsTo(Globals.mainScene.cameraController.gotoIntroScene1);

			Globals.mainScene.cameraController.fadeDown();
			TweenMax.to(this._element, 0.3, { opacity: 0, ease: Power3.easeIn, onComplete: this.hide });
			TweenMax.to(this._skip, 0.1, { y: 0, ease: Power1.easeIn });
		}
	};

	private stopSpeak = () => {
		this.speakMusic.stop();
	};

	public hide = () => {
		this._element.style.display = 'none';
		this._element.parentNode.removeChild(this._element);
		this._element = null;
		this._skipCallback();
	};
}
