import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GlowMaterial } from '../../materials/GlowMaterial';
import { Globals } from '../../utils/Globals';
import { TweenMax } from 'gsap/TweenMax';

export class Reindeers {
	public group: THREE.Group = new THREE.Group();

	private _mixer: THREE.AnimationMixer;
	private _clock: THREE.Clock;

	private _mixer2: THREE.AnimationMixer;

	private _params = {
		c: Globals.isMobile ? 1.1 : 0.6,
		p: Globals.isMobile ? 0.5 : 1.7,
		glowColor: new THREE.Color(0x3ab5f9),
		intensity: 1,
		positionY: -0.1, //-0.8,
		positionZ: 2.3,
		positionX: 0,
		rotation: 0
	};

	private _levelColors = [new THREE.Color('#3ab5f9'), new THREE.Color('#e90505'), new THREE.Color('#3af96b')];

	private _glowMaterial = new GlowMaterial(this._params.glowColor, true, THREE.FrontSide, THREE.NormalBlending);

	private _mobileMaterial;

	private _loaded: boolean = false;

	private _deer1;
	private _deer2;

	private _isTraining: boolean;

	constructor(isTraining: boolean = false) {
		this._isTraining = isTraining;
		this._glowMaterial.fog = false;
		this.updateGlow();

		let loader = new FBXLoader();
		loader.load('/assets/3d/stag/Stag_Run.txt', this.loaded);

		this.guiUpdate();
	}

	private loaded = object => {
		this._clock = new THREE.Clock();
		this._clock.start();

		this._mixer = new THREE.AnimationMixer(object);
		this._mixer.timeScale = 0; //1;

		if (this.isWindows()) {
			this._mobileMaterial = new THREE.MeshBasicMaterial({ color: 0x3ab5f9, flatShading: true, fog: false, transparent: true, skinning: true });
			// this._mobileMaterial.opacity = 0.9;
		}

		let scale = 0.03;
		object.scale.set(scale, scale, scale);
		object.rotation.y = Math.PI;
		object.position.z = -4.5; //-4;
		object.position.x = 0.65;
		this._deer1 = object;

		let material = this.isWindows() ? this._mobileMaterial : this._glowMaterial;

		if (Globals.isMobile) {
			material.uniforms['power'].value = 0.3; //0.6;
		}

		// let top = Globals.GUI.addFolder('Testing');
		//
		// top.add(material.uniforms['test'], 'value')
		// 	.min(0.0)
		// 	.max(10)
		// 	.step(0.01);

		object.traverse(function(child) {
			if (child.material) {
				child.material = material;
			}
		});

		let action = this._mixer.clipAction(object.animations[0]);
		action.play();
		this.group.add(object);

		// Second raindeer
		let object2 = this.clone(object);
		this._mixer2 = new THREE.AnimationMixer(object2);
		this._mixer2.timeScale = 0; //1;

		this._deer2 = object2;

		object2.position.x = -0.65;

		let action2 = this._mixer2.clipAction(object.animations[0]);
		action2.play();
		this.group.add(object2);

		this._loaded = true;

		this.alpha = 0;

		if (this._isTraining) {
			TweenMax.to(this, 0.8, { delay: 1.4, alpha: 1 });
		} else {
			TweenMax.to(this, 1, { delay: 0.5, alpha: 1 });
		}
		//

		if (Globals.DEBUG) {
			this.addGui();
		}
	};

	private isWindows = () => {
		return false; //navigator.platform.indexOf('Win') > -1;
	};

	public changeLevel = (id: number) => {
		if (this.isWindows()) {
			this._mobileMaterial.color = this._levelColors[id];
		} else {
			this._glowMaterial.glowColor = this._levelColors[id];
		}
	};

	private addGui = () => {
		let top = Globals.GUI.addFolder('Reindeers');
		top.add(this._params, 'c', 0, 10.0)
			.step(0.1)
			.onChange(this.updateGlow)
			.listen();
		top.add(this._params, 'p', 0.0, 10.0)
			.step(0.1)
			.onChange(this.updateGlow)
			.listen();
		// top.add(this._params, 'intensity', 0.0, 10.0)
		// 	.step(0.1)
		// 	.onChange(this.updateGlow)
		// 	.listen();

		// let glowColor = top
		// 	.addColor(this._params, 'glowColor')
		// 	.name('Glow Color')
		// 	.listen();
		// glowColor.onChange(this.updateGlow);

		top.add(this._params, 'positionX', -10, 10).onChange(this.guiUpdate);
		top.add(this._params, 'positionY', -40, 40)
			.step(0.1)
			.onChange(this.guiUpdate);
		top.add(this._params, 'positionZ', -400, 400)
			.step(0.1)
			.onChange(this.guiUpdate);

		top.open();
	};

	private updateGlow = () => {
		// this._glowMaterial.c = this._params.c;
		// this._glowMaterial.p = this._params.p;
		// this._glowMaterial.intensity = this._params.intensity;
		// this._glowMaterial.glowColor = this._params.glowColor;

		this._glowMaterial.uniforms['c'].value = this._params.c;
		this._glowMaterial.uniforms['p'].value = this._params.p;
	};

	private guiUpdate = () => {
		this.group.position.x = this._params.positionX;
		this.group.position.y = this._params.positionY;
		this.group.position.z = this._params.positionZ;
	};

	private clone(source) {
		let sourceLookup = new Map();
		let cloneLookup = new Map();

		let clone = source.clone();

		this.parallelTraverse(source, clone, function(sourceNode, clonedNode) {
			sourceLookup.set(clonedNode, sourceNode);
			cloneLookup.set(sourceNode, clonedNode);
		});

		clone.traverse(function(node) {
			if (!node.isSkinnedMesh) return;

			let clonedMesh = node;
			let sourceMesh = sourceLookup.get(node);
			let sourceBones = sourceMesh.skeleton.bones;

			clonedMesh.skeleton = sourceMesh.skeleton.clone();
			clonedMesh.bindMatrix.copy(sourceMesh.bindMatrix);

			clonedMesh.skeleton.bones = sourceBones.map(function(bone) {
				return cloneLookup.get(bone);
			});

			clonedMesh.bind(clonedMesh.skeleton, clonedMesh.bindMatrix);
		});

		return clone;
	}

	private parallelTraverse = (a, b, callback) => {
		callback(a, b);

		for (var i = 0; i < a.children.length; i++) {
			this.parallelTraverse(a.children[i], b.children[i], callback);
		}
	};

	public update = () => {
		if (this._mixer) {
			let delta = this._clock.getDelta();
			this._mixer.update(delta);

			if (this._mixer2) {
				this._mixer2.update(delta + 0.001);
			}
		}
	};

	set timeScale(value: number) {
		if (this._loaded) {
			this._mixer.timeScale = value;
			this._mixer2.timeScale = value;
		}
	}

	get alpha() {
		return this._glowMaterial.o;
	}

	set alpha(value: number) {
		this._glowMaterial.o = value;
	}
}
