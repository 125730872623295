import * as THREE from 'three';
import { GlowMaterial } from '../../materials/GlowMaterial';
import { Globals } from '../../utils/Globals';
import { Linear, Power1, TweenMax } from 'gsap/TweenMax';
import { clamp, degreesToRadians, TAU } from '../../../lib/com/hellomonday/utils/MathUtils';

export class Astroid extends THREE.Group {
	private _asteroid;
	private _glow;
	private _rotation;

	private _explosion;
	private _cells = [];

	private _params = {
		radius: 0,
		phi: 0,
		theta: 0
	};

	private _directions = [[100, 94, 1], [124, 81, 1], [13, 307, 1], [65, 295, 1], [72, 220, 1], [180, 0, 0.7], [27, 240, 1], [150, 320, 1], [132, 196, 1], [110, 127, 1]];

	private _cellGroup: THREE.Group = new THREE.Group();

	constructor(rotation: number, level) {
		super();

		this.name = 'astroid';

		this._rotation = rotation;

		let material = new THREE.MeshPhongMaterial({
			flatShading: true,
			color: [0x2583ee, 0xe90505, 0x3af96b][level],
			emissive: 0x111111,
			transparent: true
		});

		// Explosion
		this._explosion = Globals.getNamedObject('Asteroid_5_fractured').clone();
		this._explosion.scale.set(0.0004, 0.0004, 0.0004);

		let colors = ['#FF0000', '#ff4501', '#ffc205', '#92ff00', '#09ff8a', '#00b4ff', '#7e00ff', '#ff00e9', '#ff936a', '#9cdbff'];

		let l = this._explosion.children.length;

		for (let i = 0; i < l; i++) {
			if (this._explosion.children[i].name.indexOf('Asteroid_5_cell') !== -1) {
				let clone = this._explosion.children[i].clone();
				clone.material = material.clone();
				// clone.material.wireframe = true;
				// clone.material.opacity = 0.8;
				//clone.material.color = new THREE.Color(colors[i]);//0xFFFFFF * Math.random());
				clone.material.needsUpdate = true;
				clone.scale.set(0.0004, 0.0004, 0.0004);

				this._cellGroup.add(clone);
				this._cells.push(clone);
			}
		}

		this._cellGroup.visible = false;
		this.add(this._cellGroup);

		// Asteroid
		this._asteroid = Globals.getNamedObject('Asteroid_5').clone();

		let geometry = this._asteroid.children[0].geometry;
		geometry.center();

		this._asteroid.children[0].castShadow = false;
		this._asteroid.children[0].material = material;
		this._asteroid.children[0].material.needsUpdate = true;
		this._asteroid.children[0].position.set(0, 0, 0);
		this._asteroid.scale.set(0.0004, 0.0004, 0.0004);

		this.add(this._asteroid);

		// Glow
		let glowMaterial = new GlowMaterial(new THREE.Color(0xffffff), false, THREE.FrontSide); // 0xff0000
		this._glow = Globals.getNamedObject('Asteroid_5').clone();

		this._glow.children[0].castShadow = false;
		this._glow.children[0].material = glowMaterial;
		this._glow.children[0].material.needsUpdate = true;
		this._glow.children[0].position.set(0, 0, 0);
		this._glow.scale.set(0.00041, 0.00041, 0.00041);

		if (!Globals.isMobile) {
			this._glow.children[0].material.uniforms['c'].value = 0.5; //0.6;
			this._glow.children[0].material.uniforms['p'].value = 3.0;
			TweenMax.to(this._glow.children[0].material.uniforms['c'], 0.6, { value: 0.3, repeat: -1, yoyo: true, ease: Power1.easeInOut });

			this.add(this._glow);
		}

		// let top = Globals.GUI.addFolder('Asteroid');
		//
		// top.add(this._params, 'radius')
		// 	.min(0.0)
		// 	.max(10)
		// 	.step(0.01)
		// 	.name('Radius')
		// 	.listen()
		// 	.onChange(this.update);
		//
		// top.add(this._params, 'phi')
		// 	.min(0)
		// 	.max(180)
		// 	.step(0.01)
		// 	.name('Phi')
		// 	.listen()
		// 	.onChange(this.update);
		//
		// top.add(this._params, 'theta')
		// 	.min(0)
		// 	.max(360)
		// 	.step(0.01)
		// 	.name('Theta')
		// 	.listen()
		// 	.onChange(this.update);

		// window.addEventListener('click', this.explode);
	}

	private update = () => {
		let spherical = new THREE.Spherical(0, 0, 0);
		let rotation = TAU * (clamp(this._params.radius - 0.14, 0, 100) / 4) * 0.25;
		let scale = clamp(0.0004 * (1 - this._params.radius / 9), 0.00001, 0.0004);

		let l = this._cells.length;

		for (let i = 0; i < l; i++) {
			spherical.radius = this._params.radius * this._directions[i][2];
			spherical.phi = degreesToRadians(this._directions[i][0]);
			spherical.theta = degreesToRadians(this._directions[i][1]);
			spherical.makeSafe();

			this._cells[i].position.setFromSpherical(spherical);

			// this._cells[i].rotation.x = rotation;
			this._cells[i].rotation.y = rotation;
			// this._cells[i].rotation.z = rotation;

			this._cells[i].scale.set(scale, scale, scale);
		}

		// let i = 5;//1;//l - 1;
		//
		// spherical.radius = this._params.radius;
		// spherical.phi = degreesToRadians(this._params.phi);
		// spherical.theta = degreesToRadians(this._params.theta);
		// spherical.makeSafe();
		// this._cells[i].position.setFromSpherical(spherical);
	};

	public explode = (callback: () => void) => {
		// if (!this._asteroid.visible) {
		// 	this._asteroid.visible = true;
		// 	this._glow.visible = true;
		// 	this._cellGroup.visible = false;
		//
		// 	this._params.radius = 0;
		// 	this.update();
		// } else {
		this._asteroid.visible = false;
		this._glow.visible = false;
		this._cellGroup.visible = true;

		TweenMax.to(this._params, 2.5, { radius: 10, ease: Power1.easeOut, onUpdate: this.update, onComplete: callback, onCompleteParams: [this] });
		// }
	};

	public killGlowTween = () => {
		TweenMax.killTweensOf(this._glow.children[0].material.uniforms['c']);
	};

	get glowUniforms() {
		return this._glow.children[0].material.uniforms;
	}

	get positionRotation() {
		return this._rotation;
	}
}
