import * as THREE from 'three';
import { Globals } from '../../utils/Globals';

export class Lights {
	private pointLight: THREE.PointLight;
	private pointLight2: THREE.PointLight;
	private ambientLight: THREE.AmbientLight;

	private _mainGroup: THREE.Group;

	private _params = {
		x: 0,
		y: 11, //24,
		z: -20, //-40,
		distance: 68,
		decay: 0.93,
		intensity: 0.35, //0.5,//1.27,
		p2x: -67,
		p2z: -350,
		p2y: 52, //-40,
		targetX: 0,
		targetY: 0,
		targetZ: 0,
		sunX: 0,
		sunY: -1000,
		sunZ: -400,
		sunIntensity: 0 //10//2.3
	};

	private _planet2PointLight: THREE.PointLight;

	private _planet2;
	private _planet2Group;

	private _directionalLight: THREE.DirectionalLight;
	private _directionalLightHelper: THREE.DirectionalLightHelper;

	private _directionalLight2: THREE.DirectionalLight;
	private _directionalLightHelper2: THREE.DirectionalLightHelper;

	constructor(scene: THREE.Scene, camera: THREE.Camera, mainGroup: THREE.Group, planet2, planet2Group) {
		this._planet2 = planet2;
		this._planet2Group = planet2Group;
		this._mainGroup = mainGroup;

		// Lights
		this.ambientLight = new THREE.AmbientLight(0xffffff);
		this.ambientLight.intensity = 0.5;
		scene.add(this.ambientLight);

		this.pointLight = new THREE.PointLight(0xeeeeee, this._params.intensity, this._params.distance, this._params.decay);
		this.pointLight.position.set(-(Globals.SPHERE_RADIUS * 0.9), Globals.SPHERE_RADIUS + 3, 0);
		this.pointLight.lookAt(new THREE.Vector3(0, 0, 0));
		// this.pointLight.castShadow = true;
		// this.pointLight.shadow.mapSize.width = 1024;
		// this.pointLight.shadow.mapSize.height = 1024;
		scene.add(this.pointLight);

		this.pointLight2 = new THREE.PointLight(0xeeeeee, this._params.intensity, this._params.distance, this._params.decay);
		this.pointLight2.position.set(Globals.SPHERE_RADIUS * 0.9, Globals.SPHERE_RADIUS + 3, 0);
		// this.pointLight2.castShadow = true;
		// this.pointLight2.shadow.mapSize.width = 1024;
		// this.pointLight2.shadow.mapSize.height = 1024;
		scene.add(this.pointLight2);

		this._planet2PointLight = new THREE.PointLight(0xffffff, 3, 100);
		this._planet2PointLight.lookAt(this._planet2.position);
		this._planet2Group.add(this._planet2PointLight);

		this._directionalLight = new THREE.DirectionalLight(0xffffff, this._params.sunIntensity);
		this._directionalLight.target = mainGroup;
		scene.add(this._directionalLight);

		// this._directionalLightHelper = new THREE.DirectionalLightHelper(this._directionalLight, 5);
		// scene.add(this._directionalLightHelper);

		// this._directionalLight2 = new THREE.DirectionalLight(Globals.LEVEL_COLORS[0], 1);
		// this._directionalLight2.target = mainGroup;
		// scene.add(this._directionalLight2);
		//
		// this._directionalLightHelper2 = new THREE.DirectionalLightHelper(this._directionalLight, 5);
		// scene.add(this._directionalLightHelper2);

		this.guiUpdate();
		if (Globals.DEBUG) {
			this.addGui();
		}

		Globals.LIGHTS = this;
	}

	public setPlanet2Position = (pos: THREE.Vector3) => {
		this._planet2PointLight.position.set(pos.x, pos.y, pos.z);
		this._params.p2x = pos.x;
		this._params.p2y = pos.y;
		this._params.p2z = pos.z;
		this._planet2PointLight.lookAt(this._planet2.position);
	};

	private addGui = () => {
		let top = Globals.GUI.addFolder('Lights');

		top.add(this._params, 'sunX', -1000, 1000)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		top.add(this._params, 'sunY', -1000, 1000)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		top.add(this._params, 'sunZ', -1000, 100)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'sunIntensity', 0, 10)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'x', -100, 100)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		top.add(this._params, 'y', -100, 100)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		top.add(this._params, 'z', -100, 100)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		top.add(this._params, 'distance', 0, 500)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		top.add(this._params, 'decay', 0, 10)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		top.add(this._params, 'intensity', 0, 3)
			.step(0.01)
			.onChange(this.guiUpdate)
			.listen();
		//
		top.add(this._params, 'p2x', -200, 200)
			.step(0.01)
			.name('Planet 2 X')
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'p2y', -200, 200)
			.step(0.01)
			.name('Planet 2 Y')
			.onChange(this.guiUpdate)
			.listen();

		top.add(this._params, 'p2z', -400, 200)
			.step(0.01)
			.name('Planet 2 Z')
			.onChange(this.guiUpdate)
			.listen();
		//
		// top.add(this._params, 'targetX', -200, 200)
		// 	.step(0.01)
		// 	.name('Target  X')
		// 	.onChange(this.guiUpdate)
		// 	.listen();
		//
		// top.add(this._params, 'targetY', -200, 200)
		// 	.step(0.01)
		// 	.name('Target  Y')
		// 	.onChange(this.guiUpdate)
		// 	.listen();
		//
		// top.add(this._params, 'targetZ', -400, 200)
		// 	.step(0.01)
		// 	.name('Target  Z')
		// 	.onChange(this.guiUpdate)
		// 	.listen();
		//
		// top.open();
	};

	set posX(value: number) {
		this._params.x = value;
		this.guiUpdate();
	}

	get posX() {
		return this.pointLight.position.x;
	}

	set posY(value: number) {
		this._params.y = value;
		this.guiUpdate();
	}

	get posY() {
		return this.pointLight.position.y;
	}

	set posZ(value: number) {
		this._params.z = value;
		this.guiUpdate();
	}

	get posZ() {
		return this.pointLight.position.z;
	}

	get sunY() {
		return this._params.sunY;
	}

	set sunY(value: number) {
		this._params.sunY = value;
		this.guiUpdate();
	}

	get sunIntensity() {
		return this._params.sunIntensity;
	}

	set sunIntensity(value: number) {
		this._params.sunIntensity = value;
		this._directionalLight.intensity = this._params.sunIntensity;
	}

	public reset = () => {
		// this._params = {
		// 	x: 0,
		// 	y: 24,
		// 	z: -40,
		// 	distance: 68,
		// 	decay: 0.93,
		// 	intensity: 1.27,
		// 	p2x: 0,
		// 	p2y: 0,
		// 	p2z: 0
		// };
	};

	public guiUpdate = () => {
		this.pointLight.position.set(-(Globals.SPHERE_RADIUS * 2.5) + this._params.x, Globals.SPHERE_RADIUS + 3 + this._params.y, this._params.z);
		this.pointLight2.position.set(Globals.SPHERE_RADIUS * 2.5 + this._params.x, Globals.SPHERE_RADIUS + 3 + this._params.y, this._params.z);

		this.pointLight.distance = this._params.distance;
		this.pointLight.decay = this._params.decay;
		this.pointLight.intensity = this._params.intensity;

		this.pointLight2.distance = this._params.distance;
		this.pointLight2.decay = this._params.decay;
		this.pointLight2.intensity = this._params.intensity;

		this._planet2PointLight.position.set(this._params.p2x, this._params.p2y, this._params.p2z);

		this._directionalLight.intensity = this._params.sunIntensity;
		this._directionalLight.position.set(this._params.sunX, this._params.sunY, this._params.sunZ);
		// this._directionalLightHelper.update();

		// this._directionalLight2.position.set(this._params.sunX, this._params.sunY * -1, this._params.sunZ);
		// this._directionalLightHelper2.update();
	};
}
