import { Bounce, Elastic, Power1, TweenMax } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';

export class GameHUD {
	private _element;

	private _levelCount;
	private _giftCount;
	private _healthCount;

	private _totalLevelCount: number = 3;

	private healthItems;

	private _healthPoints: number = 100;
	private _currentHealthPoints: number = 100;
	private _newHealthPoints = { score: 100 };

	// Control Gift Count
	private _currentGiftCount: number = 0;
	private _newGiftCount = { score: 0 };
	private _totalGifts = 24;

	private _gameInfoBar: HTMLDivElement;
	private _videoArea: HTMLDivElement;
	private _keyboardControls: HTMLDivElement;

	private _steeringAxis: HTMLDivElement;
	private _steeringAxisInner: HTMLDivElement;
	private _rotationAngle: HTMLDivElement;
	private _giftIcon: HTMLImageElement;

	constructor(element) {
		this._element = element;

		this._gameInfoBar = element.querySelector('.gameInfo');
		this._videoArea = element.querySelector('#videoArea');
		this._steeringAxis = element.querySelector('.steeringAxis');
		this._steeringAxisInner = element.querySelector('.innerSteeringAxis');

		this._keyboardControls = element.querySelector('.keyboardControls');

		if (Globals.isMobile) {
			this._keyboardControls.style.display = 'none';
		}

		this._rotationAngle = element.querySelector('#rotationAngle');

		//this._healthBarElement = <HTMLDivElement>element.querySelector('.healthBar');
		//	this._healtBarWidth = this._healthBarElement.offsetWidth;

		this.healthItems = <HTMLDivElement>element.querySelectorAll('.healthItem');
		this._healthPoints = this.healthItems.length;
		this._currentHealthPoints = this.healthItems.length;
		this._newHealthPoints.score = this.healthItems.length;

		this._levelCount = element.querySelector('.levelCount');
		this._giftCount = element.querySelector('.giftCount');
		this._healthCount = element.querySelector('.healthCount');

		this._giftIcon = element.querySelector('.giftIcon');

		this.init();
	}

	private init() {
		//	TweenMax.set(this._rotationAngle, {opacity: 0});
		//	TweenMax.set(this._steeringAxis, {opacity: 0});
		TweenMax.set(this._gameInfoBar, { y: 80 });
		TweenMax.set(this._videoArea, { y: 280 });
		TweenMax.set(this._keyboardControls, { y: 280 });
	}

	public animateInGameInfoBar = () => {
		this._gameInfoBar.style.visibility = 'visible';
		TweenMax.to(this._gameInfoBar, 1, { y: 0, opacity: 1, ease: Power1.easeOut });
	};

	public animateOutGameInfoBar = () => {
		this._gameInfoBar.style.visibility = 'visible';
		TweenMax.to(this._gameInfoBar, 1, { y: 80, opacity: 1, ease: Power1.easeIn });
	};

	public animateInVideo = () => {
		this._element.style.visibility = 'visible';
		if (Globals.isMobile) {
			Globals.bodyPixController.turnOnBodyPix();
		} else if (Globals.useKeyboard === true) {
			this._keyboardControls.style.visibility = 'visible';

			TweenMax.to(this._keyboardControls, 1, { y: 0, opacity: 1, ease: Power1.easeOut });
			Globals.bodyPixController.turnOnBodyPix();
		} else {
			// Set colors of control elements



			this._videoArea.style.visibility = 'visible';

			TweenMax.to(this._videoArea, 1, { y: 0, opacity: 1, ease: Power1.easeOut });
			Globals.bodyPixController.turnOnBodyPix();
		}
		TweenMax.delayedCall(0.2, Globals.gameHUD.animateInRotationCircle);
	};

	public updateHudColors = (level) => {

	}

	public animateOutVideo = () => {
		if (Globals.useKeyboard === true) {
			this._keyboardControls.style.visibility = 'visible';
			TweenMax.to(this._keyboardControls, 1, { y: 280, opacity: 1, ease: Power1.easeOut });
		} else {
			this._videoArea.style.visibility = 'visible';
			TweenMax.to(this._videoArea, 1, { y: 280, opacity: 1, ease: Power1.easeIn, delay: 0.2, onComplete: this.videoAnimatedOut });
		}
	};

	private videoAnimatedOut = () => {
		Globals.bodyPixController.turnOffBodyPix();
	};

	public animateInRotationCircle = () => {
		/*var halfCircle = this._rotationAngle.querySelector('.halfCircle');
		var fullCircle = this._rotationAngle.querySelector('.fullCircle');
		var innerCircle = this._rotationAngle.querySelector('.innerCircle');*/
		var lineToCenter = this._rotationAngle.querySelector('.lineToCenter');
		var rotationText = this._rotationAngle.querySelector('.rotationText');

		//	TweenMax.set(halfCircle, { scaleX: 2, scaleY: 2, opacity: 0 });
		//	TweenMax.set(fullCircle, { scaleX: 2, scaleY: 2, opacity: 0 });
		//	TweenMax.set(innerCircle, { scaleX: 0, scaleY: 0, opacity: 0 });
		//TweenMax.set(lineToCenter, { scaleY: 0, opacity: 1 });
		//TweenMax.set(rotationText, { y: -40, opacity: 0 });

		//TweenMax.set(this._steeringAxisInner, { scaleX: 0, opacity: 1 });

		this._rotationAngle.style.visibility = 'visible';
		//	TweenMax.to(this._rotationAngle, 1, {y: 0, opacity: 1, ease: Power1.easeOut});
		this._steeringAxis.style.visibility = 'visible';
		//	TweenMax.to(this._steeringAxis, 1, {y: 0, opacity: 1, ease: Power1.easeOut});

		/*	TweenMax.to(innerCircle, 0.3, { scaleX: 1, scaleY: 1, opacity: 0.3, ease: Power1.easeOut });
		TweenMax.to(fullCircle, 0.3, { scaleX: 1, scaleY: 1, opacity: 0.3, ease: Power1.easeOut });

		TweenMax.to(halfCircle, 0.5, { scaleX: 1, scaleY: 1, opacity: 1, ease: Power1.easeOut });*/
		//	TweenMax.to(rotationText, 0.3, { delay: 0, y: 0, opacity: 1, ease: Power1.easeOut });
	};

	public animateOutRotationAngle = () => {
		/*	var halfCircle = this._rotationAngle.querySelector('.halfCircle');
		var fullCircle = this._rotationAngle.querySelector('.fullCircle');
		var innerCircle = this._rotationAngle.querySelector('.innerCircle');*/
		var lineToCenter = this._rotationAngle.querySelector('.lineToCenter');
		var rotationText = this._rotationAngle.querySelector('.rotationText');

		/*	TweenMax.set(halfCircle, { scaleX: 2, scaleY: 2, opacity: 0 });
		TweenMax.set(fullCircle, { scaleX: 2, scaleY: 2, opacity: 0 });
		TweenMax.set(innerCircle, { scaleX: 0, scaleY: 0, opacity: 0 });*/
		TweenMax.set(rotationText, { y: -40, opacity: 0 });

		this._rotationAngle.style.visibility = 'visible';
		//	TweenMax.to(this._rotationAngle, 1, {y: 0, opacity: 1, ease: Power1.easeOut});
		this._steeringAxis.style.visibility = 'visible';
		//	TweenMax.to(this._steeringAxis, 1, {y: 0, opacity: 1, ease: Power1.easeOut});

		/*	TweenMax.to(innerCircle, 0.3, { scaleX: 0, scaleY: 0, opacity: 0, ease: Power1.easeIn });
		TweenMax.to(fullCircle, 0.3, { scaleX: 0, scaleY: 0, opacity: 0, ease: Power1.easeIn });

		TweenMax.to(halfCircle, 0.3, { scaleX: 0, scaleY: 0, opacity: 0, ease: Power1.easeIn });*/
		TweenMax.to(rotationText, 0.3, { delay: 0.3, y: -40, opacity: 0, ease: Power1.easeIn });

		TweenMax.to(this._rotationAngle, 0.3, { opacity: 0 });
		TweenMax.to(this._steeringAxis, 0.3, { opacity: 0 });
	};

	/**
	 * Handle Gifts
	 * @param addToScore
	 */
	public updateGiftCount(addToScore, training: boolean = false) {
		this._currentGiftCount += addToScore;
		TweenMax.to(this._newGiftCount, 0.2, {
			score: this._currentGiftCount,
			rounded: true,
			onUpdate: this.setGiftCount,
			ease: Power1.easeOut
		});

		var createGiftPlusOne = document.createElement('div');
		createGiftPlusOne.style.fontSize = '120px';
		createGiftPlusOne.style.color = '#ffffff';
		createGiftPlusOne.style.position = 'absolute';
		createGiftPlusOne.style.bottom = '400px';
		createGiftPlusOne.style.left = 'calc(50% - 50px)';
		createGiftPlusOne.textContent = '+1';
		createGiftPlusOne.style.zIndex = '100';
		createGiftPlusOne.style.willChange = 'transform';

		TweenMax.set(createGiftPlusOne, { scaleX: 0, scaleY: 0 });

		document.body.appendChild(createGiftPlusOne);
		var scaleTo = 1;
		if (window.innerWidth < 723) {
			scaleTo = 0.5;
		}

		TweenMax.to(createGiftPlusOne, 0.6, {
			y: 200,
			scaleX: scaleTo,
			scaleY: scaleTo,
			ease: Bounce.easeOut,
			onComplete: this.fadeOutGiftCount as () => void,
			onCompleteParams: [createGiftPlusOne, training]
		});
	}

	private fadeOutGiftCount = (giftPlusOne, training) => {
		var yPos = 440;
		var xPos = training ? 0 : -180;
		if (window.innerWidth < 723) {
			xPos = training ? 0 : 120;
		}

		TweenMax.to(giftPlusOne, 0.4, {
			y: 440,
			x: xPos,
			scaleX: 0.1,
			scaleY: 0.1,
			opacity: 1,
			ease: Power1.easeOut,
			onComplete: this.removeGiftPlusOne as () => void,
			onCompleteParams: [giftPlusOne]
		});
	};

	private removeGiftPlusOne = giftPlusOne => {
		giftPlusOne.parentNode.removeChild(giftPlusOne);
	};

	private setGiftCount = () => {
		//var useScore = Math.round(this._newGiftCount.score);

		TweenMax.to(this._giftIcon, 0.1, { scaleX: 1.2, scaleY: 1.2, onComplete: this.scaleBack });
		var newGiftCount: any = Math.round(this._newGiftCount.score);
		if (newGiftCount < 10) {
			newGiftCount = '0' + newGiftCount.toString();
		}

		this._giftCount.innerHTML = newGiftCount + '/' + this._totalGifts; //this.pad(useScore, 15, '');//
	};

	private scaleBack = () => {
		TweenMax.to(this._giftIcon, 0.05, { scaleX: 1, scaleY: 1 });
	};

	public getGiftCount() {
		return this._currentGiftCount;
	}

	public setTotalGiftCount = totalGiftCount => {
		this._currentGiftCount = 0;
		this._newGiftCount.score = 0;
		this._totalGifts = totalGiftCount;
		this.setGiftCount();
	};

	/**
	 * Handle Health
	 * @param addToScore
	 */
	public updateHealthBar(subtractFromScore) {
		this._currentHealthPoints += subtractFromScore;

		if (this._currentHealthPoints <= 0) {
			Globals.tryAgainScreen.animateIn(0);
		} else {
			var currentHealthItem = <HTMLDivElement>this.healthItems[this._currentHealthPoints];
			TweenMax.to(currentHealthItem, 0.2, { backgroundColor: 'rgba(255,255,255,0.3)', ease: Power1.easeIn });

			if (this._currentHealthPoints === 1) {
				TweenMax.to(this._healthCount, 0.5, { yoyo: true, repeat: -1, opacity: 0.3 });
			}
		}
		/*TweenMax.to(this._newHealthPoints, 0.2, {
			score: this._currentHealthPoints,
			rounded: true,
			onUpdate: this.setHealthPoints,
			ease: Power1.easeOut
		});*/
	}

	public resetHealthBar() {
		TweenMax.killTweensOf(this._healthCount);
		TweenMax.to(this._healthCount, 0.1, { opacity: 1 });

		for (var i = 0; i < this.healthItems.length; i++) {
			var currentHealthItem = this.healthItems[i];
			TweenMax.to(currentHealthItem, 0.2, { backgroundColor: 'rgba(255,255,255,1)', ease: Power1.easeIn });

			this._healthPoints = this.healthItems.length;
			this._currentHealthPoints = this.healthItems.length;
			this._newHealthPoints.score = this.healthItems.length;
		}
	}

	private setHealthPoints = () => {
		//var useScore = Math.round(this._newGiftCount.score);
		//this._giftCount.innerHTML = Math.round(this._newGiftCount.score);//this.pad(useScore, 15, '');//
		//	this._healthBarElement.style.width = this._healtBarWidth * (this._newHealthPoints.score / 100) + 'px';
		/*for (var i = 0; i < this.healthItems.length; i++) {
			var currentHealthItem = this.healthItems[i];
			if (i > this._newHealthPoints.score) {
				if (currentHealthItem._isUsed !== true) {

				}
				else {

					currentHealthItem._isUsed = true;
				}
			}

		}*/
	};

	/**
	 * Update Level
	 */
	public updateLevel(newLevelCount) {
		this.updateHudColors(newLevelCount);
		Globals.bodyPixController.updateColors(newLevelCount);

		this._levelCount.innerHTML = newLevelCount + ' / ' + this._totalLevelCount;
	}
}
